/**=====================
55. theme layout CSS Start
==========================**/

body {
  .dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .for-dark {
      display: block;
    }
    .for-light {
      display: none;
    }
    .login-card {
      background-image: url("../../../assets/identity/background.png");
      background-color: #cccccc;
      //background: $dark-body-background;
      .theme-form {
        background: $dark-card-background;
      }
    }
    p{
      color: #ffffff99;
    }
    .add-product{
      .product-info{
        .product-group{
          border: 1px solid $dark-card-border;
        }
      }
    }  
    .project-list {
      ul {
        border-bottom: 0 !important;
      }
    }
    .quick-file{
      li{
        .quick-box{
          border: 1px solid $dark-card-border;
        }
      }
    }
    ngb-datepicker{
      .ngb-dp-content{
        .ngb-dp-week{
          .btn-light{
            color: $dark-all-font-color;
          }
        }
      }
    }   
    .files-content{
      li{
        .files-list{
          .file-left{
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .basic-cards{
      .custom-profile{
        .card-footer{
          >div {
            div{
              + div{
                border-left: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
    .data-table{
      table{
        border: 1px solid $dark-card-border;
      }
    }
    .calendar-event.table {
      th {
        border: 0 !important;
      }
      td {
        border: 0 !important;
      }
    }
    .product-sidebar{
      .filter-section{
        .card{
          .card-header{
            border-radius: 15px;
          }
        }
      }
    }
    .product-wrapper-grid{
      .product-box{
        ngb-rating{
          span{
            color: $warning-color;
          }
        }
      }
    }
    .product-box{
      background-color: $dark-card-background;
      border-radius: 0 0 15px 15px;
      .product-details{
        h4{
          color: $dark-all-font-color;
        }
      }
    }
    .creative-cards {
      .card {
        .card-header {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .tabbed-card {
      ul {
        border-bottom: 0 !important;
      }
    }
    .ngx-slider {
      .ngx-slider-bar {
        background-color: $dark-body-background;
      }
      .ngx-slider-tick {
        background-color: $dark-body-background;
      }
    }
    .angular-editor-textarea {
      border: 1px solid $dark-card-border !important;
    }
    .support-table {
      .pagination {
        .page-item {
          &.active {
            .page-link {
              background-color: $primary-color !important;
              border-color: $primary-color !important;
            }
          }
        }
      }
    }
    .email-wrap {
      .email-app-sidebar {
        .main-menu {
          > li {
            a {
              &:hover {
                > .title {
                  color: $primary-color !important;
                }
              }
            }
          }
        }
      }
    }
    .chat-box {
      .chat-menu {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        .user-profile {
          .follow {
            border-top: 1px solid $dark-card-border;
          }
          .digits {
            border-top: 1px solid $dark-card-border;
          }
        }
      }
      .people-list {
        .search {
          i {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .pricing-block {
      .pricing-list {
        ul {
          li {
            h6 {
              span {
                color: $dark-small-font-color;
              }
            }
          }
        }
      }
    }
    .border-bottom {
      border-bottom: 1px solid $dark-card-border !important;
    }
    // calender
    .cal-month-view {
      background-color: $dark-card-background;
      .cal-cell-row {
        &:hover {
          background-color: $dark-body-background;
        }
        .cal-cell {
          &:hover,
          &.cal-has-events.cal-open {
            background-color: rgba($dark-card-background, 0.3) !important;
          }
        }
      }
      .cal-day-cell:not(:last-child) {
        border-right-color: $dark-card-border;
      }
      .cal-days {
        border-color: $dark-card-border;
        .cal-cell-row {
          border-bottom-color: $dark-card-border;
        }
      }
      .cal-day-cell {
        &.cal-weekend {
          .cal-day-number {
            color: $danger-color;
          }
        }
      }
    }
    .cal-week-view {
      background-color: $dark-card-background;
      border-top: 1px solid $dark-card-border;
      .cal-day-headers {
        border-color: $dark-card-border;
        .cal-header {
          &:not(:last-child) {
            border-right-color: $dark-card-border;
          }
          &:first-child {
            border-left-color: $dark-card-border;
          }
        }
        .cal-header:hover,
        .cal-drag-over {
          background-color: $dark-body-background;
        }
      }
      .cal-hour {
        &:not(:last-child),
        &:last-child :not(:last-child) {
          .cal-hour-segment {
            border-bottom-color: $dark-card-border;
          }
        }
      }
      .cal-hour-odd {
        background-color: $dark-body-background;
      }
      .cal-time-events {
        border-color: $dark-card-border;
        .cal-day-columns:not(.cal-resize-active) {
          .cal-hour-segment {
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
      .cal-day-column {
        border-left-color: $dark-card-border;
      }
      .cal-all-day-events {
        border-color: $dark-card-border;
      }
    }
    // ngx editor
    .ngx-toolbar {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
      .ngx-toolbar-set {
        background-color: $dark-card-background !important;
        .ngx-editor-button {
          border: 1px solid $dark-card-border !important;
          border-right: transparent !important;
          color: $dark-all-font-color !important;
          &:hover {
            background-color: $dark-body-background !important;
          }
        }
      }
    }
    .ngx-editor {
      .ngx-wrapper {
        .ngx-editor-textarea {
          border: 1px solid $dark-card-border !important;
        }
      }
    }
    .ngx-editor-grippie {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
      border-top: transparent;
    }
    .ProfileCard-realName {
      .realname {
        color: $dark-all-font-color;
      }
    }
    .data-table{
      table{
        tbody{
          tr{
            td{
              span{
                i + i{
                  border-left: 1px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .table-hover {
      tbody {
        tr {
          &:hover {
            th,
            td {
              color: $primary-color !important;
            }
          }
        }
      }
    }
    .login-form {
      .form-group {
        input {
          border: 1px solid $dark-card-border !important;
        }
      }
    }
    .search-list {
      .nav-item {
        .nav-link {
          &.active {
            background-color: rgba($primary-color, 0.1);
          }
        }
      }
    }
    // ck editor
    .ck-editor__editable {
      color: $theme-font-color;
    }
    // ngx chart
    .ngx-charts {
      .gridline-path {
        stroke: $dark-card-border;
      }
    }
    .social-tab {
      .input-group {
        .form-control {
          background-color: rgba($primary-color, 0.1) !important;
        }
        .input-group-text {
          background-color: $primary-color;
        }
      }
    }
    .ng-select {
      .ng-select-container {
        border: 1px solid $dark-card-border;
      }
    }
    .faq-accordion {
      .faq-header {
        svg {
          color: $dark-small-font-color;
        }
      }
    }
    .blog-box {
      h6 {
        color: $dark-all-font-color;
      }
    }
    .product-social {
      li {
        a {
          border: 1px solid $dark-card-border;
        }
      }
    }
    .accordian-page {
      .accordion-header {
        .accordion-button {
          &:not(.collapsed) {
            background-color: rgba($primary-color, 0.1) !important;
            color: $primary-color;
          }
        }
      }
    }
    .product-page-main {
      .pro-group {
        &:nth-child(n + 2) {
          border-top: 1px solid $dark-card-border;
        }
      }
    }
    .order-history {
      table {
        .qty-box {
          input.form-control {
            border: 0 !important;
          }
        }
      }
    }
    .pricing-simple {
      .card-body {
        h3 {
          &::before {
            background-color: $dark-card-border;
          }
        }
      }
    }
    .dz-message {
      h6 {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .login-form {
      .login-social-title {
        h5 {
          background-color: $dark-card-background;
        }
        &::before {
          background-color: $dark-card-border;
        }
      }
    }
    .page-wrapper {
      &.horizontal-wrapper {
        > .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                > li {
                  > a {
                    svg {
                      path {
                        stroke: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-header {
        .header-wrapper {
          .nav-right {
            .notification-dropdown {
              li {
                .media {
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-wrapper.compact-wrapper {
      .page-body-wrapper {
        div.sidebar-wrapper {
          .logo-wrapper {
            img {
              height: 57.24px;
            }
          }
        }
      }
    }
    .ngb-dp-month {
      &:first-child {
        .ngb-dp-week {
          padding: 0;
        }
      }
      &:last-child {
        .ngb-dp-week {
          padding: 0;
        }
      }
    }
    .cal-date-widget {
      .cal-datepicker {
        ngb-datepicker {
          background-color: $dark-body-background;
        }
      }
    }
    ngb-datepicker {
      .ngb-dp-header {
        background-color: $dark-body-background;
        .ngb-dp-arrow-btn {
          background-color: $primary-color;
          color: $white;
        }
      }
      .ngb-dp-weekday {
        color: $white;
      }
      .ngb-dp-content {
        .ngb-dp-week {
          .btn-light {
            background-color: $dark-body-background !important;
            border-color: $dark-card-border !important;
            &:hover {
              background-color: $primary-color !important;
              border-color: $primary-color !important;
              color: $white;
              &.text-muted {
                color: $white !important;
              }
            }
            &.text-white {
              background-color: transparent !important;
              border: 1px solid $primary-color !important;
              color: $black !important;
            }
          }
        }
      }
      .ngb-dp-weekdays {
        background-color: $dark-body-background;
      }
    }
    .buy-sell {
      .buy-sell-form {
        .form-group {
          .input-group {
            input {
              background-color: $dark-body-background;
              border: 0;
            }
          }
        }
      }
    }
    .crypto-chart {
      .card-header {
        .media {
          .media-body {
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .lighticon {
      opacity: 0;
      transition: all 0.3s ease;
    }
    .darkicon {
      opacity: 1 !important;
      transition: all 0.3s ease;
    }
    .total-transactions {
      .report-sec {
        .report-perfom {
          border-top: 1px solid $dark-card-border;
        }
        .report-main {
          border-right: 1px solid $dark-card-border;
        }
      }
    }
    .activity-media {
      .media {
        .media-body {
          span {
            color: $dark-small-font-color;
          }
        }
        + .media {
          border-top: 1px solid $dark-card-border;
        }
      }
    }
    .onhover-show-div {
      li {
        a {
          svg {
            stroke: $dark-small-font-color !important;
            path,
            line,
            polyline {
              stroke: $dark-small-font-color !important;
            }
          }
        }
      }
    }
    .page-wrapper.compact-wrapper {
      .page-body-wrapper {
        div.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              > li.sidebar-list {
                ul.sidebar-submenu {
                  li {
                    a {
                      &.active,
                      &:hover {
                        span {
                          color: $primary-color;
                        }
                      }
                    }
                  }
                }
              }
              a {
                svg {
                  * {
                    stroke: $dark-small-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    .special-discount {
      .discount-detail {
        .timer-sec {
          li {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .our-activities {
      table {
        tr {
          td {
            .icon-wrappar {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .font-dark {
      color: $dark-all-font-color !important;
    }
    .square-box {
      background-color: $dark-body-background;
    }
    .starred {
      color: orange !important;
    }
    .ProfileCard-avatar {
      svg {
        path,
        line {
          stroke: $dark-small-font-color !important;
        }
      }
    }
    .page-wrapper {
      &.horizontal-wrapper {
        > .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                > li {
                  &:hover {
                    a {
                      svg {
                        path {
                          stroke: $primary-color;
                        }
                      }
                    }
                  }
                  > a {
                    &.active {
                      svg {
                        path {
                          stroke: $primary-color;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-header {
        .header-wrapper {
          .nav-right {
            .profile-dropdown {
              li {
                &:hover {
                  a {
                    svg {
                      path,
                      polyline {
                        stroke: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
            .bookmark-flip {
              &.onhover-show-div {
                .flip-card {
                  .flip-card-inner {
                    .back {
                      .flip-back-content {
                        input {
                          border: 1px solid $dark-card-border;
                        }
                      }
                    }
                  }
                }
              }
              .Typeahead-menu {
                .ProfileCard-realName {
                  .realname {
                    color: $dark-all-font-color;
                  }
                }
              }
              .bookmark-dropdown {
                .bookmark-content {
                  svg {
                    path {
                      stroke: $dark-small-font-color;
                    }
                  }
                }
              }
            }
            .cart-nav {
              .cart-dropdown {
                li {
                  .media {
                    .media-body {
                      h5 {
                        a {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                  }
                }
              }
            }
            ul {
              li {
                svg {
                  path {
                    stroke: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .cd-timeline-content::before {
      border-left: 7px solid $dark-body-background;
    }
    .cd-timeline-block:nth-child(even) {
      .cd-timeline-content::before {
        border-right: 7px solid $dark-body-background;
      }
    }
    .alert-notify-section {
      .card-footer {
        .btn-light {
          color: $dark-card-background !important;
        }
      }
    }
    .cd-timeline-content {
      border: 1px solid $dark-card-border;
    }
    .prooduct-details-box {
      .media {
        .media-body {
          .product-name {
            h6 {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .faq-accordion.default-according#accordionoc {
      .card {
        .faq-header {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .social-list {
      .media {
        .media-body {
          span {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .edit-profile {
      .profile-title {
        border-bottom: 1px solid $dark-card-border;
      }
    }
    .user-profile {
      .social-media {
        ul.user-list-social {
          li:nth-child(n + 2) {
            margin-left: 5px !important;
            margin-right: unset;
          }
        }
      }
      .profile-post {
        .post-header {
          .media {
            h5 {
              color: $dark-all-font-color;
            }
          }
        }
        .post-body {
          .post-react {
            h6 {
              color: $dark-all-font-color;
            }
          }
          .post-comment {
            li {
              label {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .post-about {
        ul {
          li {
            h5 {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .follow {
        ul.follow-list {
          border-top: 1px solid $dark-card-border;
          li:nth-child(n + 2) {
            border-left: 1px solid $dark-card-border !important;
          }
        }
      }
      .profile-header {
        .userpro-box {
          background-color: $dark-card-background;
          .icon-wrapper {
            background-color: $dark-body-background;
          }
          h4 {
            color: $dark-all-font-color;
          }
          .avatar {
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .apexcharts-yaxis {
      .apexcharts-text {
        fill: $dark-all-font-color;
      }
    }
    .static-widget {
      h6 {
        color: $dark-all-font-color;
      }
    }
    // deafult dashboard page css  start
    .default-dash {
      .card.profile-greeting {
        background-color: var(--theme-deafult) !important;
      }
      .total-transactions {
        .report-sec {
          .report-content {
            h5 {
              color: $dark-small-font-color;
            }
          }
        }
      }
      .recent-activity {
        table {
          tr {
            td {
              .media {
                .media-body {
                  .activity-msg {
                    background-color: $dark-body-background;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                  h5 {
                    color: $dark-all-font-color;
                  }
                  p {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
        }
      }
      .ongoing-project {
        .table-responsive {
          tr {
            th {
              background-color: $dark-card-inbox;
            }
          }
        }
        table {
          tbody {
            tr {
              td {
                h6 {
                  color: $dark-all-font-color;
                }
                span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      .table-responsive {
        .badge-light-theme-light {
          background-color: $dark-body-background;
          color: $dark-small-font-color !important;
        }
      }
      .news-update {
        h5 {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
      .icon-box {
        .onhover-show-div {
          ul {
            li {
              background-color: $dark-body-background;
              border-bottom: 1px solid $dark-card-background;
              &:last-child {
                border-bottom: none;
              }
              &:hover a {
                color: $primary-color;
              }
              a {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      .earning-card {
        h4,
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }

      .profile-greeting {
        .greeting-user {
          .btn {
            color: $dark-all-font-color;
            border-color: $dark-all-font-color;
            &:hover {
              color: $primary-color;
            }
          }
          h1 {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .apexcharts-tooltip.apexcharts-theme-light {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
    }
    .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
      color: $dark-all-font-color;
    }
    .apexcharts-tooltip-series-group {
      color: $dark-all-font-color !important;
    }
    .apexcharts-yaxistooltip,
    .apexcharts-xaxistooltip {
      color: $dark-all-font-color;
      background: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .apexcharts-yaxistooltip-left {
      &::before,
      &::after {
        border-left-color: $dark-card-border;
      }
    }
    .apexcharts-xaxistooltip-bottom {
      &::before,
      &::after {
        border-bottom-color: $dark-card-border;
      }
    }
    .apexcharts-canvas {
      .apexcharts-tooltip {
        border: 1px solid $dark-card-border;
        .apexcharts-tooltip-title {
          margin-bottom: 0;
        }
        .apexcharts-tooltip-series-group {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
      .apexcharts-yaxis {
        text {
          fill: $dark-small-font-color;
        }
      }
    }
    // dashboard 2

    .ecommerce-dash {
      .total-sale {
        .card-header {
          background-color: transparent !important;
          .animat-block {
            ul {
              li {
                border: 12px solid $dark-card-inbox;
              }
            }
          }
        }
      }
      .ongoing-project {
        table {
          tbody {
            tr {
              td {
                h6 {
                  color: $dark-all-font-color;
                }
                span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      .our-activities {
        table {
          tr {
            td {
              h5 {
                color: $dark-all-font-color;
              }
              p {
                color: $dark-small-font-color;
              }
            }
          }
        }
      }
      .table-responsive {
        tr {
          th {
            background-color: $dark-card-inbox;
          }
        }
        .badge-light-theme-light {
          background-color: $dark-body-background;
          color: $dark-small-font-color !important;
        }
      }
      .hot-selling {
        .table {
          tbody {
            tr {
              td {
                h5 {
                  color: $dark-all-font-color;
                }
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      .icon-box {
        .onhover-show-div {
          ul {
            li {
              background-color: $dark-body-background;
              border-bottom: 1px solid $dark-card-background;
              &:last-child {
                border-bottom: none;
              }
              &:hover a {
                color: $primary-color;
              }
              a {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      .sales-state {
        .sales-small-chart {
          &:after {
            background-color: $dark-card-border;
          }
          h6 {
            color: $dark-all-font-color;
          }
          span {
            color: $dark-small-font-color;
          }
        }
      }
    }

    .crypto-dash {
      .buy-sell {
        .buy-sell-form {
          .form-group {
            .input-group {
              background-color: $dark-body-background;
              span {
                color: $dark-all-font-color;
              }
            }
            label {
              color: $dark-all-font-color;
            }
            .dropdown {
              .dropdown-toggle {
                background-color: $dark-body-background;
                &:hover {
                  background-color: $primary-color;
                }
              }
            }
          }
        }
        .card-header {
          .media {
            ul {
              background-color: $dark-body-background;
            }
          }
        }
      }
      .candlestick-chart {
        .candlestick-detail {
          h3 {
            color: $dark-all-font-color;
          }
          h4 {
            color: $dark-small-font-color;
          }
        }
      }
      .cripto-chat {
        .chat {
          .right-side-chat {
            .msg-box {
              background-color: $dark-body-background;
              color: $dark-all-font-color;
            }
          }
        }
      }
      .table-responsive {
        .badge-light-theme-light {
          background-color: $dark-body-background;
          color: $dark-small-font-color !important;
        }
      }
      .cripto-notification {
        table {
          tr {
            td {
              .media {
                .media-body {
                  h5 {
                    color: $dark-all-font-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                  p {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
        }
      }

      .ongoing-project {
        table {
          tbody {
            tr {
              td {
                h6 {
                  color: $dark-all-font-color;
                }
                span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
    }

    .icon-box {
      .onhover-show-div {
        ul {
          li {
            background-color: $dark-body-background;
            border-bottom: 1px solid $dark-card-background;
            &:last-child {
              border-bottom: none;
            }
            &:hover a {
              color: $primary-color;
            }
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }

    // general widget

    .general-widget {
      .datepicker--day-name {
        color: $dark-all-font-color;
      }
      .widget-feedback {
        h3 {
          color: $dark-all-font-color;
        }
        h6 {
          color: $dark-small-font-color;
        }
        ul {
          border-top: 1px solid $dark-card-border;
          li {
            &:nth-child(n + 2) {
              border-left: 1px solid $dark-card-border;
            }
            h4 {
              color: $dark-small-font-color;
            }
          }
        }
      }
      .contact-form {
        .form-icon {
          i {
            color: $primary-color;
          }
        }
      }

      .social-widget-card {
        .card-footer {
          .row {
            .col:nth-child(n + 2) {
              border-left: 1px solid $dark-card-border;
            }
          }
        }
      }

      .custom-profile {
        .card-footer > div {
          div + div {
            border-left: 1px solid $dark-card-border;
          }
        }
      }
      .widget-joins {
        .widget-card {
          border: 1px solid $dark-card-border;
          background-color: $dark-body-background;
        }
      }
    }

    // =======================  state color page   ======================= //

    .colors-palette-section {
      > .card-body {
        .card {
          .card-body {
            div.color-name {
              background-color: $dark-body-background;

              h6 {
                color: $light-1;
              }
            }
          }
        }
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .resp-serch-input {
              .search-form {
                input {
                  background-color: $dark-card-background;
                  border-color: $dark-card-border;
                  color: $white;
                }
              }
            }

            .cart-dropdown {
              li {
                .media {
                  .cart-quantity {
                    border-color: $dark-card-border;

                    input {
                      color: $dark-all-font-color;
                    }

                    span {
                      i {
                        color: $primary-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .button-builder-wrap {
      .btn-light {
        color: $theme-body-font-color !important;
      }
    }

    .customizer-links {
      > .nav {
        background-color: $dark-body-background;
      }
    }
    .calendar-basic {
      .tui-full-calendar-month-dayname {
        border-top: 1px solid $dark-card-border !important;
        span {
          color: $dark-all-font-color !important;
        }
      }
      .tui-full-calendar-weekday-border {
        border-top: 1px solid $dark-card-border !important;
      }
      .tui-full-calendar-weekday-schedule-title {
        color: $white !important;
      }
      .tui-full-calendar-weekday-grid-header {
        span {
          color: $dark-small-font-color !important;
        }
      }
      #menu-navi {
        border-bottom: 1px solid $dark-card-border;
      }
    }
    .toast {
      overflow: hidden;
      background-color: $dark-body-background;

      .toast-header {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom: 1px solid $dark-card-border;
      }

      .toast-body {
        background-color: $dark-body-background;
        color: $dark-all-font-color;

        .border-top {
          border-color: $dark-card-border !important;
        }
      }
    }

    .irs-grid-pol {
      background: $dark-card-border;
    }
    .progress-showcase {
      .progress {
        background-color: $light-1;
      }
    }
    .page-wrapper {
      pre {
        &.helper-classes {
          background-color: $dark-body-background;
        }
      }
    }
    .list-group {
      .flex-column {
        li {
          + li {
            a {
              border-top: 0;
            }
          }
        }
      }
    }
    .loader-box {
      .loader-30 {
        border-top-color: aliceblue;
        border-bottom-color: aliceblue;
        &::after,
        &::before {
          background: aliceblue;
        }
      }
      .loader-22 {
        border: 1px solid aliceblue;
        &::after,
        &::before {
          background: aliceblue;
        }
      }
      .loader-4 {
        border: 1px solid aliceblue;
        &::before {
          background: aliceblue;
        }
      }
    }
    .knowledgebase-search {
      h2 {
        color: $dark;
      }
    }
    .browse {
      .browse-articles {
        background-color: $dark-card-background;
        h4 {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .loader-10 {
      &::after,
      &::before {
        background: aliceblue;
      }
    }
    .custom-profile {
      .card-social {
        .social-link {
          background-color: $dark-body-background;
          border: 1px solid $dark-card-border;
        }
      }
    }

    .apexcharts-tooltip.light {
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        color: $white;
      }
    }

    .card {
      background-color: $dark-card-background;
    }

    .ecommerce-widget {
      border: 1px solid $dark-card-border !important;
    }
    .plain-style {
      .card {
        border: 0 !important;
      }
    }
    .form-select {
      color: $dark-all-font-color;
      option {
        .form-select {
          color: $black;
        }
      }
    }

    .add-project {
      .text-inherit {
        color: $dark-all-font-color;
      }
    }
    .faq-page {
      .accordion-item {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
      }
      .accordion-header {
        background-color: $dark-card-background;
        .accordion-button {
          background-color: $dark-card-background !important;
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }
      }
      .accordion-body {
        background-color: $dark-card-background;
        color: $dark-all-font-color;
        border-radius: 6px;
      }
    }
    .accordian-page {
      .accordion-item {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
        &:not(:last-of-type) {
          border-bottom: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:not(:first-of-type) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
      .accordion-header {
        background-color: $dark-body-background;
        .accordion-button {
          background-color: transparent !important;
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }
      }
      .accordion-body {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .knowledgebase-search {
      color: $theme-body-font-color;

      .form-control-plaintext {
        color: $theme-body-font-color;
      }
    }

    .btn-close {
      filter: brightness(0.8) invert(1);
    }

    .prooduct-details-box {
      .media {
        border: 1px solid $dark-card-border;
      }
    }

    .theme-form {
      input[type="file"] {
        background-color: $dark-body-background;
      }
    }

    .product-price {
      del {
        color: $dark-small-font-color;
      }
    }
    aw-wizard-navigation-bar {
      ul.steps-indicator {
        p {
          color: $dark-all-font-color !important;
        }
      }
    }
    // form wizard
    aw-wizard-navigation-bar {
      &.horizontal {
        &.small,
        &.large-filled-symbols {
          ul {
            &.steps-indicator {
              li:not(:last-child):after {
                background-color: $light-all-font-color;
              }
            }
          }
        }
        ul {
          &.steps-indicator {
            &.steps-3 {
              li {
                p {
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      &.vertical {
        &.small {
          ul {
            &.steps-indicator {
              li:not(:last-child):after {
                background-color: $light-all-font-color;
              }
            }
          }
        }
      }
    }
    .form-control-plaintext {
      color: $dark-all-font-color;
    }
    .ng-select {
      .ng-select-container {
        background-color: $dark-card-background;
      }
    }
    .form-select {
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;
    }

    .box-layout {
      background-color: $dark-body-background;

      .page-wrapper,
      &.page-wrapper {
        .page-header {
          .header-wrapper {
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    div.dt-autofill-list {
      background-color: $dark-body-background;
      ul {
        li {
          &:hover {
            background-color: $dark-card-background;
          }
        }
      }
      div.dt-autofill-question {
        border-bottom: 1px solid $dark-card-border;
        input {
          background-color: $dark-card-background;
        }
      }
      div.dt-autofill-button {
        border-bottom: 1px solid $dark-card-border;
      }
    }

    .btn.btn-primary {
      z-index: 0;
    }
    .left-side-header {
      .input-group {
        span {
          border-color: $dark-card-border !important;
          svg {
            stroke: $dark-all-font-color;
          }
        }
        input {
          border-color: $dark-card-border !important;
          color: $white;
        }
      }
    }
    .form-check-input {
      background-color: $dark-body-background;
    }

    .switch-state {
      &:before {
        background-color: $dark-card-background;
      }
    }

    .bootstrap-touchspin {
      .dropdown-basic {
        .dropdown {
          .dropbtn {
            background-color: $dark-body-background !important;
            color: $dark-all-font-color !important;
          }
        }
      }
    }
    #auto-generate-content_wrapper.dataTables_wrapper {
      button {
        background-color: $dark-body-background !important;
      }
    }

    // daterangepicker css start
    .daterangepicker {
      &:before {
        border-bottom-color: $dark-card-background;
      }

      .ranges {
        li {
          background-color: $dark-body-background;
          border-color: $dark-card-background;
          color: $dark-gray;
        }
      }

      .daterangepicker_input {
        .form-control {
          background-color: $dark-body-background;
          border-color: $dark-card-background !important;
        }
      }

      .calendar-time {
        select {
          border-color: $dark-card-background;
          background-color: $dark-body-background;
        }
      }

      &.dropdown-menu {
        background-color: $dark-card-background;
        -webkit-box-shadow: 0 0 14px $dark-body-background;
        box-shadow: 0 0 14px $dark-body-background;
      }

      .calendar-table {
        background-color: $dark-card-background;
        border-color: $dark-card-background;
      }

      &.ltr {
        td {
          &.in-range,
          &.off {
            background-color: $dark-body-background;
            color: $white;
          }
        }
      }
    }

    .datepickers-container {
      .datepicker {
        i.datepicker--pointer {
          z-index: -1;
          background-color: $dark-body-background;
        }
        .datepicker--nav {
          border-bottom: 1px solid $dark-card-border;
        }
        .datepicker--content {
          .datepicker--day-name,
          .datepicker--cell-month {
            color: $dark-all-font-color;
          }
          .datepicker--cell-day {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .date-picker {
      .datepicker {
        .datepicker--day-name {
          color: $dark-all-font-color;
        }
        .datepicker--cells-days {
          color: $dark-small-font-color;
        }
        .datepicker--nav {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }

    .clockpicker-popover {
      .popover-title {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-card-border;
      }
      .popover-content {
        background-color: $dark-card-background;
        .clockpicker-plate {
          background-color: $dark-body-background;
        }
        .clockpicker-tick {
          color: $primary-color;
          &:hover {
            color: $dark;
          }
        }
      }
    }
    // daterangepicker css end
    // tooltip css start
    // tooltip css start
    .tooltip {
      &.bs-tooltip-top {
        .tooltip-arrow {
          &:before {
            border-top-color: $dark-card-inbox;
          }
        }
      }

      &.bs-tooltip-bottom {
        .tooltip-arrow {
          &:before {
            border-bottom-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }

      &.bs-tooltip-start {
        .tooltip-arrow {
          &:before {
            border-left-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }

      &.bs-tooltip-end {
        .tooltip-arrow {
          &:before {
            border-right-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }

      .tooltip-inner {
        background-color: $dark-card-inbox;
        color: $white;
      }

      .tooltip-arrow {
        &:before {
          border-top-color: $dark-card-inbox;
        }
      }
    }

    // tooltip css end

    .page-wrapper {
      &.only-body {
        .page-body-wrapper {
          .page-title {
            > .row {
              .col-6 {
                &:first-child {
                  h6 {
                    color: rgba($dark-all-font-color, 0.5);
                  }
                }
              }
            }
          }
        }
      }

      .page-header {
        .header-wrapper {
          .nav-right {
            .profile-dropdown li span {
              color: $white;
            }

            .flip-card .flip-card-inner {
              .back {
                background-color: $dark-card-background !important;

                .flip-back-content {
                  input {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                    color: $dark-all-font-color;
                  }
                }

                li {
                  &:last-child {
                    border-top: 0;
                  }
                }
              }

              .front {
                background-color: $dark-body-background !important;

                .bookmark-dropdown {
                  li {
                    .bookmark-content {
                      .bookmark-icon {
                        border-color: $dark-card-border;
                        svg {
                          stroke: $dark-all-font-color;
                        }
                      }
                      &:hover {
                        svg {
                          stroke: var(--theme-deafult);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .bookmark-tabcontent {
      .details-bookmark {
        .bookmark-card.card {
          .title-bookmark {
            border-top: unset;
          }
        }
      }
      .tab-content {
        .card {
          .d-flex {
            border-bottom: 1px solid $dark-card-border;
          }
        }
      }
    }
    .customizer-contain {
      color: $dark-all-font-color;
      background-color: $dark-card-background;

      li {
        &.active {
          box-shadow: 0 0 11px 5px rgba(29, 30, 38, 1);
        }
      }

      .customizer-header {
        border-color: $dark-card-border;
      }

      .customizer-body {
        .sidebar-type,
        .sidebar-setting {
          > li {
            border-color: $dark-card-border;
          }
        }

        .layout-grid {
          li {
            border-color: $dark-card-border;

            .header {
              background-color: $dark-body-background !important;
            }

            .body {
              background-color: $dark-card-background !important;

              ul {
                background-color: $dark-card-background;

                li {
                  background-color: $dark-body-background !important;
                }
              }
            }
          }

          &.customizer-mix {
            .color-layout[data-attr="light-only"],
            [data-attr="dark-sidebar"] {
              background-color: $white;

              .header {
                background-color: $light !important;
              }

              .body {
                background-color: $light !important;

                ul {
                  background-color: $white;

                  li {
                    background-color: $light !important;
                  }
                }
              }
            }

            .color-layout[data-attr="dark-sidebar"] {
              .body {
                ul {
                  .sidebar {
                    background-color: $dark-card-background !important;
                  }
                }
              }
            }

            .color-layout[data-attr="dark-only"] {
              border-color: $dark-card-border;
            }
          }
        }
      }
    }
    .learning-box {
      .details-main {
        background-color: $dark-card-background;
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .blog-single {
      .comment-box {
        .comment-social {
          li {
            &:nth-child(n + 2) {
              border-left: 1px solid $dark-card-border;
            }
          }
        }
        h4 {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .add-post {
      .needs-validation {
        label {
          color: $dark-all-font-color !important;
        }
      }
    }
    .blog-list {
      .blog-details {
        h6 {
          color: $dark-all-font-color !important;
        }
      }
    }
    .blog-box {
      .blog-details {
        .blog-social {
          li {
            &:nth-child(n + 2) {
              border-left: 1px solid $dark-card-border;
            }
          }
        }
        hr {
          background-color: $dark-card-background;
        }
      }
      .blog-details-second {
        .detail-footer {
          border-top: 1px solid $dark-card-border;
          ul.sociyal-list {
            li {
              &:nth-child(n + 2) {
                border-left: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .profile-dropdown {
              li {
                border-bottom: 1px solid $dark-card-border;
                &:last-child {
                  border-bottom: 0;
                }
                span {
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .job-search {
      .job-description {
        border-top: 1px solid $dark-card-border;
        .theme-form {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }

    .login-card {
      .btn-showcase .btn {
        background-color:  $dark-card-background !important;
        color: #fff;
        border: $dark-card-background !important;
      }

      .login-main {
        box-shadow: 0 0 37px rgba($white, 0.05);
        .theme-form {
          input {
            background-color: $dark-card-background !important;
          }

          .or {
            &:before {
              background-color: $dark-card-background;
            }
          }

          .checkbox label::before {
            background-color: $dark-card-background !important;
            border-color: $dark-card-background !important;
          }
        }
      }
    }

    .H_ui {
      ~ div {
        background-color: $dark-body-background !important;
      }
    }

    .swal-icon--success:after,
    .swal-icon--success:before {
      background: $dark-card-background;
    }

    .swal-icon--success__hide-corners {
      background-color: $dark-card-background;
    }

    .note-editor.note-frame {
      .note-status-output {
        border-top: 1px solid $dark-card-background;
      }

      .note-statusbar {
        border-top: 1px solid $dark-card-background;

        .note-resizebar {
          background-color: $dark-card-background;
        }
      }
    }

    .light-font {
      color: rgba(255, 255, 255, 70%);
    }

    .page-link {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }

    .b-r-light {
      border-right: 1px solid $dark-card-border !important;
    }

    .history-details {
      .media {
        border-top: 1px solid $dark-card-border;
      }
    }

    .modal-header {
      border-bottom: 1px solid $dark-card-border;
    }

    #right-history {
      background-color: $dark-card-background;
      box-shadow: 0 0 9px $dark-body-background;

      h6 {
        span {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }

    .ProfileCard {
      &:hover {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
    }

    .translate_wrapper.active .more_lang {
      &:before {
        border-bottom: 7px solid $dark-card-background;
      }

      .lang {
        border-bottom: 1px solid $dark-card-border;
      }
    }

    .toggle-sidebar {
      svg {
        stroke: var(--theme-deafult);
      }
    }

    .page-wrapper {
      .page-body-wrapper {
        background-color: $dark-body-background;

        .page-title {
          background-color: transparent;
        }
      }

      .sidebar-main-title {
        p {
          color: $dark-all-font-color;
        }
      }

      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            background-color: $dark-card-background;

            .sidebar-main {
              .sidebar-links {
                .sidebar-link.active {
                  svg {
                    stroke: var(--theme-deafult);
                  }
                }

                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }

                    svg {
                      stroke: $dark-all-font-color;
                    }
                  }
                }

                > li {
                  .sidebar-link {
                    &.active {
                      background-color: rgba($primary-color, 0.1);

                      svg {
                        color: var(--theme-deafult);
                        stroke: var(--theme-deafult);
                      }
                    }
                  }

                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                          }
                        }

                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                font-weight: 400;
                              }
                            }
                          }
                        }
                      }
                    }

                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                }

                .sidebar-list {
                  &:hover {
                    > a {
                      &:hover {
                        background-color: rgba($primary-color, 0.1) !important;
                      }
                    }
                  }

                  ul.sidebar-submenu {
                    li {
                      a {
                        span {
                          color: $dark-all-font-color;
                        }

                        &.active {
                          color: var(--theme-deafult);
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }

      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            background: $dark-card-background;
            border-top: 1px solid $dark-card-border;

            .sidebar-main {
              .sidebar-links {
                > li {
                  > a {
                    color: $dark-all-font-color;
                  }

                  .sidebar-submenu {
                    background: $dark-card-background;

                    li {
                      a {
                        color: $dark-all-font-color;

                        &.active,
                        &:hover {
                          color: var(--theme-deafult);
                        }
                      }

                      .nav-sub-childmenu {
                        background: $dark-card-background;
                      }
                    }
                  }

                  .mega-menu-container {
                    background: $dark-card-background;

                    .mega-box {
                      .link-section {
                        .submenu-content {
                          li {
                            a {
                              color: $dark-all-font-color;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .left-arrow,
              .right-arrow {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }

      &.compact-sidebar {
        .page-body-wrapper {
          div {
            &.sidebar-wrapper {
              > div {
                background: $dark-card-background;
              }

              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-title {
                      border-bottom: 1px solid $dark-card-border;
                    }

                    a {
                      span {
                        color: $dark-all-font-color;
                      }

                      svg {
                        stroke: $dark-all-font-color;
                      }
                    }

                    .sidebar-submenu,
                    .mega-menu-container {
                      background-color: $dark-card-background;

                      li {
                        a {
                          border-bottom: 1px solid $dark-card-border;
                        }
                      }
                    }
                  }

                  > li {
                    .sidebar-link {
                      &.active {
                        background-color: rgba($primary-color, 0.2);

                        span {
                          color: var(--theme-deafult);
                        }

                        svg {
                          color: var(--theme-deafult);
                          stroke: var(--theme-deafult);
                        }
                      }
                    }

                    .mega-menu-container {
                      .mega-box {
                        .link-section {
                          .submenu-title {
                            h5 {
                              color: $dark-all-font-color;
                            }
                          }

                          .submenu-content {
                            &.opensubmegamenu {
                              li {
                                a {
                                  color: $dark-all-font-color;
                                  font-weight: 400;
                                }
                              }
                            }
                          }
                        }
                      }

                      &::after {
                        background-color: $light-all-font-color;
                      }
                    }

                    .sidebar-submenu {
                      li {
                        a {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                  }

                  .sidebar-list {
                    ul.sidebar-submenu {
                      li {
                        a {
                          span {
                            color: $dark-all-font-color;
                          }

                          &.active {
                            color: var(--theme-deafult);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }

    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    .kanban-board-header {
      background-color: $dark-body-background !important;
    }

    .kanban-board .kanban-drag {
      background: $dark-body-background !important;
    }

    .kanban-container {
      .kanban-item {
        background-color: $dark-body-background;
        .kanban-box {
          border: 1px solid rgba($primary-color, 0.15);
          background-color: $dark-body-background;
        }
      }
    }

    .project-box {
      border: 1px solid rgba($primary-color, 0.15);
      background-color: $dark-body-background;
    }

    .file-content {
      .form-inline {
        border: 1px solid $dark-card-border;
      }
      .folder .folder-box {
        border: 1px solid rgba($primary-color, 0.15);
      }
    }

    .file-sidebar {
      h6 {
        color: $dark-gray;
      }
      .pricing-plan {
        border: 1px solid rgba($primary-color, 0.15) !important;
      }

      .btn-light {
        color: $dark-all-font-color;
        background-color: $dark-body-background !important;
        border: 1px solid rgba($primary-color, 0.15) !important;
      }
    }

    #lnb {
      border-right: 1px solid $dark-card-border;
    }

    .lnb-new-schedule,
    .lnb-calendars > div {
      border-bottom: 1px solid $dark-card-border;
    }

    #menu .sidebar-list .btn-default {
      color: rgba($dark-all-font-color, 0.2);
    }

    .tui-full-calendar-timegrid-hour {
      background-color: $dark-card-background;
      color: $white !important;
    }

    .tui-full-calendar-timegrid-gridline {
      border-bottom: 1px solid $dark-card-border !important;
    }

    .tui-full-calendar-time-date,
    .tui-full-calendar-weekday-grid-line,
    .tui-full-calendar-left,
    .tui-full-calendar-timegrid-timezone {
      border-right-color: $dark-card-border !important;
    }

    .tui-full-calendar-popup {
      color: $theme-body-font-color;
    }

    #menu {
      .btn-default {
        color: $dark-all-font-color;

        &:hover {
          color: $theme-body-font-color;
        }
      }

      .dropdown-menu {
        color: $dark-all-font-color;
      }
    }

    .tui-full-calendar-dayname-container,
    .tui-full-calendar-splitter {
      border-top-color: $dark-card-border !important;
      border-bottom-color: $dark-card-border !important;
    }

    span.tui-full-calendar-dayname-date-area {
      color: $white !important;
    }

    .tui-full-calendar-layout {
      background-color: $dark-card-background !important;
    }

    // index page css end
    .blockquote {
      border-left: 4px solid $dark-card-border;
    }

    .figure {
      &.text-end {
        blockquote {
          border-right-color: $dark-card-border;
        }
      }
    }

    .contacts-tabs {
      .nav-pills {
        border-left: 1px solid $dark-card-border !important;
        border-right: 1px solid $dark-card-border;

        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-card-border;
          }
        }
      }
    }

    .list-persons {
      .profile-mail {
        .media {
          .media-body {
            ul {
              li {
                + li {
                  border-left: 2px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;

                .new-products {
                  .product-box {
                    .product-details {
                      .p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .apexcharts-gridline {
      stroke: $dark-card-border;
    }

    .apexcharts-tooltip-title,
    .apexcharts-tooltip-series-group,
    .apexcharts-tooltip.light,
    .apexcharts-menu-item {
      color: $black;
    }

    // peity chart css
    .small-donut,
    .donut-peity2,
    .pie-peity {
      ~ svg,
      svg {
        path {
          &:nth-child(even) {
            fill: $dark-body-background;
          }
        }
      }
    }

    // index page css start

    .chart_data_left,
    .chart_data_right {
      .card-body {
        .chart-main {
          .media {
            border-right: 1px solid $dark-card-border;

            &.border-none {
              border-right: none;
            }
          }
        }

        .media {
          .right-chart-content {
            span {
              color: $dark-all-font-color !important;
            }
          }
        }
      }
    }

    .chart_data_right {
      .card-body {
        .knob-block {
          input {
            color: $dark-all-font-color !important;
          }
        }
      }
    }

    .third-news-update,
    .news {
      .card-body {
        &.chat-box {
          .input-group {
            .form-control {
              background-color: $dark-card-background !important;
              border: none !important;
              border-left: 1px solid var(--theme-deafult) !important;

              &::placeholder {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }

    .appointment-table {
      table {
        tr {
          td {
            &.img-content-box {
              .font-roboto , .font-poppins, .font-montserrat{
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }

    .notification {
      .card {
        .media {
          .media-body {
            p {
              span {
                color: $dark-all-font-color;
              }
            }

            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }

    .datepicker {
      background-color: $dark-card-inbox;

      .datepicker--cell.-current- {
        color: $primary-color;
      }
    }

    .cal-date-widget {
      .datepicker {
        background-color: $dark-card-inbox;

        .datepicker--cell-day {
          color: $white;
        }

        .datepicker--cell.-current- {
          color: $primary-color;
        }
      }
    }

    .default-datepicker {
      .datepicker-inline {
        .datepicker {
          background: $dark-card-background;

          .datepicker--nav-title {
            color: $dark-all-font-color;

            i {
              color: $dark-all-font-color;
            }
          }

          .datepicker--content {
            .datepicker--days {
              .datepicker--days-names,
              .datepicker--cells {
                .datepicker--day-name,
                .datepicker--cell-day {
                  color: $dark-all-font-color;

                  &.-selected- {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }

    // index page css end
    // ecommerce dashboard css start
    .dash-chart {
      .media {
        .media-body {
          p {
            color: $dark-all-font-color;

            .font-roboto, .font-poppins, .font-montserrat {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }

    .our-product {
      table {
        tr {
          td {
            p {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .cke_contents {
      background-color: $dark-card-background;
    }

    .activity-timeline {
      .media {
        .activity-line {
          background-color: $light-all-font-color;
        }

        .media-body {
          p {
            color: $dark-all-font-color;
          }
        }
      }
    }

    .total-users {
      .goal-status {
        ul {
          &:last-child {
            li {
              border-top-color: $dark-card-border;
            }
          }
        }
      }
    }

    .best-seller-table {
      table {
        tbody {
          tr {
            td {
              .label {
                color: $dark-all-font-color;
              }

              p {
                color: $light-all-font-color;
              }
            }
          }
        }
      }
    }

    [class*="activity-dot-"] {
      background-color: $dark-card-background;
    }

    // ecommerce dashboard css end

    $alert-name: primary, secondary, success, danger, warning, info, light, dark;
    $alert-color: $primary-color, $secondary-color, $success-color,
      $danger-color, $warning-color, $info-color, $light-color, $dark-color;

    @each $var in $alert-name {
      $i: index($alert-name, $var);

      .alert-#{$var} {
        background-color: rgba(nth($alert-color, $i), 0.8) !important;
        border-color: rgba(nth($alert-color, $i), 0.9) !important;
        color: $white;

        .progress {
          height: 5px;
          background-color: darken(nth($alert-color, $i), 1%);
          border-radius: 0;
        }

        .progress-bar {
          background-color: lighten(nth($alert-color, $i), 50%);
        }
      }

      .alert-#{$var}.inverse {
        background-color: $transparent-color !important;

        .btn-close {
          filter: brightness(1) invert(1);
        }
      }

      .alert-#{$var}.outline,
      .alert-#{$var}.outline-2x {
        .btn-close {
          filter: brightness(1) invert(1);
        }
      }

      .dismiss-text {
        .alert-dismissible {
          .btn-close {
            filter: unset;

            .bg-light {
              color: $theme-body-font-color !important;
            }
          }
        }
      }
    }

    .options {
      > div {
        border: 1px solid $dark-card-border;
      }
    }
    .was-validated {
      .custom-control-input {
        &:invalid {
          ~ .custom-control-label {
            &::before {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .was-validated .form-control:valid,
          .form-control.is-valid {
            border-color: #198754;
          }
          .was-validated .form-control:invalid,
          .form-control.is-invalid {
            border-color: #dc3545;
          }
        }
      }
    }
    .pricing-simple {
      box-shadow: 1px 1px 2px 1px $dark-body-background !important;
    }

    .pricing-block {
      box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
    }

    .search-page {
      .nav-tabs {
        border-bottom: unset;
      }
      ul {
        &.search-info {
          li {
            + li {
              border-left: 1px solid $dark-card-border;
            }
          }
        }
      }
    }

    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }

    .job-search {
      .job-description {
        .theme-form {
          .row {
            div[class^="col-"] {
              .input-group {
                .datepicker-here {
                  border: 1px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }

    .calendar-wrap {
      .fc-unthemed {
        td,
        th {
          border-color: $dark-body-background;
        }
      }
    }

    .mega-inline,
    .mega-horizontal,
    .mega-vertical {
      .card {
        box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
      }

      &.plain-style,
      &.border-style,
      &.offer-style {
        .card {
          box-shadow: none !important;
        }
      }

      &.border-style {
        .card {
          border: 1px solid $dark-card-border !important;
        }
      }

      &.offer-style {
        .card {
          border: 1px dashed $dark-card-border !important;
        }
      }
    }

    .setting-dot {
      .setting-bg {
        background-color: var(--theme-secondary);
      }
    }

    .bookmark.pull-right {
      border: none;
    }

    // popover css start
    .popover {
      &.bs-popover-bottom {
        .popover-arrow {
          &:after {
            border-bottom-color: $dark-body-background;
            border-right-color: $transparent-color;
          }
        }
      }

      &.bs-popover-top {
        .popover-arrow {
          &:after {
            border-right-color: $transparent-color;
            border-top-color: $dark-body-background;
          }
        }
      }

      &.bs-popover-start {
        .popover-arrow {
          &:after {
            border-left-color: $dark-body-background;
          }
        }
      }

      background-color: $dark-body-background;

      .popover-header {
        background-color: $dark-card-background;
      }

      .popover-arrow {
        &:after {
          border-right-color: $dark-body-background;
        }
      }

      .popover-body {
        color: rgba(255, 255, 255, 0.6);
      }

      code {
        background-color: unset;
      }
    }

    // popover css end
    // apex chart css start
    .apex-chart-container {
      .apexcharts-legend {
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
      }
    }

    .apexcharts-canvas {
      svg {
        .apexcharts-title-text {
          fill: $white;
        }

        .apexcharts-subtitle-text {
          fill: $white;
        }

        .apexcharts-yaxis {
          .apexcharts-yaxis-texts-g {
            .apexcharts-yaxis-label {
              fill: $dark-all-font-color;
            }
          }
        }

        .apexcharts-xaxis {
          .apexcharts-xaxis-texts-g {
            .apexcharts-xaxis-label {
              fill: $dark-all-font-color;
            }
          }
        }

        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }

        .apexcharts-datalabels-group {
          .apexcharts-datalabel-label {
            fill: $white;
          }

          .apexcharts-datalabel-value {
            fill: $white;
          }
        }
      }
    }

    // apex chart css end
    .Typeahead-menu {
      background-color: $dark-body-background;
    }

    .ecommerce-widget {
      border: 1px solid $dark-card-border;
    }

    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
              }

              &:before {
                background: $dark-card-border;
              }
            }
          }
        }
      }
    }

    // cart css start
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: #2e3648 !important;
            border-color: $dark-card-border !important;
          }
        }
      }
    }

    // cart css end
    // checkout css start
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
        padding: 40px;

        .animate-chk {
          .radio_animated {
            &:after {
              border: 2px solid $dark-card-background;
            }
          }
        }
      }
    }

    // checkout css end
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-card-border;
      }

      .qty {
        li {
          color: #bfc2c6;

          span {
            color: #bfc2c6;
          }
        }

        border-bottom: 1px solid $dark-card-border;
      }

      .sub-total {
        li {
          color: #bfc2c6;
        }
      }

      .total {
        li {
          color: #bfc2c6;
        }
      }
    }

    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-card-background;
        }
      }
    }

    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-card-border;
        }
      }
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }

    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }

              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }

    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }

    .slider-product {
      border-top: 1px solid $dark-card-border;
      border-bottom: 1px solid $dark-card-border;
    }

    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;

        svg {
          color: $dark-all-font-color;
        }
      }
    }

    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }

        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-card-border;
            }

            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-card-border;
            }
          }
        }
      }
    }

    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;

            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }

    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }

    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }

    .loader-wrapper {
      background-color: $dark-body-background;

      .loader {
        background-color: $dark-body-background !important;
      }
    }

    .page-wrapper {
      /* Main Header start */
      .page-header {
        .translate_wrapper {
          &.active {
            .more_lang {
              background-color: $dark-body-background;
            }

            .lang {
              background-color: $dark-card-background;
            }
          }
        }

        form {
          background-color: $dark-body-background;
        }

        .header-wrapper {
          background-color: $dark-card-background;

          li {
            i {
              color: $light-color;
            }
          }

          .nav-right {
            > ul {
              > li {
                svg {
                  stroke: $dark-small-font-color;
                  path {
                    stroke: $dark-small-font-color;
                  }
                }
              }
            }

            &.right-header {
              > ul {
                > li {
                  .profile-media {
                    .media-body {
                      p {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }

            .nav-menus {
              li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }

                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 14px $dark-body-background;
                  &.cart-dropdown {
                    li:not(:last-child) {
                      border-bottom: 1px solid $dark-card-border !important;
                    }
                  }

                  p {
                    color: $dark-all-font-color;
                  }

                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }

                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }

                .notification-dropdown.onhover-show-div {
                  li {
                    span {
                      color: $dark-small-font-color;
                    }
                  }

                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
              }
            }

            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;

                  svg {
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }

                  &:hover {
                    color: var(--theme-deafult);

                    svg {
                      line,
                      path,
                      circle {
                        color: var(--theme-deafult) !important;
                      }
                    }
                  }
                }
              }
            }

            > ul {
              > li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }

                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;

                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }

                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }

                .notification-dropdown.onhover-show-div {
                  li {
                    border-color: $dark-card-background;

                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }

            .chat-dropdown {
              li {
                color: $white;
                border-color: $dark-card-background;
              }
            }
          }
        }

        .header-logo-wrapper {
          background-color: $dark-card-background;

          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }

              .image-light {
                display: block;
                height: 50px;
              }
            }
          }
        }
      }

      /* Main Header ends */
      .page-body-wrapper {
        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }

          .header-small {
            color: $dark-small-font-color;
          }

          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }

          .feather-main,
          .professor-table {
            .media-body,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }

          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }

          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }

          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }

          .new-users,
          .recent-notification {
            .media {
              .media-body {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }

          .progress-media {
            .media {
              .media-body {
                span {
                  color: $dark-small-font-color;
                }
              }
            }

            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }

          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-card-border;
                  }

                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }

          .notifiaction-media {
            .media {
              .media-body {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }

          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-card-border;

              &:last-child {
                border-bottom: none;
              }
            }
          }

          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }

          .number-widgets {
            .media {
              .media-body {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }

          .activity {
            .media {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-card-border;
                  }
                }
              }

              .media-body {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }

          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }

        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }

        .page-title {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb {
            li {
              color: var(--theme-deafult);
            }

            .breadcrumb-item {
              & + .breadcrumb-item {
                &:before {
                  color: $dark-all-font-color;
                  font-weight: 500;
                }
              }

              a {
                svg {
                  color: var(--theme-deafult);
                }
              }
            }
          }

          .breadcrumb-item {
            &.active {
              color: var(--theme-deafult);
            }
          }
        }

        .page-body {
          background-color: $dark-body-background;

          .default-according {
            .card:not(.email-body) {
              border: none;
            }
          }

          .card:not(.email-body) {
            background-color: $dark-card-background;
            box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
            border: 1px solid $dark-card-border;

            .chart-block {
              #bar-chart2 {
                svg {
                  > rect {
                    fill: $dark-card-background;
                  }

                  > g {
                    text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }

              .word-tree {
                svg {
                  > g {
                    > rect {
                      fill: $dark-card-background;
                    }

                    > text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
            }

            .card-header {
              background-color: $dark-card-background;

              > span {
                color: $dark-all-font-color;
              }

              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }

              .card-header-right {
                background-color: $dark-card-background;

                i {
                  color: $dark-all-font-color;

                  &.fa-cog {
                    color: var(--theme-deafult);
                  }
                }
              }
            }

            .alert-dark {
              color: $dark-small-font-color;

              a {
                color: $dark-small-font-color;
              }
            }

            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }

            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }

            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
              }
            }

            .line {
              color: $dark-all-font-color;
            }

            thead,
            tbody,
            tfoot,
            tr,
            td,
            th {
              border-color: $dark-card-border;
            }

            .table {
              th,
              td {
                color: $dark-all-font-color;
              }

              thead {
                th {
                  border-bottom: 1px solid $dark-card-border;
                }

                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid var(--theme-deafult);
                  }
                }
              }

              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $primary-color;
                }
              }

              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }

              .bg-light {
                color: $black;
              }

              .thead-light {
                th {
                  color: $black;
                }
              }

              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid var(--theme-deafult);
                  }
                }
              }
            }

            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }

            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
                    background-color: $dark-body-background;
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }

            .table-double,
            .table-dotted,
            .table-dashed {
              border-left-color: $dark-card-border;
              border-right-color: $dark-card-border;
            }

            .table-bordered {
              border-color: $dark-card-border !important;

              td,
              th {
                border-color: $dark-card-border !important;
              }
            }

            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }

            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-card-border;
              }
            }

            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }

            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
            }

            .switch {
              .switch-state {
                background-color: $dark-body-background;
                &:after {
                  color: $dark-all-font-color;
                }
              }

              input {
                &:checked {
                  + .switch-state {
                    background-color: var(--theme-deafult);
                  }
                }
              }
            }

            .bg-white {
              background-color: $white !important;
            }

            .b-l-light {
              border-left: 1px solid $dark-card-border !important;
            }

            .ct-grid {
              stroke: $dark-card-border;
            }

            .ct-label {
              color: $dark-small-font-color;
            }

            hr {
              border-top: 1px solid $dark-card-border;
            }

            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }

            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
              }
            }

            .contact-form {
              .theme-form {
                border: 1px solid $dark-card-border;

                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-card-border;
                  color: $dark-all-font-color;
                }
              }
            }

            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
              border: 1px solid $dark-card-border;
            }

            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }

            .border-right {
              border-right: 1px solid $dark-card-border !important;
            }

            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }

              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }

            .chart-overflow {
              &#gantt_chart {
                svg {
                  g {
                    rect {
                      &:first-child {
                        fill: $dark-body-background;
                      }
                    }
                  }
                }

                rect {
                  &:nth-child(6) {
                    fill: $dark-body-background;
                  }
                }
              }

              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }

              svg {
                > rect {
                  fill: $dark-card-background;
                }

                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }

                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }

            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }

            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;

                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }

            .b-r-light {
              border-right: 1px solid $dark-card-border !important;
            }

            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }

                .ct-grid {
                  stroke: $white;
                }
              }

              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }

            .status-details {
              h4 {
                color: $dark-all-font-color;

                span {
                  color: $dark-all-font-color;
                }
              }

              span {
                color: $dark-small-font-color;
              }
            }

            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-card-border;
              }
            }

            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-card-border;
            }

            .dataTables_wrapper {
              button {
                color: $black;
              }

              &.no-footer {
                .dataTables_scrollBody {
                  border-bottom: 1px solid $dark-card-border;
                }
              }

              .btn-danger,
              .btn-success,
              .btn-primary {
                color: $white;
              }

              .dataTables_length {
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-card-border;
                }
              }

              .dataTables_length,
              .dataTables_filter,
              .dataTables_info,
              .dataTables_processing,
              .dataTables_paginate {
                color: $dark-all-font-color;
              }

              .dataTables_paginate {
                border: 1px solid $dark-card-border;
              }

              .dataTables_filter {
                input[type="search"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                  color: $dark-all-font-color;
                }
              }

              table.dataTable.display,
              table.dataTable.order-column.stripe {
                tbody {
                  tr {
                    background-color: $dark-card-background;

                    &:hover {
                      > .sorting_1 {
                        background-color: $dark-datatable-sorting;
                      }
                    }
                  }

                  tr.odd {
                    background-color: $dark-datatable-odd;

                    > .sorting_1 {
                      background-color: $dark-datatable-sorting;
                    }
                  }

                  tr.even {
                    > .sorting_1 {
                      background-color: $dark-datatable-sorting-even;
                    }
                  }
                }
              }

              table.dataTable {
                border: 1px solid $dark-card-border;

                thead {
                  th,
                  td {
                    border-bottom: 2px solid $dark-card-border;
                  }
                }

                input,
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-card-border;
                }

                tbody {
                  tr {
                    background-color: $dark-card-background;
                  }

                  td.select-checkbox,
                  th.select-checkbox {
                    &:before {
                      border: 1px solid $dark-small-font-color;
                    }
                  }
                }
              }

              .table-striped {
                tbody {
                  tr {
                    &:nth-of-type(odd) {
                      --bs-table-accent-bg: #1d1e26;
                    }
                  }
                }
              }

              .dataTables_paginate {
                .paginate_button {
                  color: $dark-all-font-color !important;

                  &.current,
                  &:active {
                    border-color: var(--theme-deafult);
                  }
                }

                .paginate_button.disabled {
                  color: $dark-small-font-color !important;

                  &:hover,
                  :active {
                    color: $dark-small-font-color !important;
                  }
                }
              }

              table.dataTable.row-border,
              table.dataTable.display {
                tbody {
                  th,
                  td {
                    border-top: 1px solid $dark-card-border;
                  }
                }
              }

              table.dataTable.display,
              table.dataTable.order-column.hover {
                tbody {
                  tr.even {
                    &:hover {
                      > .sorting_1 {
                        background-color: $dark-even-hover-sorting;
                      }
                    }
                  }
                }
              }

              table.dataTable.hover,
              table.dataTable.display {
                tbody {
                  tr {
                    &:hover {
                      background-color: $dark-datatable-sorting;
                    }
                  }
                }
              }

              table.dataTable.cell-border {
                th,
                td {
                  border-top: 1px solid $dark-card-border;
                  border-right: 1px solid $dark-card-border;

                  &:first-child {
                    border-left: 1px solid $dark-card-border;
                  }
                }
              }

              table.dataTable.order-column,
              table.dataTable.display {
                tbody {
                  tr {
                    > .sorting_1,
                    > .sorting_2,
                    > .sorting_3 {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }

            #example-style-3_wrapper {
              #example-style-3 {
                tfoot {
                  border-top: 2px solid $dark-card-border;
                }
              }
            }

            .page-link {
              border: 1px solid $dark-card-border;
            }

            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-card-border;
              }
            }

            .page-link {
              color: $dark-all-font-color;
              background-color: $dark-card-background;
            }

            .page-item {
              &:hover {
                .page-link {
                  background-color: $dark-body-background;
                }
              }
            }

            .page-item.active {
              .page-link {
                background-color: $dark-body-background;
              }
            }

            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }

              .total-num {
                color: $dark-all-font-color;

                span {
                  color: $dark-all-font-color;
                }
              }

              span {
                color: $dark-small-font-color;
              }
            }

            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
            }

            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }

              h6 {
                color: $dark-small-font-color;
              }
            }

            .border-tab.nav-tabs {
              border-bottom: 1px solid $dark-card-border;

              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }

              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: var(--theme-deafult);
                }
              }
            }

            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-card-border;

                  &.br-active,
                  &.br-selected {
                    background-color: var(--theme-deafult);
                  }
                }
              }
            }

            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-card-border;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;

                  &.br-active,
                  &.br-selected {
                    border: 2px solid var(--theme-deafult);
                    color: var(--theme-deafult);
                  }
                }
              }
            }

            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: var(--theme-deafult);
                    }
                  }
                }
              }
            }

            .scroll-demo {
              border: 1px solid $dark-card-border;
            }

            .search-form {
              input {
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }

              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }

            .cd-timeline-content {
              background-color: $dark-body-background;
            }
            .breadcrumb {
              &.bg-white {
                background-color: $dark-card-background !important;
              }
            }

            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }

            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }

          .form-builder-header-1,
          .form-builder-2-header {
            background-color: $dark-body-background;
          }

          .form-builder {
            .nav-primary {
              .show {
                > .nav-link {
                  color: $white;
                }
              }

              .nav-link {
                color: $dark-all-font-color;

                &.active {
                  color: $white;
                }
              }

              .nav-pills.nav-primary {
                .show {
                  > .nav-link {
                    color: $white;
                  }
                }

                .nav-link {
                  color: $dark-all-font-color;

                  &.active {
                    color: $white;
                  }
                }
              }
            }

            .drag-box {
              fieldset {
                border: 1px solid $dark-card-border;
              }
            }

            .help-block {
              color: $dark-small-font-color;
            }
          }

          #viewhtml {
            .render {
              background-color: $dark-card-background;
              color: $dark-all-font-color;
              border-color: $dark-card-border;
            }
          }
          .wizard-4 {
            ul.anchor {
              li {
                a.disabled {
                  color: $dark-body-background;
                }
              }
            }
          }

          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }

            a {
              > div {
                border: 1px solid $dark-card-border;
              }
            }

            p {
              color: $dark-small-font-color;
            }
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;

                &:hover {
                  color: var(--theme-deafult);
                }
              }
            }

            .separator {
              border-bottom: 1px solid $dark-card-border;
            }
          }

          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }

          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: var(--theme-deafult) !important;
                }
              }
            }
          }

          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }

                &:hover {
                  color: var(--theme-deafult);

                  a {
                    color: var(--theme-deafult);
                  }
                }
              }
            }
          }

          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 14px 0 $dark-body-background;

              .navs-icon {
                li {
                  p {
                    color: $white;
                  }

                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }

                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: var(--theme-deafult) !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }

          .default-according {
            .card {
              .btn-link {
                color: $dark-all-font-color;
              }

              .card-body {
                color: $dark-small-font-color;
              }

              .card-body {
                border: 1px solid $dark-card-border;
                border-top: none;
              }
            }
          }

          .border {
            border: 1px solid $dark-card-border !important;
          }

          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }

            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  &:last-child {
                    border-right: none;
                    border-left: 1px solid $dark-card-border;
                  }
                }
              }

              p {
                color: $dark-all-font-color;
              }

              .single-blog-content-top {
                border-top: 1px solid $dark-card-border;

                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }

          .comment-box {
            .media {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }

              img {
                border: 1px solid $dark-card-border;
              }
            }

            p {
              color: $dark-small-font-color;
            }

            .comment-social {
              li {
                color: $dark-small-font-color;

                &:first-child {
                  border-right: 1px solid $dark-card-border;
                }
              }
            }

            hr {
              border-top: 1px solid $dark-card-border;
            }
          }

          .table-hover {
            > tbody {
              > tr {
                &:hover {
                  --bs-table-accent-bg: #1d1e26;

                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }

          ul.the-icons {
            li {
              border: 1px dotted $dark-card-border;
              color: $sidebar-submenu-font-color;
              display: inline-block;
              padding: 10px;

              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }

              em {
                display: none;
              }
            }
          }

          .button-builder-wrap {
            .box {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
            }

            .button-preview {
              h2 {
                color: $dark-all-font-color;
              }
            }

            pre.well {
              background-color: $dark-card-background !important;
            }
          }

          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-card-border;
              }

              h6 {
                color: $dark-all-font-color;
              }
            }

            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }

          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }

          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }

            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }

                + div {
                  border-left: 1px solid $dark-card-inbox;
                }
              }
            }
          }

          .form-control {
            background-color: $dark-card-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-card-border;
          }
          .form-control.b-light {
            background-color: $dark-card-background;
          }

          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
              }
            }
          }

          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;

                a {
                  color: $dark-all-font-color;
                  background-color: $transparent-color;
                  border-color: $dark-card-background;

                  &:hover {
                    background-color: $dark-card-background;
                  }
                }

                .dropdown-header {
                  color: $dark-all-font-color;
                }

                .dropdown-divider {
                  border: 1px solid $dark-card-border;
                }
              }
            }
          }

          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;

                a {
                  color: $dark-all-font-color;

                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }

          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }

              span {
                color: $dark-small-font-color;
              }
            }
          }

          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;

              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }

          .browser-widget {
            .media-body {
              column-rule: 1px solid $dark-card-border;

              h4 {
                color: $dark-all-font-color;

                span {
                  color: $dark-all-font-color;
                }
              }

              span {
                color: $dark-small-font-color;
              }
            }
          }

          .email-wrap {
            .inbox {
              .media:hover {
                background-color: unset;
              }
              .media-body {
                h6 {
                  color: $primary-color;
                }
              }
            }
            .email-wrapper {
              .emailread-group {
                &:nth-child(n + 2) {
                  border-top: 1px solid $dark-card-border;
                }
              }
            }
            .email-app-sidebar {
              .media-body {
                h6 {
                  color: $primary-color;
                }
              }
              .media {
                img {
                  border: 2px solid $dark-body-background;
                }
              }

              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;

                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }

              ul {
                li {
                  a {
                    > .title {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .row {
                  .col-xl-4 {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                  }
                }

                .inbox {
                  .media.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }

            .media-body {
              h6 {
                small {
                  color: $dark-small-font-color;

                  span {
                    color: $dark-small-font-color;
                  }
                }
              }

              p {
                color: $dark-small-font-color;
              }
            }

            .email-top {
              border-bottom: 1px solid $dark-card-border;
            }

            p {
              color: $dark-small-font-color;
            }

            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-card-border;
                }
              }
            }
          }

          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-background;

            a {
              color: $dark-all-font-color;
              background: $transparent-color;
              border-top-color: $dark-card-background;

              &:hover {
                background-color: $dark-card-background;
              }
            }
          }

          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }

              &:hover {
                background-color: $dark-body-background;
              }
            }
          }

          .widget-joins {
            .media {
              .media-body {
                > span {
                  color: $dark-small-font-color;
                }
              }

              .details {
                border-left: 1px solid $dark-card-border;
              }
            }

            &:before,
            &:after {
              background-color: $dark-card-border;
            }
          }

          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }

          .social-widget-card {
            h6 {
              color: $dark-small-font-color;
            }

            .media {
              .social-font {
                background-color: $dark-body-background;
              }
            }
          }

          .b-b-light {
            border-bottom: 1px solid $dark-card-border !important;
          }

          .b-r-dark {
            border-right: 1px solid $white !important;
          }

          .testimonial {
            i {
              color: $dark-card-border;
            }

            p {
              color: $dark-all-font-color;
            }

            h5 {
              color: $dark-all-font-color;
            }

            span {
              color: $dark-small-font-color;
            }
          }

          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
            }
          }

          .grid-align {
            .row {
              background-color: $dark-card-border;
              border: 1px solid $dark-card-border;
            }
          }

          .page-builder {
            .ge-html-output {
              background-color: $dark-body-background;
              color: $dark-small-font-color;
              border: 1px solid $dark-card-border;
            }

            .btn-grid {
              background-color: $dark-card-background;
              color: $dark-small-font-color !important;
              border-right: 1px solid $dark-card-border;
            }

            .ge-mainControls {
              .ge-addRowGroup {
                .ge-row-icon {
                  .column {
                    border-left: 3px solid $dark-card-background;
                  }
                }
              }
            }

            .btn-code,
            .btn-screen {
              border-right: 1px solid $dark-card-border;
            }

            .ge-canvas.ge-editing {
              .ui-sortable {
                .ge-tools-drawer {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              .row {
                background-color: $dark-body-background;
              }

              .column {
                border: 1px solid $dark-card-border;
                background-color: $dark-card-background;
              }

              .ge-tools-drawer {
                .ge-details {
                  input {
                    background-color: $dark-body-background;
                    border-color: $dark-card-border;
                    color: $dark-small-font-color;
                  }

                  .btn-group {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }

            .ge-content-type-ckeditor {
              color: $dark-all-font-color;
            }
          }

          .search-page {
            .info-block + .info-block {
              border-top: 1px solid $dark-card-border;
            }
          }

          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }

          .timeline-small {
            .media {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-card-border;
                  }
                }
              }
            }
          }

          /* body end*/
        }

        .footer {
          p {
            color: $dark-all-font-color;
          }

          border-top: 1px solid $dark-card-border;
          background-color: $dark-card-background;
        }

        .custom-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }

        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-card-border;
        }
      }

      .note {
        textarea {
          color: $dark-all-font-color;
        }
      }

      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;

        h2 {
          background-color: $dark-card-background;
        }
      }

      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }

        .chat-menu {
          border-left: 1px solid $dark-card-border;

          .nav-tabs {
            border-bottom: 1px solid $dark-card-border;

            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }

              .avatar {
                img {
                  border: 5px solid $dark-card-border;
                }
              }
            }

            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }

            .follow {
              span {
                color: $dark-small-font-color;
              }

              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }

        .status {
          color: $dark-small-font-color;

          p {
            color: $dark-all-font-color !important;
          }
        }

        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-card-border;

              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }

              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }

            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-card-border;
              }

              .message {
                color: $dark-all-font-color;
              }

              .other-message {
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
              }
            }

            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
              border-radius: 0 0 15px 15px;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }

        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-card-border;

                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      .social-status {
        form {
          .form-group {
            .form-control-social {
              background-color: $dark-body-background;
              border: unset;
            }
          }
        }
      }
      .social-app-profile {
        .card {
          .socialprofile {
            span {
              .badge {
                background-color: $dark-body-background;
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-card-border;
        color: $dark-all-font-color;
      }

      .scorlled {
        background-color: $dark-body-background;
      }

      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-card-border;
      }

      .input-group-text {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        border-right: none;
        color: rgba(255, 255, 255, 0.6);

        i {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }
      }

      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }

      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        color: $dark-all-font-color;

        + .list-group-item {
          border-top-width: 0;
        }

        &.active {
          background-color: var(--theme-deafult) !important;
          border-color: var(--theme-deafult);
          color: $white;
        }

        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }

      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }

      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }

      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }

      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }

      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }

      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }

      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }

      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }

      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }

      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }

      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);

        .authentication-box {
          .card {
            background-color: $dark-card-background;

            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                }

                label {
                  color: $dark-all-font-color;
                }
              }

              .checkbox {
                label {
                  color: $dark-all-font-color;

                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
          }

          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }

      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);

        .authentication-box {
          h4,
          h3 {
            color: $white;
          }

          h6 {
            color: $dark-small-font-color;
          }

          .card {
            background-color: $dark-card-background;

            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                  color: $dark-all-font-color;
                }
              }

              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
          }
        }
      }

      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-card-border;
            }

            &:nth-child(3) {
              i {
                color: $dark-card-border;
              }
            }
          }
        }
      }

      .modal-content {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        .modal-header {
          border-bottom: 1px solid $dark-card-border;

          .close {
            color: $dark-small-font-color;
          }
        }

        .modal-footer {
          border-top: 1px solid $dark-card-border;
        }
      }

      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);

        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }

          .countdown {
            border-top: 1px solid $dark-card-border;
            border-bottom: 1px solid $dark-card-border;

            .title {
              color: $dark-all-font-color;
            }
          }

          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }

      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-card-border;

          &:before {
            background: $dark-card-border;
            color: $dark-all-font-color;
          }
        }
      }

      .authentication-main {
        background-color: $dark-card-border;

        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }

            .reset-password-link {
              color: $dark-small-font-color;
            }

            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }

                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-card-border;
                }
              }

              .opt-box {
                background-color: $dark-card-border;
              }
            }
          }

          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }

            h6 {
              color: $dark-small-font-color;
            }

            h3 {
              color: $dark-all-font-color;
            }

            .card {
              background-color: $dark-card-background;

              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                    color: $dark-all-font-color;
                  }
                }

                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-card-border;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .vertical-menu-main {
        background-color: $dark-card-background;
      }

      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }

        .list-unstyled {
          div {
            a {
              &:hover {
                color: var(--theme-deafult);
              }
            }
          }
        }
      }

      .default-according {
        .card {
          background-color: $dark-card-background;

          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-border;
            border-bottom: none;
            color: $white;
          }

          .text-muted {
            color: $dark-small-font-color !important;
          }
        }

        .bg-primary {
          .btn-link {
            background-color: var(--theme-deafult);
            border: 1px solid var(--theme-deafult);
          }
        }

        .bg-secondary {
          .btn-link {
            background-color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
          }
        }
      }

      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }

      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-card-border;
                }
              }
            }

            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }

        .caller-img {
          img {
            opacity: 0.7;
          }
        }

        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 1199px) {
        .mobile-title {
          &.d-none {
            border-bottom: 1px solid $dark-card-border;
          }
        }

        .chat-menu {
          border-top: 1px solid $dark-card-border;
          background-color: $dark-card-background;
        }

        .md-sidebar.job-sidebar {
          .md-sidebar-aside.job-left-aside {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
          }
        }

        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 575.98px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-card-border;
                border-bottom: 1px solid $dark-card-border;
              }
            }
          }
        }

        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-card-border;
            }
          }
        }
        .page-wrapper {
          &.compact-wrapper,
          &.compact-sidebar {
            .page-header {
              .header-wrapper {
                .toggle-sidebar {
                  border-right: 1px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }

    .lg-backdrop {
      background-color: $dark-body-background;
    }

    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }

    .drag {
      background-color: $dark-card-background;

      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-card-border;
        border: 1px dotted $dark-card-border;

        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }

        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }

    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-card-border;
      }

      p {
        color: $dark-small-font-color;
      }

      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-card-border;
      }

      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
          }
        }
      }
    }

    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border !important;
      }

      .select2-search--inline {
        .select2-search__field {
          color: $dark-all-font-color;
        }
      }

      .select2-selection--single {
        .select2-selection__rendered {
          color: $dark-all-font-color;
        }
      }

      .select2-search--dropdown {
        .select2-search__field {
          background-color: $dark-card-background;
          border: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }
      }

      .select2-results__option--highlighted[aria-selected] {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color !important;
      }
    }

    .select2-dropdown {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }

    .select2-drpdwn {
      .form-control-primary {
        border-color: var(--theme-deafult) !important;
        color: var(--theme-deafult) !important;
      }

      .form-control-secondary {
        border-color: var(--theme-secondary) !important;
        color: var(--theme-secondary) !important;
      }

      .form-control-success {
        border-color: $success-color !important;
        color: $success-color !important;
      }

      .form-control-info {
        border-color: $info-color !important;
        color: $info-color !important;
      }

      .form-control-warning {
        border-color: $warning-color !important;
        color: $warning-color !important;
      }

      .form-control-danger {
        border-color: $danger-color !important;
        color: $danger-color !important;
      }

      .form-control-inverse {
        border-color: $white !important;
        color: $white !important;
      }

      .form-control-primary-fill {
        background-color: var(--theme-deafult) !important;
        color: $white !important;
      }

      .form-control-secondary-fill {
        background-color: var(--theme-secondary) !important;
        color: $white !important;
      }

      .form-control-success-fill {
        background-color: $success-color !important;
        color: $white !important;
      }

      .form-control-info-fill {
        background-color: $info-color !important;
        color: $white !important;
      }

      .form-control-warning-fill {
        background-color: $warning-color !important;
        color: $white !important;
      }

      .form-control-danger-fill {
        background-color: $danger-color !important;
        color: $white !important;
      }

      .form-control-inverse-fill {
        background-color: $white !important;
        color: $black !important;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border;
        }

        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-card-border;
          background-color: $dark-body-background;
          color: $dark-all-font-color;

          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }

        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }

        textarea {
          border-color: $dark-card-border;
        }
      }

      .form-divider {
        border-top: 1px solid $dark-card-border;

        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }

    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-card-border;
      color: $dark-all-font-color;

      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }

    .editor-toolbar {
      border-top: 1px solid $dark-card-border;
      border-left: 1px solid $dark-card-border;
      border-right: 1px solid $dark-card-border;

      a {
        color: $dark-all-font-color !important;

        &:hover,
        &.active {
          background: $dark-card-border;
        }
      }

      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }

      &.fullscreen {
        &::before {
          background: linear-gradient(
            to right,
            $dark-body-background 0,
            rgba(0, 0, 0, 0) 100%
          );
        }

        &::after {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0,
            $dark-body-background 100%
          );
        }
      }
    }

    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-card-border;
        }
      }
    }

    .editor-preview {
      background-color: $dark-card-background;
    }

    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }

    .u-step {
      background: $dark-card-border;

      &.active,
      &.current {
        background: var(--theme-deafult);
        color: $white;
      }
    }

    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }

    .u-step-number {
      background-color: $dark-card-background;
    }

    .u-pearl {
      &:before {
        background-color: $dark-card-border;
      }
    }

    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }

    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-card-border;
        border: 2px solid $dark-card-border;
      }

      &:after {
        background-color: #334053;
      }
    }

    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }

    .note-editor.note-frame {
      border-color: $dark-card-border;

      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }

    .swal-modal {
      background-color: $dark-card-background;

      .swal-title {
        color: $dark-all-font-color;
      }

      .swal-text {
        color: $dark-small-font-color;
      }

      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }

    .nav-tabs {
      border-bottom: 1px solid $dark-card-border;

      .nav-link {
        color: $dark-all-font-color;

        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border $dark-card-border
            $dark-card-background;
        }

        &:hover,
        &:focus {
          border-color: $dark-card-border $dark-card-border $dark-card-inbox;
        }
      }

      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border $dark-card-border
            $dark-card-background;
        }
      }
    }

    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-card-border
              $dark-card-border;
          }
        }
      }
    }

    .border-tab.nav-left,
    .border-tab.nav-right {
      .nav-link {
        color: $dark-all-font-color;

        &.active {
          color: var(--theme-deafult);
        }
      }

      .show {
        > .nav-link {
          color: var(--theme-deafult);
        }
      }
    }

    .border-tab.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: var(--theme-secondary);
          color: var(--theme-secondary) !important;
        }
      }

      .show > .nav-link {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
      }

      .nav-item.show {
        color: var(--theme-secondary) !important;
        border-left-color: var(--theme-secondary);
      }
    }

    .border-tab.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }

      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }

      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }

    .border-tab.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }

      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }

      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }

    .border-tab.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: var(--theme-secondary) !important;
        }
      }

      .nav-item {
        &.show {
          color: var(--theme-secondary) !important;
        }
      }
    }

    .border-tab.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }

      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }

    .dropdown-divider {
      border-top: 1px solid $dark-card-border;
    }

    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-card-border;

      .icon-title {
        color: $dark-all-font-color;
      }

      span {
        color: $dark-small-font-color;
      }

      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-card-border;
        }
      }

      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }

    code {
      background-color: $dark-body-background;
      border-radius: 2px;
    }

    #cd-timeline {
      &::before {
        background: $dark-card-border;
      }
    }

    .timeliny {
      border-top: 1px solid $dark-card-border;
      border-bottom: 1px solid $dark-card-border;

      &::before {
        background-color: $white;
      }

      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;

        &::before {
          color: $dark-all-font-color;
        }
      }

      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }

              &::after {
                background-color: $dark-card-border;
                color: $dark-all-font-color;
                border: none;
              }
            }
          }

          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $dark-body-background;

                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }

          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }

    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-card-border;

              .task-label {
                color: $dark-all-font-color;
              }

              &:hover {
                h4 {
                  color: $white;
                }
              }

              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }

            &.completed {
              .task-container {
                .task-label {
                  color: var(--theme-deafult);
                }

                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }

        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .f1 {
      .f1-steps {
        .f1-step {
          .f1-step-icon {
            background-color: $dark-body-background !important;
          }
        }
        .f1-step.active {
          .f1-step-icon {
            background-color: $primary-color !important;
          }
        }
      }
    }
    .stepwizard {
      .stepwizard-row {
        .stepwizard-step {
          a {
            border: 1px solid $primary-color;
          }
          .btn-light {
            border: none;
            color: $dark-all-font-color;
            background-color: $dark-body-background !important;
          }
          .btn-primary {
            background-color: $primary-color !important;
          }
        }
      }
    }

    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }

        span {
          color: $dark-all-font-color;
        }
      }

      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }

        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }

          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }

        .tabs-scoial {
          border-bottom: none !important;
        }
      }

      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }

      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }

        p {
          color: $dark-small-font-color;
        }
      }
    }

    .introjs-helperLayer {
      background-color: rgba(54, 64, 74, 0.9);
      border: rgba(255, 255, 255, 0.5);
    }

    .jstree-default {
      .jstree-node,
      .jstree-icon {
        color: #455869 !important;
      }

      .jstree-anchor {
        color: $dark-small-font-color;
      }

      .jstree-clicked {
        color: $white;
        background-color: $transparent-color;
      }

      .jstree-hovered {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
      }
    }

    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }

      .tt-suggestion {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }

    .typeahead {
      span.twitter-typeahead {
        .tt-suggestion {
          border-top: 1px solid $dark-card-border;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }

    .code-box-copy {
      pre {
        background-color: $dark-body-background;

        code {
          background-color: $dark-body-background;
        }
      }

      pre[class*="language-"] {
        border: 1px solid $dark-card-border;
      }

      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
        color: $white;
      }

      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;

        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }

    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }

    .note {
      textarea {
        color: $dark-all-font-color;
      }
    }

    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;

      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-card-border;
      }
    }

    pre {
      background-color: $dark-body-background;
    }

    #keytable_wrapper,
    #column-selector_wrapper,
    #auto-fill_wrapper,
    #custom-button_wrapper,
    #class-button_wrapper,
    #keyboard-btn_wrapper,
    #multilevel-btn_wrapper,
    #pagelength-btn_wrapper,
    #basic-colreorder_wrapper,
    #state-saving_wrapper,
    #real-time_wrapper,
    #basic-fixed-header_wrapper,
    #fixed-header-footer_wrapper,
    #export-button_wrapper,
    #excel-cust-bolder_wrapper,
    #cust-json_wrapper,
    #basic-key-table_wrapper,
    #focus-cell_wrapper,
    #responsive_wrapper,
    #new-cons_wrapper,
    #show-hidden-row_wrapper,
    #basic-row-reorder_wrapper,
    #full-row_wrapper,
    #rest-column_wrapper {
      .dataTables_paginate {
        border: none;
      }
    }

    #example-style-8_wrapper {
      table.dataTable.stripe,
      table.dataTable.display {
        tbody {
          tr.odd {
            background-color: $dark-card-border;
          }
        }
      }
    }

    .error-wrapper {
      background-color: rgba(54, 64, 74, 0.8);

      .sub-content {
        color: $dark-all-font-color;
      }
    }

    .b-light {
      border: 1px solid $dark-card-border !important;
    }

    .modal-content {
      background-color: $dark-card-background;

      .modal-header {
        border-bottom: 1px solid $dark-card-border;

        .close {
          color: $dark-all-font-color;
          font-weight: 400;
        }
      }

      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-card-border;
        }
      }
    }

    .stepwizard {
      .stepwizard-row {
        &:before {
          background-color: $dark-card-border;
        }
      }
    }

    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
        color: $light-all-font-color;
      }
    }

    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }

    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }

    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }

    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-card-border !important;
          border-bottom: none !important;
        }
      }
    }

    @each $var in $alert-name {
      $i: index($alert-name, $var);

      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }

    .alert-theme {
      span {
        + span {
          + span {
            border-left: 5px solid var(--theme-deafult);
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }

      i {
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }

    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }

      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-card-border;
          }
        }

        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }

          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .product-table {
      table {
        thead {
          tr {
            th {
              color: $dark-all-font-color;
            }
          }
        }
        tbody {
          tr {
            &:hover {
              background-color: $dark-card-background !important;
            }
            &:nth-child(even) {
              background-color: $dark-body-background;
              td {
                &:first-child {
                  background-color: $dark-card-background;
                }
              }
            }
            &:nth-child(odd) {
              background-color: $dark-card-background;
              td {
                &:first-child {
                  background-color: rgba($primary-color, 0.1);
                }
              }
            }
          }
        }
      }
    }
    .order-history {
      .product-name {
        h6 {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      table {
        .qty-box {
          button {
            border: none !important;
          }

          .input-group {
            border-color: $dark-card-border;
          }
        }
        tbody {
          tr {
            &:hover {
              background-color: $dark-card-background !important;
            }
            &:nth-child(even) {
              background-color: $dark-body-background;
              td {
                &:first-child {
                  background-color: $dark-card-background;
                }
              }
            }
            &:nth-child(odd) {
              background-color: $dark-card-background;
              td {
                &:first-child {
                  background-color: rgba($primary-color, 0.1);
                }
              }
            }
          }
        }
        thead {
          tr {
            th {
              background-color: rgba($primary-color, 0.1);
              color: $primary-color !important;
            }
          }
        }

        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }

      .title-orders {
        background-color: $dark-body-background;
      }
    }

    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }

          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }

    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }

        span {
          color: $dark-small-font-color;
        }
      }

      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;

              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }

    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }

            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, var(--theme-deafult)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }

            &::after {
              background-color: $btn-color;
            }
          }

          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }

              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, var(--theme-deafult)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, var(--theme-deafult)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }

        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }

              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }

    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }
      }

      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }

          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }

          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }

        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }

    .btn-transparent {
      color: $dark-all-font-color;
    }

    #cal-basic {
      .fc-toolbar {
        .fc-left,
        .fc-right {
          .fc-next-button {
            .fc-icon-right-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }

          .fc-prev-button {
            .fc-icon-left-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
        }

        .fc-left {
          .fc-button-group {
            .fc-month-button {
              color: $white !important;
            }
          }
        }
      }
    }

    .fc-button-group {
      .fc-basicWeek-button,
      .fc-basicDay-button,
      .fc-agendaWeek-button,
      .fc-agendaDay-button {
        color: $black !important;
      }

      .fc-month-button {
        color: $white !important;
      }
    }

    #cal-agenda-view {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-month-button,
            .btn-light {
              color: $black !important;
            }

            .fc-agendaWeek-button {
              color: $white !important;

              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }

    .basic-calendar,
    #cal-bg-events,
    #cal-event-colors {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }

            .fc-month-button {
              color: $white !important;

              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }

    .categories {
      ul {
        li {
          &:hover {
            a {
              color: $primary-color;
            }
          }
          a {
            color: $dark-small-font-color;
          }
        }
      }
    }

    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }

      span {
        color: $dark-small-font-color;
      }
    }

    .social-status {
      .media {
        .media-body {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }

          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
    }

    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }

      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }

      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }

          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .socialprofile {
      .social-btngroup {
        .btn-light {
          color: $black !important;
        }
      }
    }
    .social-chat {
      .media-body {
        border: 1px solid $dark-card-border;

        &:after {
          border-right: 7px solid $dark-body-background;
        }
      }

      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }

    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }

    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }

    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }

    @media only screen and (max-width: 991.98px) {
      .page-wrapper.compact-wrapper
        .page-header
        .header-wrapper
        .toggle-sidebar {
        border-right: 1px solid $dark-card-border;
      }
    }

    @media only screen and (max-width: 767.98px) {
      .chart_data_left {
        .card-body {
          .chart-main {
            > div {
              &:first-child {
                border-right: 1px solid $dark-card-border;
              }

              &:nth-child(3) {
                border-right: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }

    div.dataTables_wrapper {
      div.dataTables_length {
        text-align: center;
      }
    }
    .apexcharts-tooltip.light {
      border-color: $dark-card-border;
      background-color: $dark-body-background;

      .apexcharts-tooltip-title {
        border-color: $dark-card-border;
      }

      .apexcharts-tooltip-text-label,
      .apexcharts-tooltip-text-value {
        color: $white;
      }
    }

    .note-editor {
      .note-toolbar-wrapper {
        .note-toolbar.card-header {
          .note-btn-group {
            button {
              background-color: $dark-body-background !important;
              border-color: $dark-card-border !important;
            }
          }
        }
      }
    }

    .blog-box {
      .blog-details-main {
        h6.blog-bottom-details {
          a {
            color: $dark-all-font-color;
          }
        }
      }

      h5 {
        a {
          color: $dark-all-font-color;
        }
      }
    }

    .blog-box {
      .blog-details-date {
        background-color: $dark-body-background;
      }
      .blog-details {
        .blog-social {
          li {
            &:first-child {
              border-color: $gray-60 !important;
            }
          }
        }
        .content-date {
          color: $dark-small-font-color;
        }
      }
    }
    .blog-box {
      .blog-details-main {
        .blog-social {
          li {
            &:first-child {
              border-color: $gray-60 !important;
            }

            + li {
              border-color: $gray-60 !important;
            }
          }
        }
      }
    }

    .product-box {
      .product-details {
        h4 {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }

    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              i {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }

    .product-box {
      .modal {
        .modal-header {
          .product-box {
            .product-details {
              .product-qnty {
                fieldset {
                  .input-group {
                    .btn-primary {
                      background-color: $dark-body-background !important;
                      border: 1px solid $dark-card-border !important;
                      color: #ddd;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .product-page-main {
      p {
        color: $dark-all-font-color;
      }
      .product-main {
        border-color: $dark-card-border;
      }
    }
  }

  .dark-sidebar {
    .toggle-sidebar {
      svg {
        stroke: var(--theme-deafult);
      }
    }
    .page-wrapper {
      .sidebar-main-title {
        p {
          color: $dark-all-font-color;
        }
      }

      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            background-color: $dark-card-background;
            .sidebar-main {
              .sidebar-links {
                > li.sidebar-list {
                  &:hover {
                    > a {
                      &:hover {
                        background-color: rgba($primary-color, 0.1);
                      }
                    }
                  }
                }
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }

                    svg {
                      path,
                      line {
                        stroke: $dark-all-font-color;
                      }
                    }
                  }
                }

                > li {
                  .sidebar-link {
                    &.active {
                      background-color: rgba($primary-color, 0.1);

                      svg {
                        color: var(--theme-deafult);
                        stroke: var(--theme-deafult);
                      }
                    }
                  }

                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                          }
                        }

                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                font-weight: 400;
                              }
                            }
                          }
                        }
                      }
                    }

                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }

                  .sidebar-submenu {
                    li {
                      a {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }

                .sidebar-list {
                  ul.sidebar-submenu {
                    li {
                      &:hover {
                        span {
                          color: $primary-color;
                        }
                      }
                      a {
                        span {
                          color: $dark-all-font-color;
                        }

                        &.active {
                          span {
                            color: var(--theme-deafult);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              .for-light {
                display: none;
              }
              .for-dark {
                display: block;
                height: 50px;
              }
            }
          }

          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            background: $dark-card-background;

            .sidebar-main {
              background-color: $dark-card-background;

              .sidebar-links {
                > li {
                  > a {
                    color: $dark-all-font-color;
                  }

                  .sidebar-submenu {
                    background: $dark-card-background;

                    li {
                      a {
                        color: $dark-all-font-color;

                        &.active,
                        &:hover {
                          color: var(--theme-deafult);
                        }
                      }

                      .nav-sub-childmenu {
                        background: $dark-card-background;
                      }
                    }
                  }

                  .mega-menu-container {
                    background: $dark-card-background;

                    .mega-box {
                      .link-section {
                        .submenu-content {
                          li {
                            a {
                              color: $dark-all-font-color;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .left-arrow,
              .right-arrow {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
          }
        }
        > .page-body-wrapper {
          .sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                > li {
                  > a {
                    &:hover,
                    &.active {
                      svg {
                        path,
                        line {
                          stroke: $primary-color;
                        }
                      }
                    }
                    svg {
                      path,
                      line {
                        stroke: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.compact-sidebar {
        .page-body-wrapper {
          div {
            &.sidebar-wrapper {
              > div {
                background: $dark-card-background;
              }

              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-title {
                      border-bottom: 1px solid $dark-card-border;
                    }

                    a {
                      span {
                        color: $dark-all-font-color;
                      }

                      svg {
                        stroke: $dark-all-font-color;
                      }
                    }

                    .sidebar-submenu,
                    .mega-menu-container {
                      background-color: $dark-card-background;

                      li {
                        a {
                          border-bottom: 1px solid $dark-card-border;
                        }
                      }
                    }
                  }

                  > li {
                    .sidebar-link {
                      &.active {
                        background-color: rgba($primary-color, 0.2);

                        span {
                          color: var(--theme-deafult);
                        }

                        svg {
                          color: var(--theme-deafult);
                          stroke: var(--theme-deafult);
                        }
                      }
                    }

                    .mega-menu-container {
                      .mega-box {
                        .link-section {
                          .submenu-title {
                            h5 {
                              color: $dark-all-font-color;
                            }
                          }

                          .submenu-content {
                            &.opensubmegamenu {
                              li {
                                a {
                                  color: $dark-all-font-color;
                                  font-weight: 400;
                                }
                              }
                            }
                          }
                        }
                      }

                      &::after {
                        background-color: $light-all-font-color;
                      }
                    }

                    .sidebar-submenu {
                      li {
                        a {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                  }

                  .sidebar-list {
                    ul.sidebar-submenu {
                      li {
                        a {
                          span {
                            color: $dark-all-font-color;
                          }

                          &.active {
                            color: var(--theme-deafult);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
  }
  .for-dark {
    display: none;
  }
}

// =======================  Dark Body only  ======================= //

/**=====================
55. theme layout CSS ends
==========================**/
