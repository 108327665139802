/**=====================
     50. Tree  CSS Start
==========================**/
.jstree-default{
	.jstree-clicked{
		background-color: lighten($primary-color , 42%);
		box-shadow: none;
		color: $dark-color;
	}
}
.vakata-context, .vakata-context ul{
	background-color: $white;
	border: none;
	box-shadow: 0 0 2px 2px $light-semi-gray;
	 li{
		 a{
			 .vakata-contextmenu-sep{
				 display: none;
			 }
			 &:hover{
				 background-color: lighten($primary-color , 45%);
				 box-shadow: None;
			 }
		}
	}
	.vakata-context-separator{
		display: none;
	}
}

.node .node-content-wrapper, .tree-children {
	position: relative;
 }
 
.node .node-content-wrapper::before, .tree-children::after {
	content: "";
	position: absolute;
 }
 
.node .node-content-wrapper::before {
	border-bottom: 1px solid lightgrey;
	border-left: 1px solid lightgrey;
	height: 28px;
	top: -17px;
	width: 20px;
	left: -28px;
 }
 
 .tree-node-level-1 > tree-node-wrapper > .node-wrapper > .node-content-wrapper::before {
	display: none;
 }
 
 .tree-node-leaf > .node-wrapper > .node .node-content-wrapper::before {
	width: 25px;
 }
 
 .tree-children::after {
	border-left: 1px solid lightgrey;
	height: 100%;
	top: -15px;
	left: -15px;
 }
 
 tree-node:last-child > .tree-node > .tree-children::after {
	border-left: none;
 }
 
 .toggle-children {
	z-index: 1;
 }



 
/**=====================
     50. Tree CSS Ends
==========================**/