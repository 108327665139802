/**=================================
nn. Style Customized bt Relead Team
=================================**/

.card-hover {
  margin-bottom: $card-margin-bottom;
  border: none;
  transition: all 0.1s ease;
  letter-spacing: 0.5px;
  border-radius: $card-border-radious;
  box-shadow: $card-box-shadow;

  &:hover {
    border: 1px solid $primary-color; /* Set the default border style for the card */
  }
}

.payment-selected {
  border: 2px solid $primary-color;
}
.grid-column {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
}
/* horizontal scroll */

.blocHorizontal {
  display: flex;
  flex-direction: row;
  //transform: rotate(90deg) translateY(-100vh);
  // transform-origin: top left;
  overflow-x: scroll;
  min-width: 1576px;
}
.main-scroll {
  // transform: rotate(-90deg) translateX(-100vh);
  // transform-origin: top left;
  overflow-x: hidden;
  overflow-y: auto;
  width: auto;

  /* pour ie et mozilla */
  -ms-overlow-style: none;
  scrollbar-width: none;
}
.main-scroll::-webkit-scrollbar {
  width: 0;
}
/*
// width 
::-webkit-scrollbar {
  width: 10px;
}

// Track 
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

//Handle 
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}*/

.nav-border-primary {
  &.nav-tabs.nav {
    border-bottom: 0;
  }
  .nav-link.active,
  .show > .nav-link {
    border: 1px solid #ff69b4;
    border-radius: 4px;
  }
}
.nav-border-hr {
  &.nav-tabs.nav {
    border-bottom: 0;
  }
  .nav-link.active,
  .show > .nav-link {
    border-bottom: 1px solid #ff69b4;
    border-radius: 0;
  }
}
.phone-preview {
  display: flex;
  justify-content: center;
  justify-items: center;
  height: 565px;
}
.media-space-between {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.img {
  display: block;
  margin: 20px auto;
}

.creation-form {
  border-radius: none;
  box-shadow: none;
  padding-top: 10px;
}

.img-info {
  width: 10px;
  height: 10px;
  // margin-top: 3px;
  margin-left: 5px;
}
.border-box {
  border: 1px solid #efeeff;
  padding: 15px;
}
.items-center {
  display: flex;
  margin: auto;
  justify-content: center;
}
.fill-card {
  background-color: #2d2e40;
}
.flow {
  filter: blur(0.5rem);
}
.button-right {
  margin-left: auto;
  display: block;
}
.box-container {
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 10px;
  padding: 7px;
}

.border-tab.nav-tabs.line {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  .nav-item {
    .nav-link {
      font-size: 16px;
      color: $dark-color;
      text-align: center;
      padding: 10px 25px;
      border: none;
      border-bottom: 2px solid $transparent-color;
      transition: 0.3s;
      &.active,
      &.show,
      &:focus,
      &:hover {
        color: var(--theme-deafult);
      }
      &.active,
      &.show,
      &:focus {
        border-bottom: 2px solid $primary-color;
        border-radius: 0;
        background: none;
      }
    }
  }
}
.underline-link {
  text-decoration: underline;
  cursor: pointer;
}

.border-tab.nav-tabs.newFields {
  margin-bottom: 30px;
  .nav-item {
    .nav-link {
      font-size: 16px;
      color: $dark-color;
      text-align: center;
      padding: 10px 25px;
      border: none;
      border-bottom: 2px solid $transparent-color;
      transition: 0.3s;
      &.active,
      &.show,
      &:focus,
      &:hover {
        color: var(--theme-deafult);
      }
      &.active,
      &.show,
      &:focus {
        border: 2px solid $primary-color;
      }
    }
  }
}

.form-select {
  font-size: 12px;
}
.margin-p {
  margin-bottom: 12px;
}
.form-control {
  font-size: 12px;
}
.font-a {
  font-size: 12px;
}
.cal-event-title {
  font-size: 12px;
}
.page-link:focus {
  color: var(--bs-pagination-active-color);
  border-color: #ff69b4;
  box-shadow: 0 0 0 0.15rem #ff69b4;
}
.color-darkGray {
  color: #89898b;
}
.border-active {
  border: 1px solid #ff69b4;
  padding: 0;
}

.muteButton {
  width: 90%;
  margin-top: 12px;
  margin-right: 15px;
  background-color: transparent;
  flex-shrink: 0;
  border: none;
  position: absolute;
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  padding: 0;
}
.design-mute-button {
  width: 25px;
  height: 25px;
  justify-content: center;
  background-color: rgba(219, 219, 219, 0.2);
  border-top-width: 0;
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-right: 2px;
}
.video-body {
  display: flex;
  justify-content: flex-end;
  position: sticky;
}
.loading-video {
  position: absolute;
  flex-grow: 1;
  min-height: 0;
  flex-direction: column;
  display: flex;
  z-index: 5002;
}
.color-success {
  color: $success-color;
}
.btn-outline-white {
  font-size: 12px;
  border-radius: 5px;
  border-color: white;
  color: white;
  background-color: transparent;
  &:hover {
    background-color: white !important;
    color: var(--theme-deafult);
  }
}
.div-center {
  display: flex;
  justify-content: center;
}
.center-white-content {
  background: $primary-color;
  color: #fff;
  height: 100%;
  position: relative;
  border-radius: 20px;
  text-align: center;
}
.custom-textarea {
  border: none;
  padding: 0;
  min-height: 38px;
  min-width: 380px;
  resize: none;
  overflow: hidden;
  text-align: center;
}
.custom-textarea-input-case {
  border: none;
  padding: 0;
  min-height: 25px;
  min-width: 250px;
  resize: none;
  overflow: hidden;
  text-align: left;
}

.stepper-ad {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  bottom: 0;
  padding: 20px 3rem;
  background-color: white;
}
.btn-outline-primary:disabled {
  border-color: $primary-color;
  color: $primary-color;
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  opacity: var(--bs-btn-disabled-opacity);
}

.dropdown-lang-options {
  padding: 10px !important;
  border-bottom: 1px solid #ecf3fa;
  transform: translateY(0) scale(1);
  transition: transform 0.3s ease;
}

.dropdown-lang {
  width: 160px;
  top: 30px;
  left: -110px;
  padding: 0 10px;
}

.greeting-auth {
  text-align: center;
  padding-top: 65px;
}
