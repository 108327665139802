/**=====================
    60. RTL CSS Start
==========================**/

html[dir="rtl"] {

  .show-hide {
    right: unset;
    left: 30px;
  }
  .footer{
    margin-left: unset;
    margin-right: 280px;
  }
  .fa.pull-right {
    margin-right: 0.3em;
    margin-left: unset;
  }
  .ProfileCard-realName{
    .realname{
      text-align: right;
    }
  }
  .chat-box{
    .chat-history{
      .caller-img{
        border-radius: 0 0 0 15px; 
      }
    }
  } 
  .file-content{
    .folder{
      .folder-box{
        .ellips{
          right: unset;
          left: 22px;
        }
      }
    }
  }   
  footer {
    &.blockquote-footer {
      margin-right: 0 !important;
    }
  }
  .ms-4 {
    margin-right: 1.5rem !important;
    margin-left: unset !important;
  }
  blockquote {
    border-left: 0;
    border-right: 4px solid $light-gray;
    &.text-center {
      border: 0;
    }
    &.text-end {
      border-left: 4px solid $light-gray;
      border-right: 0;
    }
  }
  app-layout-setting{
    ul{
      select{
        option{
          text-align: right;
        }
      }
    }
  }
  .files-content{
    li{
      margin-right: unset;
      margin-left: 20px;
    }
  }
  .quick-file{
    li{
      margin-right: unset;
      margin-left: 20px;
    }
  }
  .basic-cards{
    .custom-profile{
      .card-footer{
        > div{
          div{
            + div{
              border-left: unset;
              border-right: 1px solid $light-gray;
            }
          }
        }
      }
    }
  }
  .todo{
    .todo-list-wrapper{
      #todo-list{
        li{
          .task-container{
            .task-action-btn{
              .action-box{
                margin-left: unset;
                margin-right: 5px;
              }
            }
            .d-flex{
              div{
                text-align: left;
              }
            }
            .assign-name{
              padding: 8px 28px 0 0;
            }
          }
        }
      }
    }
  }
  .todo-wrap{
    &.email-wrap{
      .email-app-sidebar{
        .main-menu {
          > li{
            .badge-light{
              app-feather-icon{
                margin-right: unset;
                margin-left: 5px;
              }
            }
            .badge{
              padding: 5px 6px 3px 8px;
            }
          }
        }
      }
    }
  }
  .data-table{
    table{
      tbody{
        tr{
          td{
            span{
              i + i{
                border-right: 1px solid $gray-60;
                border-left: unset;
              }
            }
          }
        }
      }
    }
  }
  .data-table{
    table{
      thead{
        tr{
          th{
            &::before{
              float: left;
              direction: ltr;
            }
            &::after{
              float: left;
            }
          }
        }
      }
    }
  }
  .line-tabs{
    .card{
      .card-body{
        .d-flex{
           direction: ltr;
        }
      }
    }
  }
  .sticky-header-main {
    .card-header {
      h5 {
        float: none !important;
      }
    }
  }
  .cd-timeline-img{
    left: 50%;
  }
  .cripto-chat{
    .input-group{
      .form-control{
        border-radius: 0 !important;
      }
    }
  }  
  // carousal page
  .owl-rtl{
    div{
      direction: rtl;
    }
  }
  .br-movie{
    .br-text{
      text-align: right;
    }
  }
  .br-default{
    direction: ltr;
    .br-units{
      justify-content: flex-end;
    }
  }
  .br-horizontal{
    .br-text{
      margin-left: unset;
      margin-right: 20px;
    }
  } 
  .vertical-tab{
    .nav-tabs{
      margin-right: unset;
      margin-left: 2%;
    }
  }
  .border-tab{
    &.nav-left{
      &.nav-pills{
        direction: ltr;
      }
    }
  }
  .blog-box{
    .blog-details{
      .blog-social{
        li{
          &:nth-child(n+2){
            border-left: unset;
          }
        }
      }
    }
  }
  .blog-details-page{
    .blog-box.blog-shadow{
      .blog-details{
        .blog-social{
          li{
            &:nth-child(2){
              border-left: 1px solid $light-gray;
            }
            &:nth-child(n+3){
              border-left: unset;
            }
          }
        }
      }
    }
  }
  // wizard vertical
  aw-wizard-navigation-bar {
    &.horizontal.large-filled-symbols {
      ul.steps-indicator {
        li {
          &:not(:last-child):after {
            left: calc(50% + 25px) !important;
          }
        }
      }
    }
    &.horizontal.small {
      ul.steps-indicator {
        li {
          &:not(:last-child):after {
            left: calc(50% + 7px) !important;
          }
        }
      }
    }
    &.vertical.small {
      ul.steps-indicator {
        padding: 5px 19px 5px 5px;
        li {
          a {
            .label {
              margin-left: unset;
              margin-right: 15px;
            }
          }
          &:not(:last-child) {
            &::after {
              left: unset;
              right: -7px;
            }
          }
          .step-indicator {
            left: unset;
            right: -14px;
          }
        }
      }
    }
  }
  .rating-star-wrapper{
    i{
      &.m-r-5{
        margin-left: unset;
        margin-right: 5px;
      }
    }
  }
  .pill-input-group{
    input{
      border-radius: 0 !important;
    }
  }
  .input-group-square{
    .input-group-text, .form-control{
      border-radius: 0 !important;
    }
  }
  #right-history.show{
    right: unset;
    left: 0;
  }
  .ng-select.ng-select-multiple{
    .ng-select-container{
      .ng-value-container{
        .ng-value{
          .ng-value-icon.left{
            border-left: 1px solid $white;
            border-right: unset;
          }
        }
      }
    }
  }    
  .login-form{
    .form-group{
      .small-group{
        .input-group{
          &:nth-child(n+2){
            margin-right: 10px;
            margin-left: unset;
          }
        }
      }
    }
  }
  .search-form{
    input{
      padding: 10px 70px 10px 10px;
      border-radius: 30px 0 0 30px !important;
    }
  }
  .dropdown-basic{
    .dropdown{
      &.separated-btn{
        .dropdown-content{
          left: 0;
          right: unset;
        }
      }
    }
  }  
  .dismiss-text {
    .alert-dismissible {
      .btn-close {
        top: 12px !important;
        left: 20px;
        right: unset;
        span{
          padding: 2px 5px;
        }
      }
    }
  }
  .default-according{
    .card{
      .card-header{
        .btn-link{
          padding-left: 20px !important;
          padding-right: 22px !important;
        }
      }
    }
  }
  .social-tab{
    ul{
      li{
        &:nth-child(n+2){
          margin-right: 20px;
          margin-left: unset;
        }
      }
    }
  }
  .taskadd{
    table{
      tr{
        td{
          &:first-child{
            padding-right: 30px;
            padding-left: 12px;
          }
          &:last-child{
            padding-right: 12px;
            padding-left: 30px;
          }
        }
      }
    }
  }  
  .login-form{
    .form-group{
      input{
        border-radius: 6px 0 0 6px !important;
      }
    }
  }
  .job-search{
    .media{
      .media-body{
        ul.rating{
          li{
            + li{
              margin-right: 4px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .blog-single{
    .blog-details{
      .blog-social{
        li{
          + li{
            border-left: 1px solid $light-gray !important;
          }
          &:last-child{
            border-left: unset !important;
          }
        }
      }
    }
  }
  .product-filter{
    .color-selector{
      ul{
        li{
          + li{
            margin-left: unset;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .chat-box{
    .chat-menu{
      right: unset;
      left: 10px;
      .user-profile{
        .user-content{
          .social-list{
            li{
              &:nth-child(n+2){
                margin-left: unset;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  } 
  .blog-box{
    .blog-details-second{
      .detail-footer{
        ul.sociyal-list{
          li{
            &:nth-child(n+2){
              border-right: 1px solid $light-gray;
              border-left: unset;
            }
          }
        }
      }
    }
  }
  .order-box{
    .title-box{
      span{
        float: left;
        text-align: left;
      }
    }
  }
  .login-card{
    .login-form{
      .form-group{
        .btn.btn-block{
          margin-right: auto;
          margin-left: 0;
        }
      }
      p{
        a.ms-2{
          margin-left: 8px !important;
          margin-right: unset;
        }
      }
    }
  }
  .login-form {
    .form-group {
      .link {
        float: left;
      }
    }
    ul.login-social {
      li:nth-child(n+2) {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
  .product-wrapper-grid{
    .product-box {
      .rating{
        li{
          + li{
            margin-left: unset;
            margin-right: 3px;
          }
        }
      }
    }
  }
  .bookmark-tabcontent{
    .tab-content{
      .card{
        .d-flex{
          ul{
            li{
              + li{
                margin-right: 5px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }
  .pricing-block{
    .pricing-list{
      ul{
        li{
          h6{
            span{
              margin-right: 5px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .email-top{
    .dropdown-menu{
      .dropdown-item{
        text-align: right;
      }
    } 
  }
  .email-chek{
    input.checkbox_animated{
      margin: 0 0 0 16px;
    }
  }
  .email-wrap{
    .email-wrapper{
      .right-download{
        float: left;
      }
      h6{
        float: right;
      }
    }
    .actions{
      li{
        &:nth-child(n+2){
          margin-left: unset;
          margin-right: 15px;
        }
      }
    }
  }
  .ck.ck-toolbar{
    >.ck-toolbar__items{
      justify-content: flex-end;
    }
  }
  .preview-inner-container{
    img{
      + img{
        margin-left: unset;
        margin-right: 15px;
      }
    }
  }
  .product-color{
    li{
      + li{
        margin-right: 5px;
        margin-left: unset;
      }
    }
  }
  .modal-header,
  .modal-footer,
  .modal-content{
    .btn-close{
      right: unset;
      left: 25px;
    }
  }
  .email-wrap{
    .actions{
      text-align: left;
    }
    .email-body{
      .attachment{
        ul{
          li{
            &:nth-child(n+2){
              margin-right: 10px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .file-manager {
    .folder {
      li {
        &:nth-child(n + 2) {
          margin-left: unset;
          margin-right: 12px;
        }
      }
    }
  }
  .project-list{
    ul{
      li{
        svg{
          margin-right: unset;
          margin-left: 5px;
        }
      }
    }
  }
  .cripto-chat{
    .input-group{
      .input-group-text{
        &:first-child{
          border-radius: 0 6px 6px 0 !important;
        }
        &:last-child{
          border-radius: 6px 0 0 6px !important;
        }
      }
    }
  } 
  .custom-card{
    .card-social{
      li{
        + li{
          margin-left: unset;
          margin-right: 10px;
        }
      }
    }
  }
  .buy-sell-form{
    .dropdown{
      .dropdown-menu{
        li{
          text-align: right;
        }
      }
    }
  }
  .chat-box{
    .chat-history{
      .call-content{
        &.pe-0{
          padding-right: 12px !important;
        }
      }
    }
  }  
  .cripto-chat{
    .chat{
      .right-side-chat{
        .msg-box{
          img{
            margin-left: unset;
            margin-right: 6px;
          }
        }
      }
    }
  }
  .product-table{
    .dataTables_wrapper{
      button{
        + button{
          margin-left: unset;
          margin-right: 6px;
        }
      }
    }
  }
  .product-social{
    li{
      &:nth-child(n+2){
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
  .cripto-notification{
    .media{
      .media-body{
        .notification-chart-sec{
          #notification-chart{
            margin-right: unset;
            margin-left: 10px;
          }
        }
      }
    }
    table{
      tr{
        td{
          .media{
            .square-box{
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
      }
    }
  }    
  .buy-sell{
    .buy-sell-form{
      .form-group{
        .dropdown{
          .dropdown-menu{
            right: unset;
            left: 0;
          }
        }
      }
    }
  }    
  .slick-slider {
    direction: ltr;
  }
  .form-select {
    background-position: left 0.75rem center;
  }
  .slick-slide {
    float: left !important;
  }
  .profile-greeting{
    .cartoon-img{
      animation: unset;
    }
  } 
  .ongoing-project{
    table{
      thead{
        tr{
          th{
            &:last-child{
              text-align: left;
            }
          }
        }
      }
    }
  }
  .page-wrapper{
    .page-header{
      .header-wrapper{
        .nav-right{
          .onhover-show-div{
            li{
              .media{
                .notification-img{
                  border-radius: 35px 20px 20px 15px;
                }
                .media-body{
                  > span{
                    float: left;
                  }
                }
              }
            }
          }
          .cart-nav{
            .cart-dropdown{
              .close-circle{
                left: 0;
                right: unset;
              }
            }
          }
        }
      }
    }
  }      

  .badges-as-part-buttons {
    .card-body button {
      .badge {
        margin: 0 10px 0 0;
      }
    }
  }
  .pro-filter-sec {
    .product-sidebar {
      margin-left: 30px;
      margin-right: unset;
    }
  }
  .product-box {
    .product-img {
      .product-hover {
        ul {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }

  .product-filter.new-products {
    .owl-theme {
      .owl-item {
        .item {
          .product-box {
            .product-img {
              order: 1;
            }
          }
        }
      }
    }
  }
  caption {
    text-align: right;
  }
  .defaul-show-toast {
    .toast-header {
      .left {
        margin-left: auto;
        margin-right: unset !important;
      }
    }
  }

  ul {
    padding-right: 0;
  }
  .f-right {
    float: left;
  }
  .form-check-inline {
    margin-right: unset;
    margin-left: 1rem;
  }
  .color-box {
    button {
      + button {
        margin-left: unset;
        margin-right: 5px;
      }
    }
  }
  .modal{
    .modal-header{
      .product-box{
        .product-details{
          .product-size{
            ul{
              li{
                &:nth-child(n+2){
                  margin-left: unset;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .product-qnty{
    .qty-box1{
      .input-group{
        input{
          border-radius: 0 !important;
        }
      }
    }
  }
  .icofont-heart-alt:before{
    float: right;
  }
  .square-product-setting{
    .icon-grid{
      margin: 0 0 0 10px;
    }
  } 
  .search-page {
    .search-form {
      .form-group {
        .input-group-text {
          right: 0;
          left: unset;
        }
      }
    }
  }
  .knowledgebase-search {
    .form-inline {
      svg {
        left: unset;
        right: 20px;
      }
    }
  }
  .switch-showcase {
    .form-check {
      .form-check-input {
        float: left;
      }
    }
  }
  .pill-input-group span {
    &:last-child {
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
      border-top-left-radius: 1.3rem !important;
      border-bottom-left-radius: 1.3rem !important;
    }

    &:first-child {
      border-top-left-radius: unset !important;
      border-top-right-radius: unset !important;
      border-top-right-radius: 1.3rem !important;
      border-bottom-right-radius: 1.3rem !important;
    }
  }
  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          text-align: right;

          .toggle-sidebar {
            right: unset;
            left: 30px;
          }

          .sidebar-main .sidebar-links {
            padding: 0;

            > li .badge {
              left: 53px !important;
              right: unset !important;
            }
          }

          &.close_icon {
            margin-left: unset;
            transition: all 0.3s ease;
            margin-right: -330px;
            .logo-icon-wrapper {
              text-align: left;
              img {
                margin-left: 8px;
              }
            }
            ~ .page-body,
            ~ .footer {
              margin-left: 0;
              margin-right: 0;
              transition: 0.5s;
            }

            .sidebar-main {
              .sidebar-links {
                > li {
                  a {
                    svg {
                      margin-left: 0;
                    }
                  }
                }
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~ .sidebar-submenu {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }

            .according-menu {
              display: none;
            }

            &:hover {
              .sidebar-main {
                .sidebar-links {
                  > li {
                    a {
                      svg {
                        margin-left: 13px;
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            transform: translate(0px);

            .sidebar-main {
              .sidebar-links {
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~ .sidebar-submenu {
                        display: block !important;
                      }
                    }
                  }
                }

                > li {
                  a {
                    svg {
                      float: none;
                    }
                  }
                }
              }
            }

            .according-menu {
              display: block;
            }
          }

          .sidebar-main {
            .sidebar-links {
              > li {
                .sidebar-submenu {
                  &:after {
                    right: 30px;
                    left: unset;
                  }

                  > li {
                    a {
                      padding-right: 45px !important;
                      padding-left: unset !important;

                      &:after {
                        right: 26px;
                        left: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .page-body {
          margin-left: unset;
          margin-right: 280px;
        }

        .sidebar-wrapper {
          &.close_icon{
            ~ .footer{
              margin-right: 0;
              margin-left: unset;
            }
          }
          .sidebar-main {
            .sidebar-links {
              > li {
                a {
                  svg {
                    margin-right: unset;
                    margin-left: 15px;
                  }
                }
              }
            }
          }
        }

        .according-menu {
          right: unset;
          left: 20px;
        }
      }
    }
    &.only-body {
      .page-header {
        .header-wrapper {
          .nav-right {
            right: unset;
            left: 30px;
          }
        }
      }
    }
  }
  .btn.btn-primary {
    z-index: 0;
  }
  // task page css
  .date-details {
    > div {
      + div {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
  // contact page css
  .contact-editform {
    &.ps-0 {
      padding-left: 20px !important;
    }
  }
  .contact-options {
    li {
      .btn-category {
        padding-left: unset !important;
        padding-right: 40px;
      }
    }
  }
  .list-persons {
    .profile-mail {
      .email-general {
        ul {
          padding-right: unset;
          padding-left: 20px;
          li {
            > span {
              float: left;
            }
          }
        }
      }
      .media {
        .media-body {
          ul {
            li {
              + li {
                padding-left: unset;
                padding-right: 10px;
                margin-left: unset;
                margin-right: 5px;
                border-left: unset;
                border-right: 1px solid $light-color;
              }
            }
          }
        }
      }
    }
  }
  // file manager css
  .file-content {
    .btn{
      svg{
        margin-right: unset;
        margin-left: 2px;
      }
    }
    .form-inline {
      i {
        padding-right: unset;
        padding-left: 10px;
      }
    }
  }
  .file-sidebar {
    .pricing-plan {
      .bg-img {
        top: 40px;
        left: -60px;
        right: unset;
        transform: rotate(45deg);
      }
    }
    .btn {
      svg {
        margin-right: unset;
        margin-left: 8px;
      }
    }
  }


  // projects page css
  .form-select {
    background-position: left 0.75rem center;
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  }
  .customers {
    ul {
      li {
        + li {
          margin-left: unset;
          margin-right: -10%;
        }
      }
    }
  }
  .project-list {
    .project-box {
      .customers {
        .d-inline-block {
          float: unset !important;
        }
      }
    }
    .btn {
      float: left;
    }
    .border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 5px 0 5px 30px;
          }
        }
      }
    }
  }
  .alert-copy.notify-alert{
    left: 20px;
    right: unset !important;
    .close{
      left: 10px;
      right: unset !important;
    }
  }
  .project-box {
    .badge {
      right: unset;
      left: 10px;
    }
  }
  .timeliny{
    .timeliny-dot::after{
      right: 1.25rem;
      left: unset;
    }
    .timeliny-vertical-line{
      right: 16%;
    }
  } 
  // kanban board css
  .kanban-board {
    float: right;
  }
  .kanban-item {
    .kanban-box {
      .customers {
        ul {
          li {
            + li {
              margin-left: unset !important;
              margin-right: -10px !important;
            }
          }
        }
      }
    }
    .list {
      li {
        i {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }
  }
  
  .icon-box{
    .onhover-show-div{
      right: unset;
      left: 0;
    }
  } 

  //dashboard default
.default-dash{
  .profile-greeting{
    .greeting-user{
      .btn{
        i{
          margin-right: 10px;
          margin-left: unset;
          transform: scale(-1);
        }
      }
    }
    .cartoon-img{
      left: 0;
      right: unset;
      transform: scaleX(-1);
    }
  }
  .news-update{
    .table{
      tbody{
        tr{
          td{
            &:first-child{
              padding-left: 12px;
              padding-right: 0;
            }
            &:last-child{
              padding-right: 12px;
              padding-left: 0;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .ongoing-project{
    table{
      tbody{
        tr{
          td{
            &:first-child{
              padding-left: 12px;
              padding-right: 0;
            }
            &:last-child{
              padding-right: 12px;
              padding-left: 0;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .icon-box{
    .onhover-show-div{
      right: unset;
      left: 0;
    }
  } 
  .premium-access{
    transform: scaleX(-1);
    .card-body{
      transform: scaleX(-1);
    }
  }
  .yearly-chart{
    .card-header{
      h5,h6{
        text-align: left;
      }
    }
  }
  .total-transactions{
    .report-sec{
      border-right: 1px solid rgba($dark, 0.1);
      border-left: unset;
      .report-main{
        border-left: 1px solid rgba($dark, 0.1);
        border-right: unset;
      }
    }
  }
}

  // ecommerce dashboard 2
  .ecommerce-dash{
    .sale-main{
      .sale-right{
        transform: scaleX(-1);
      }
    }
    .icon-box{
      .onhover-show-div{
        right: unset;
        left: 0;
      }
    }
    .get-support{
      .support-sec-img {
        left: -15px;
        bottom: 0;
        right: unset;
        transform: scaleX(-1);
      }
    }
    .our-activities{
      table{
        tr{
          td{
            &:first-child{
              padding-right: 0;
              padding-left: 12px;
            }
            &:last-child{
              padding-left: 0;
              padding-right: 12px;
              text-align: left;
            }
            .icon-wrappar{
              margin-right: 0;
              margin-left: 15px;
            }
          }
        }
      }
    }
    .ongoing-project{
      .media-body{
        padding-right: 8px;
        padding-left: unset !important;
      }
      table{
        tbody{
          tr{
            td{
              &:first-child{
                padding-left: 12px;
                padding-right: 0;
              }
              &:last-child{
                padding-left: 0;
                padding-right: 12px;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .hot-selling{
      .table{
        tbody{
          tr{
            td{
              &:first-child{
                padding-left: 12px;
                padding-right: 0;
              }
              &:last-child{
                padding-left: 0;
                padding-right: 12px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  // crypto dashboard 
  .crypto-dash{
    .buy-sell{
      .buy-sell-form{
        .form-group{
          .dropdown{
            .dropdown-toggle{
              margin-right: auto;
              margin-left: unset;
            }
          }
        }
      }
    }    
    .icon-box{
      .onhover-show-div{
        right: unset;
        left: 0;
      }
    }
    .cripto-chat{
      .input-group{
        .input-group-text{
          svg{
            transform: scaleX(-1);
          }
        }
      }
      .chat{
        .left-side-chat{
          .img-msg-box{
            img{
              margin-left: unset;
              margin-right: 10px;
            }
          }
          .msg-box{
            margin-left: unset;
            margin-right: 15px;
            border-radius: 8px 0 8px 8px;
          }
        }
        .right-side-chat{
          .msg-box{
            float: left;
            margin-right: unset;
            margin-left: 15px;
            border-radius: 0 8px 8px 8px;
          }
        }
      }
    }
    .cripto-notification{
      table{
        tr{
          td{
            &:first-child{
              padding-left: 12px;
              padding-right: 0;
            }
            &:last-child{
              padding-left: unset;
              text-align: left;
              padding-right: 12px;
            }
            .media-body{
              h5{
                span{
                  margin-left: 16px !important;
                  margin-right: unset !important;
                }
              }
            }
          }
        }
      }
    }
    .trading-activities{
      table{
        tbody{
          tr{
            td{
              &:first-child{
                padding-left: 12px;
                padding-right: 0;
              }
              &:last-child{
                padding-right: 12px;
                padding-left: 0;
                text-align: left;
              }
              .media{
                svg{
                  margin-left: 5px;
                  margin-right: 0;
                }
                .table-box-img{
                  margin-left: 15px;
                  margin-right: 0;
                  svg{
                    margin-left: 0;
                    margin-right: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
    .crypto-chart{
      .card-header{
        .media{
          .media-body{
            h5{
              margin-right: 0;
              margin-left: 10px;
            }
            .coin-logo-img{
              margin-left: 10px;
              margin-right: 0;
              
            }
          }
        }
      }
    }    
  }

   

  //horizontal-sidebar
  .page-wrapper {
    &.horizontal-wrapper {
      .header-logo-wrapper {
        text-align: right;
      }
      .page-body-wrapper {
        .page-body {
          margin-right: 0;
        }
        .footer {
          margin-right: 0;
        }
      }
    }
    &.material-type {
      .page-body-wrapper {
        .page-body {
          margin-right: auto;
        }
      }
    }
  }
  
  .page-header {
    .header-wrapper {
      .nav-right {
        > ul {
          > li {
            &:first-child {
              margin-right: 0;
            }
          }
        }
        &.right-header {
          ul {
            li {
              .profile-media {
                .media-body {
                  margin-right: 15px;
                  margin-left: unset;
                }
              }
            }
          }
        }
      }
      .toggle-sidebar {
        margin-left: 24px;
        margin-right: unset;
      }
    }
  }
  .datepickers-container {
    .-bottom-left- {
      .datepicker--pointer {
        right: 10px;
        left: unset;
      }
    }
  }
  .clockpicker-align-left.popover > .arrow {
    right: 25px;
    left: unset;
  }
  .popover.left > .arrow {
    left: -22px;
    right: unset;
    transform: scale(-1);
  }
  .clockpicker-popover.clockpicker-align-left {
    left: 1380px !important;
  }
  .clockpicker-popover.clockpicker-align-top {
    left: 810px !important;
  }
  .clockpicker-popover.clockpicker-align-right {
    left: 550px !important;
  }
  // compact icon css
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .notification-dropdown {
            right: unset !important;
          }
        }
        .left-side-header {
          .input-group {
            .input-group-text{
              border-radius: 0 30px 30px 0;
            }
            span {
              border-left: none;
              border-right: 1px solid #d8e0ff;
            }
            input {
              border: 1px solid #d8e0ff;
              border-right: none;
              &.form-control{
                border-radius: 30px 0 0 30px !important;
              }
            }
          }
        }
      }
    }
  }
  .apexcharts-canvas {
    direction: ltr;
  }
  .job-search{
    .media{
      .media-body{
        .job-apply-btn{
          left: 30px;
          right: unset;
        }
        ul.rating{
          margin-right: 10px;
          margin-left: unset;
        }
      }
    }
  }

  // bookmark app css
  .email-wrap {
    .btn-mail {
      text-align: right;
    }
    .email-app-sidebar {
      ul {
        li {
          a {
            padding-left: unset;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .bookmark-tabcontent {
    .details-bookmark.list-bookmark {
      .bookmark-card.card {
        .details-website {
          .hover-block {
            text-align: left;
            width: 95%;
          }
        }
        .title-bookmark {
          h6 {
            padding-left: 15px;
            padding-right: unset;
            &::before {
              position: absolute;
              content: "-";
              left: 0;
              color: #898989;
            }
          }
        }
      }
    }
    .details-bookmark {
      .bookmark-card {
        &.card {
          .favourite-icon {
            text-align: center;
            left: 15px;
            right: unset;
          }
          .title-bookmark {
            text-align: right;
          }
        }
      }
    }
  }
  .bookmark-wrap {
    .details-bookmark {
      .row {
        > div {
          + div {
            padding-left: 15px !important;
          }
        }
      }
    }
  }

  .starter-main{
    blockquote{
      border-right: 4px solid $light-gray !important;
      border-left: unset;
    }
  }
  // index page css start

  .chart_data_left,
  .chart_data_right {
    .card-body {
      .chart-main {
        .media {
          border-right: unset;
          border-left: 1px solid $light-gray;
          .media-body {
            .right-chart-content {
              margin-left: unset;
              margin-right: 30px;
            }
          }
          &.border-none {
            border-left: none;
          }
        }
      }
    }
  }
  .chart_data_right {
    .card-body {
      .media {
        .right-chart-content {
          h4 {
            .new-box {
              margin-left: unset;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .appointment-table {
    table {
      tr {
        td {
          .status-circle {
            left: unset;
            right: 35px;
          }
        }
      }
    }
  }
  .notification {
    .card {
      .media {
        .media-body {
          h6 {
            .dot-notification {
              float: left;
            }
          }
          p {
            .badge {
              margin-left: unset;
              margin-right: 10px;
            }
          }
          .inner-img {
            &:first-child {
              margin-right: unset;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  // index page css end
  // ecommerce dashboard css start
  .dash-chart {
    .media {
      .media-body {
        p {
          .font-roboto {
            float: right;
          }
          .font-montserrat{
            float: right;
          }
        }
      }
    }
  }
  @each $progress-gradient-name,
    $progress-gradient-color in (primary, var(--theme-deafult)),
    (secondary, $secondary-color), (success, $success-color),
    (danger, $danger-color), (info, $info-color), (light, $light-color),
    (dark, $dark-color), (warning, $warning-color)
  {
    .progress-gradient-#{$progress-gradient-name} {
      background-image: linear-gradient(
        to right,
        $progress-gradient-color,
        $transparent-color
      );
      &::after {
        right: unset;
        left: -1px;
      }
      .animate-circle {
        right: unset;
        left: -8px;
      }
    }
  }
  .progress-animate {
    span {
      left: -10px;
      right: unset;
    }
  }
  .our-product,
  .best-seller-table {
    table {
      tr {
        td {
          &:first-child {
            padding-left: 20px;
            padding-right: 0;
          }
          &:last-child {
            padding-right: 20px;
            padding-left: 0;
            text-align: left;
          }
        }
      }
    }
  }
  .best-seller-table {
    table {
      tbody {
        tr {
          td {
            .align-middle {
              .status-circle {
                left: unset;
                right: 32px;
              }
            }
            .fa-check-circle {
              left: unset;
              right: 30px;
            }
          }
        }
      }
    }
  }
  .activity-timeline {
    .media {
      .media-body {
        margin-left: unset;
        margin-right: 20px;
      }
      .activity-line {
        left: unset;
        right: 46px;
      }
    }
  }
  .card-body {
    .alert {
      app-feather-icon{
        & ~ p {
          padding-left: unset;
          padding-right: 20px;
        }
      }
    }
  }
  .offer-box {
    .offer-slider {
      .carousel-control-prev,
      .carousel-control-next {
        right: unset;
        left: 0;
      }
    }
  }
  .total-users {
    .goal-status {
      .progress {
        background-image: linear-gradient(
          to right,
          $transparent-color 15%,
          rgba($info-color, 0.1) 25%,
          $transparent-color
        );
      }
      .progress-gradient-primary {
        background-image: linear-gradient(
          to right,
          var(--theme-deafult) 25%,
          rgba($info-color, 0.1),
          $transparent-color
        );
      }
    }
  }
  // ecommerce dashboard css end
  .cal-date-widget {
    .cal-info {
      .pe-3 {
        padding-right: 1rem !important;
      }
    }
  }
  .me-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  .pe-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important;
  }
  .me-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  .navs-dropdown {
    .onhover-show-div {
      right: 0;
    }
  }
  .default-according {
    &.style-1 {
      button {
        text-align: right;
        &:before {
          left: 20px;
          right: unset;
        }
        i {
          right: 18px;
          left: unset;
        }
      }
    }
    .card {
      .card-header {
        i {
          right: 18px;
          left: unset;
        }
      }
    }
  }
  .ProfileCard-details {
    padding-right: 30px;
    padding-left: unset;
    float: right;
  }
  .starter-main {
    .card-body {
      ul {
        padding-right: 30px;
        padding-left: unset;
      }
    }
  }
  .theme-tab {
    .tab-title {
      li {
        a {
          svg {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .spent {
    .spent-graph {
      .project-budget {
        margin-left: 30px;
        margin-right: unset;
      }
    }
  }
  .widget-joins {
    .row {
      .pe-0 {
        padding-right: 15px !important;
      }
      .ps-0 {
        padding-left: 15px !important;
      }
    }
  }
  .location-checkbox{
    span{
      padding-right: 33px;
      padding-left: unset;
    }
  }
  .cke_contents{
    direction: rtl;
  }
  .CodeMirror-sizer{
    border-right: unset !important;
  }
  .order-box {
    .sub-total,
    .total,
    .qty {
      .shipping-class,
      li {
        .shopping-checkout-option,
        span {
          float: left;
          text-align: left;
        }
      }
      li {
        .count {
          float: left;
        }
      }
    }
  }
  .img-paypal {
    margin-right: 15px;
    margin-left: unset;
  }
  .cart {
    .qty-box {
      .input-group {
        .btn {
          border-radius: 0 !important;
        }
      }
    }
  }
  .product-qnty {
    fieldset {
      .input-group {
        .input-group-prepend {
          .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
  .grid-options {
    margin-right: 10px;
    margin-left: unset;
    ul {
      li {
        a {
          .line-grid-1 {
            right: 12px;
            left: unset;
          }
          .line-grid-2 {
            right: 18px;
            left: unset;
          }
          .line-grid-3 {
            right: 36px;
            left: unset;
          }
          .line-grid-4 {
            right: 42px;
            left: unset;
          }
          .line-grid-5 {
            right: 48px;
            left: unset;
          }
          .line-grid-6 {
            right: 66px;
            left: unset;
          }
          .line-grid-7 {
            right: 72px;
            left: unset;
          }
          .line-grid-8 {
            right: 78px;
            left: unset;
          }
          .line-grid-9 {
            right: 84px;
            left: unset;
          }
          .line-grid-10 {
            right: 103px;
            left: unset;
          }
          .line-grid-11 {
            right: 109px;
            left: unset;
          }
          .line-grid-12 {
            right: 115px;
            left: unset;
          }
          .line-grid-13 {
            right: 121px;
            left: unset;
          }
          .line-grid-14 {
            right: 127px;
            left: unset;
          }
          .line-grid-15 {
            right: 133px;
            left: unset;
          }
        }
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left: unset;
        }
      }
    }
  }

  .product-filter {
    &.new-products {
      .owl-theme {
        .owl-nav {
          left: 0;
          right: unset;
        }
        .owl-item {
          .item {
            .product-box {
              .product-details {
                justify-content: flex-end;
                &.text-start {
                  text-align: right !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .dataTables_wrapper{
    .dataTables_length{
      float: right;
    }
    .dataTables_filter{
      float: left;
    }
  }
  .categories{
    ul{
      li{
        padding: 5px 30px 5px 60px;
        .badge{
          left: 30px;
          right: unset;
        }
      }
    }
  }

  .page-wrapper{
    .page-header{
      .header-wrapper{
        .nav-right{
          .onhover-show-div{
            .dropdown-title{
              a{
                svg{
                  right: 0;
                  left: unset;
                  transform: rotate(45deg);
                }
              }
            }
          }
        }
      }
    }
  }
  .browse{
    .browse-articles{
      ul{
        li{
          &:last-child{
            a{
              svg{
                transform: scale(-1);
              }
            }
          }
          h5{
            padding-right: 20px;
            padding-left: unset;
            svg{
              right: 0;
              left: unset;
              margin-left: 10px;
              margin-right: unset;
            }
          }
        }
      }
      h4{
        padding-right: 25px;
        padding-left: unset;
        span{
          svg{
            right: 0;
            left: unset;
          }
        }
      }
    }
  }
  .product-sidebar {
    .filter-section {
      .card {
        .card-header {
          h6 {
            .pull-right {
              i {
                left: auto;
                right: unset;
              }
            }
          }
        }
      }
    }
  }
  .img-cropper{
    .docs-options{
      .dropdown-menu{
        transform: translate(0px, -35.3298px) !important;
        .form-check-label{
          float: right;
        }
      }
    }
    .img-preview{
      float: right;
    }
  } 
  .prooduct-details-box {
    .btn {
      right: unset;
      left: 5px;
    }
    .close {
      right: unset;
      left: 10px;
    }
  }
  .b-l-primary {
    &.border-3{
      border-width: 3px !important;
    }
  }
  .b-r-secondary {
    border-left: 1px solid #ffc500 !important;
    border-right: unset !important;
  }
  .b-l-warning {
    border-right: 1px solid #ff8819 !important;
    border-left: unset !important;
  }
  .b-l-danger {
    border-right: 1px solid #e52727 !important;
    border-left: unset !important;
  }
  .b-l-secondary {
    border-right:solid #ffc500 !important;
    border-left: unset !important;
  }

  .collection-filter-block {
    svg {
      margin-right: unset;
      margin-left: 10px;
    }
  }
  .features-faq{
    .rating{
      li{
        + li{
          margin-left: unset;
          margin-right: 3px;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          left: 30px;
          right: unset;
        }
      }
    }
  }
  .icon-hover-bottom{
    .icon-popup{
      .close-icon{
        left: 10px;
        right: unset;
      }
    }
  }
  .flag-icons, .feather-icons{
    div{
      .media{
        .media-body{
          h5{
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-details {
          text-align: right;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-title {
        .breadcrumb {
          margin-left: unset;
        }
      }
    }
  }
  .bookmark {
    ul {
      li {
        + li {
          margin-left: unset;
          margin-right: 10px;
        }
        .search-form {
          .form-control-search {
            left: -50px;
            right: unset;
            &:before {
              right: 50px;
              left: unset;
            }
            &:after {
              right: 20px;
              left: unset;
            }
          }
        }
      }
    }
  }
  .me-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  .footer {
    .pull-right {
      float: none;
    }
  }
  .checkbox_animated,
  .radio_animated {
    margin: 0 8px 0 16px;
  }
  .button.remove {
    left: -10px;
    right: unset;
  }
  .code-box-copy__btn {
    left: 11px;
    right: unset;
  }
  ul {
    &.nav-menus {
      border-bottom: none;
    }
    &.notification-dropdown {
      &.onhover-show-div {
        li {
          text-align: right;
        }
      }
    }
  }
  .badge {
    + .badge {
      margin-right: 5px;
      margin-left: unset;
    }
  }
  .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  .m-l-10 {
    margin-right: 10px !important;
    margin-left: unset !important;
  }
  .m-l-15 {
    margin-right: 15px !important;
    margin-left: unset !important;
  }
  .m-r-20 {
    margin-left: 20px;
    margin-right: unset;
  }
  .m-r-30 {
    margin-left: 30px;
    margin-right: unset;
  }
  .m-r-5 {
    margin-left: 5px;
    margin-right: unset;
  }
  .text-start {
    text-align: right !important;
  }
  .m-r-10 {
    margin-left: 10px;
    margin-right: unset;
  }
  .pe-3 {
    padding-left: 1rem !important;
    padding-right: unset !important;
  }
  .p-r-0 {
    padding-left: 0;
    padding-right: 15px;
  }
  .m-l-20 {
    margin-right: 20px !important;
    margin-left: unset !important;
  }
  .ps-3 {
    padding-right: 1rem !important;
  }
  .b-r-light {
    border-left: 1px solid $light-color !important;
    border-right: unset !important;
  }
  .float-end {
    float: left !important;
  }
  .float-start {
    float: right !important;
  }
  .text-end {
    text-align: left !important;
  }
  .border-right {
    border-left: 1px solid #dee2e6 !important;
    border-right: unset !important;
  }
  .pe-0 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  .ps-0 {
    padding-right: 0 !important;
    padding-left: unset !important;
  }
  .ps-2 {
    padding-right: 0.5rem !important;
    padding-left: unset !important;
  }
  .pe-2 {
    padding-left: 0.5rem !important;
    padding-right: unset !important;
  }
  .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }
  .owl-carousel {
    direction: ltr;
  }
  .btn-group {
    > {
      .btn:not(:last-child):not(.dropdown-toggle),
      :not(:last-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
      .btn:not(:first-child) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .btn:nth-child(2) {
        border-radius: 0 !important;
      }
    }

    .btn-group > :not(:first-child) > .btn {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn + {
      .btn,
      .btn-group {
        margin-right: -1px;
        border-right: none;
      }
    }
    .btn-group + {
      .btn,
      .btn-group {
        margin-right: -1px;
      }
    }
    .btn-group-vertical {
      .btn + {
        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
      .btn-group + {
        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
    }
  }
  .me-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }
  .me-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  .ms-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  .m-r-15 {
    margin-left: 15px;
    margin-right: unset;
  }
  .b-l-light {
    border-right: 1px solid $light-color !important;
    border-left: unset !important;
  }
  .p-l-0 {
    padding-left: unset;
    padding-right: 0px;
  }
  .ps-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important;
  }
  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }
  .alert {
    text-align: right;
  }
  .pull-right {
    float: left;
  }
  .form-inline {
    .form-group {
      margin-left: 15px;
      margin-right: 0;
    }
  }
  .card {
    text-align: right;
    .card-header {
      h5:not(.mb-0) {
        float: right;
      }
      span {
        clear: both;
      }
      .card-header-right {
        left: 35px;
        right: unset;
        .card-option {
          text-align: left;
          &.list-unstyled {
            padding-right: 0;
            padding-left: unset;
          }
        }
      }
      .pull-left {
        float: right;
      }
    }
    &.full-card {
      left: 0;
      right: unset;
    }
    .card-body {
      .alert {
        svg {
          margin-left: 4px;
          margin-right: unset;
        }
      }
      .value-left {
        margin-left: 30px;
        margin-right: unset;
      }
      .square {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
  .modal-footer {
    > :not(:first-child) {
      margin-right: 0.5rem;
      margin-left: 0;
    }
  }
  .form-inline {
    .form-group {
      .col-form-label {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
  .breadcrumb-item {
    & + .breadcrumb-item {
      padding-right: 0.5rem;
      padding-left: unset;
      &::before {
        padding-left: 0.5rem;
        padding-right: unset;
        float: right;
      }
    }
  }
  code[class*="language-"],
  pre[class*="language-"] {
    text-align: right;
  }
  .input-group-prepend {
    margin-left: -1px;
    margin-right: unset;
  }
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: unset;
    margin-right: -1px;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid,
  .was-validated .form-control:valid,
  .form-control.is-valid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px;
  }
  .input-group > .form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .border-tab.nav-left .nav-link {
    text-align: left;
  }
  // social app page css
  .social-status {
    form {
      .form-group {
        .form-control-social {
          padding-right: 15px;
          padding-left: unset;
        }
      }
    }
    .media {
      .social-status {
        right: 35px;
        left: unset;
      }
    }
  }
  .social-chat {
    .media-body {
      span.f-w-600 {
        display: flex;
        i.fa-reply {
          transform: scale(-1);
        }
      }
    }
  }
  .social-tab {
    ul {
      li {
        a {
          svg {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .was-validated {
    .form-select:invalid {
      background-position: left 0.75rem center, center left 1.75rem !important;
    }
  }
  .social-chat {
    .other-msg {
      margin-right: 40px;
      margin-left: unset;
    }
    .media-body {
      &:before {
        left: 100%;
        right: unset;
        border-left: 7px solid var(-semi-dark);
        border-right: unset;
      }
      &:after {
        left: 100%;
        right: unset;
        border-left: 7px solid $white;
        border-right: unset;
      }
    }
  }
  .timeline-content {
    .comment-number {
      i {
        margin-left: 20px;
        margin-right: unset;
      }
    }
  }
  .photos {
    ul {
      li {
        margin-right: unset;
        margin-left: 10px;
      }
    }
  }
  .avatar-showcase {
    .friend-pic {
      margin-left: 8px;
      margin-right: unset;
    }
    .pepole-knows {
      ul {
        li {
          margin-left: 21px;
          margin-right: unset;
          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .user-profile {
    .profile-post {
      .post-body {
        .post-comment {
          li {
            &:nth-child(n + 2) {
              margin-right: 15px;
              margin-left: unset;
            }
            label {
              a {
                span {
                  margin-right: 10px;
                  margin-left: unset;
                }
              }
            }
          }
        }
        .post-react {
          h6{
            margin-right: 10px;
            margin-left: unset;
          }
          ul {
            li {
              &:nth-child(n + 2) {
                margin-right: -20px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
    .post-about {
      ul {
        li {
          .icon {
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
    }
    .follow {
      ul.follow-list {
        li:nth-child(n + 1) {
          margin-left: 15px;
          padding-left: 15px;
          border-left: 1px solid #ecf3fa;
          &:last-child {
            margin-left: unset;
            margin-right: unset;
            border-left: unset;
          }
        }
      }
    }
    .social-media {
      ul.user-list-social {
        li:nth-child(n + 2) {
          margin-right: 5px !important;
          margin-left: unset;
        }
      }
    }
    .hovercard {
      .user-image {
        .share-icons {
          left: 100px;
          right: unset;
          li {
            margin-left: 10px;
            margin-right: unset;
            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  // faq page css
  .faq-wrap{
    .default-according{
      .card{
        .card-header{
          .btn-link{
            padding-right: 48px !important;
          }
        }
      }
    }
  }
  .faq-form {
    .search-icon {
      left: 12px;
      right: unset;
    }
  }
  .faq-widgets {
    .media-body {
      p {
        padding-left: 20px;
        padding-right: unset;
      }
    }
  }
  .modal{
    .theme-close{
      left: 0;
      right: unset;
    }
  }
  .swal-footer{
    clear: both;
    display: flex;
    justify-content: flex-end;
  }
  .header-faq,
  .faq-title {
    text-align: right;
  }
  .faq-accordion.job-accordion{
    button{
      &::before{
        left: 10px !important;
      }
    }
    .card-header{
      .btn-link{
        text-align: right;
      }
      h5{
        float: right;
      }
    }
  }
  .faq-accordion {
    .faq-header {
      svg {
        left: 30px;
        right: unset;
      }
    }
    .card {
      .btn-link {
        svg {
          margin-left: 10px;
          margin-right: unset;
          right: 16px;
          left: unset;
        }
      }
    }
  }
  .navigation-option {
    ul {
      li {
        padding: 15px 0 15px 15px;
        a {
          padding-right: 40px;
          padding-left: unset;
          svg {
            right: 15px;
            top: 17px;
            left: unset;
          }
        }
      }
    }
  }
  .default-according{
    .card{
      .card-header{
        .btn-link{
          text-align: right;
        }
      }
    }
  }   
  .updates-faq {
    margin-right: unset;
    margin-left: 30px;
  }
  // knowledgebase page css
  .browse {
    .browse-articles {
      span {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }
  // learning app page css
  .categories {
    .learning-header {
      padding-right: 30px;
      padding-left: unset;
    }
  }
  // internationalization page css
  .main {
    .langChoice {
      left: 30px;
      right: unset;
    }
  }
  .pricing-block {
    svg {
      direction: ltr;
    }
  }
  // chart peity css
  p {
    &.data-attributes {
      svg {
        margin-left: 10px;
        margin-right: unset;
      }
    }
  }
  // avatars page css
  .avatars {
    .avatar {
      margin-left: 10px;
      margin-right: unset;
      &:last-child {
        margin-left: 0;
      }
      .status {
        left: 4px;
        right: unset;
      }
    }
  }
  .customers {
    &.avatar-group {
      margin-left: 30px;
      margin-right: unset;
    }
  }
  .card {
    .card-header {
      h5 {
        float: none !important;
      }
    }
  }
  // knob chart css
  .knob-chart {
    .chart-clock-main {
      .clock-small {
        right: 10px;
        left: unset;
      }
    }
  }
  // general widgets css
  .general-widget{
    .employee-status{
      .table{
        tbody{
          tr{
            td{
              &:first-child{
                padding-right: 0;
                padding-left: 12px;
              }
              &:last-child{
                padding-right: 12px;
                padding-left: 0;
                text-align: left;
              }
              .media{
                img{
                  margin-left: 15px;
                  margin-right: unset;
                }
              }
            }
          }
        }
      }
    }
    .social-widget-card{
      .card-footer{
        .row{
          .col:nth-child(n+2){
            border-right: 1px solid $light-gray;
            border-left: unset;
          }
        }
      }
    }   
    .custom-profile{
      .card-footer>div{
        div+div{
          border-right: 1px solid $light-gray;
          border-left: unset;
        }
      }
    }  
    .testimonial{
      text-align: center;
    }
    .widget-feedback{
      ul{
        li{
          &:nth-child(n+2){
            border-right: 1px solid $light-gray !important;
            border-left: unset;
          }
        }
      }
    }
    .icon-box{
      .onhover-show-div{
        right: unset;
        left: 0;
        text-align: right;
      }
    } 
    .card-header{
      .media{
        .media-body{
          h5{
            text-align: right;
          }
        }
      }
    }
    .card-body{
      .widget-card{
        .media-body{
          text-align: left;
          h5{
            direction: ltr;
          }
        }
      }
    }
  }

  .widget-joins {
    .widget-card {
      h6 {
        margin-left: unset;
        margin-right: 15px;
      }
      .icon-bg {
        right: unset;
        left: 20px;
      }
    }
  }
  .weather-widget-two {
    .widget-list {
      .media {
        .media-body {
          margin-left: unset;
          margin-right: 30px;
        }
      }
    }
  }
  .activity-media {
    .media {
      .media-body {
        margin-left: unset;
        margin-right: 20px;
      }
    }
  }
  .custom-profile {
    .card-social {
      li {
        + li {
          margin-left: unset;
          margin-right: 20px;
        }
      }
    }
  }
  .social-widget-card {
    .media {
      .media-body {
        margin-left: unset;
        margin-right: 20px;
      }
    }
  }
  .mobile-clock-widget {
    .bg-svg {
      left: unset;
      right: -25px;
    }
  }
  // to-do css
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        .btn-label {
          margin-left: 5px;
          margin-right: unset;
        }
        left: 30px;
        right: unset;
      }
      #todo-list {
        li {
          .task-container {
            .task-action-btn {
              text-align: left;
            }
          }
        }
      }
    }
    .notification-popup {
      left: 10px;
      right: unset;
    }
  }
  .todo-options {
    .badges-todo {
      h6 {
        margin-left: 10px;
        margin-right: unset;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
  .todo-list-btns {
    .dropdown-basic {
      .btn-group {
        .form-group {
          .checkbox {
            padding-right: 14px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            padding-left: unset;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }
      }
      .separated-btn {
        margin-right: -6px;
        margin-left: unset;
        .btn {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }
    }
  }
  .search-todo {
    .dropdown-basic {
      .btn-group {
        margin-left: 18px;
        margin-right: unset;
      }
    }
  }
  //sticky note css
  .sticky-note {
    .note {
      float: right;
    }
  }
  // mega-menu css
  .mega-menu {
    .list-unstyled {
      div {
        a {
          padding: 8px 0 8px 35px;
          &:hover {
            padding: 8px 10px 8px 35px;
          }
        }
      }
    }
  }
  #testimonial {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .slide--item {
            .media {
              .me-3 {
                margin-right: 1rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
      }
    }
  }
  .needs-validation,
  .form-row {
    .input-group > .form-control:not(:last-child),
    .input-group > .custom-select:not(:last-child) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .form-check {
    padding-right: 1.25rem;
    padding-left: unset;
    .form-check-input {
      float: right;
      margin-left: unset;
      margin-right: -1.5em;
    }
  }
  .mega-menu {
    .default-according {
      .card {
        .btn-link {
          text-align: right;
        }
      }
      &.style-1 {
        button[aria-expanded="true"],
        button[aria-expanded="false"] {
          &:before {
            right: unset;
            left: 20px;
          }
        }
      }
    }
  }
  // email css
  .email-wrap {
    .row {
      .col-xl-6 {
        padding-right: 0;
        padding-left: 15px;
      }
      .col-xl-3 {
        + .col-xl-3 {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }
    .email-app-sidebar {
      .main-menu {
        & > li {
          text-align: right;
          a {
            i {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
      ul {
        padding-right: 0;
      }
    }
    .email-content {
      .email-top {
        .user-emailid {
          &:after {
            float: left;
          }
        }
      }
    }

    .email-right-aside {
      .email-body {
        .inbox {
          svg {
            margin-left: 10px;
            margin-right: unset;
          }
          p {
            padding-right: 15px;
          }
          .media {
            .media-body {
              position: relative;
              right: 0;
              span {
                left: 20px;
                right: unset;
              }
            }
          }
        }
        .pe-0 {
          padding-right: unset !important;
        }
      }
    }
  }
  // calender css
  #renderRange {
    float: left;
    padding-left: unset;
    padding-right: 12px;
  }
  .event-calendar {
    direction: ltr;
  }
  .calendar-wrap {
    .fc {
      direction: rtl;
      text-align: right;

      .fc-toolbar {
        .fc-left {
          float: right;
        }
        .fc-right {
          float: left;
        }
        & > * {
          & > * {
            margin-left: 0;
          }
        }
      }
    }
    .fc-events-container {
      text-align: right;
    }
    .fc-scroller {
      margin-right: unset;
      margin-left: -17px;
    }
  }
  .fc-agenda-view {
    .fc-day-grid {
      .fc-row {
        margin-right: 0 !important;
      }
    }
  }
  .fc-unthemed {
    .fc-row {
      margin-right: 0 !important;
    }
  }
  // buttons css
  .btn-group-showcase {
    .btn-group {
      margin-left: 20px;
      margin-right: unset;
    }
  }
  .btn-showcase {
    .btn {
      margin-right: unset;
      margin-left: 18px;
    }
  }
  .btn-square {
    &.dropdown-toggle {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  // button-builder css
  &.button-builder {
    ul.the-icons {
      li {
        float: right;
      }
    }
    .color-slelector {
      float: right;
    }
    .custom-button-color {
      .form-control {
        border-radius: 0 5px 5px 0;
        &.pull-left {
          float: right;
        }
      }
    }
    .hint-label {
      float: right;
      padding: 4px 020px 0 0;
    }
  }
  #customer-review {
    .owl-item {
      img {
        order: 2;
      }
    }
  }
  .options {
    > div {
      margin: 0 0 8px 8px;
    }
  }
  // modal page
  .modal-dialog {
    .modal-content {
      .modal-body {
        p {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
  // forms page
  .drag-box {
    fieldset {
      .component {
        .form-group {
          .text-lg-left {
            text-align: right !important;
          }
          .input-group {
            .input-group-prepend {
              .btn,
              .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }
              .btn-right,
              .checkbox-radius {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
            #prependedcheckbox {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
            #appendedcheckbox {
              border-right-color: $light-semi-gray;
            }
            #buttondropdown {
              margin-right: unset;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }
  .drag {
    form {
      text-align: right;
      input {
        text-align: right;
      }
      label {
        text-align: right !important;
      }
      .form-group {
        .input-group {
          .input-group-prepend {
            .btn,
            .input-group-text {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
            }
            .btn-right,
            .checkbox-radius {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }
  .draggable {
    label {
      text-align: right;
    }
  }
  .form-builder-column {
    .tab-content {
      .tab-pane {
        .theme-form {
          .ui-draggable {
            .input-group {
              > .input-group-prepend {
                > .input-group-text {
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  border-top-right-radius: 0.25rem;
                  border-bottom-right-radius: 0.25rem;
                }
              }
            }
          }
        }
      }
    }
    #pills-tabContent {
      .theme-form {
        .form-group,
        .ui-draggable {
          .input-group {
            #buttondropdown {
              margin-right: unset;
              margin-left: 15px;
            }
            .input-group-btn {
              button {
                border-radius: 4px !important;
              }
            }
          }
        }
      }
    }
  }
  .form-horizontal {
    &.theme-form {
      fieldset {
        .tab-content {
          .tab-pane {
            .component {
              .form-group {
                .text-lg-left {
                  text-align: right !important;
                }
                .input-group {
                  .input-group-prepend {
                    .btn,
                    .input-group-text {
                      border-top-left-radius: 0;
                      border-bottom-left-radius: 0;
                      border-top-right-radius: 0.25rem;
                      border-bottom-right-radius: 0.25rem;
                    }
                    .btn-right,
                    .checkbox-radius {
                      border-top-left-radius: 0.25rem;
                      border-bottom-left-radius: 0.25rem;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    }
                  }
                  #prependedcheckbox {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                  }
                  #appendedcheckbox {
                    border-right-color: $light-semi-gray;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .form-builder {
    .component {
      .form-group {
        .input-group {
          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
        .ps-md-radios {
          padding-right: 37px;
          padding-left: unset;
        }
      }
    }
  }

  // search page
  .search-page {
    .nav i {
      margin-left: 10px;
      margin-right: unset;
    }
  }
  .product-stts {
    display: inline-block;
    .ms-1 {
      margin-right: 0.25rem !important;
      margin-left: unset !important;
    }
  }
  .search-form input {
    text-align: right;
  }
  .needs-validation {
    select.form-select {
      option {
        color: $black;
      }
    }
  }
  // icons page
  .icon-hover-bottom {
    .icon-title {
      text-align: right;
    }
    .icon-popup {
      .icon-last {
        .form-inline {
          .form-group {
            .btn {
              margin-right: 15px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .icon-lists {
    div {
      i {
        margin: 0 10px 0 15px;
      }
    }
    ul {
      padding-right: 0;
    }
  }
  ul {
    &.icon-lists {
      padding-right: 30px;
    }
  }
  // customizer
  .floated-customizer-btn {
    &.third-floated-btn {
      left: 35px;
      right: unset;
    }
    & > span {
      margin-right: 10px;
    }
  }
  .floated-customizer-panel {
    text-align: right;
    left: -85px;
    right: unset;
    &.active {
      left: 35px;
      right: unset;
    }
    .close-customizer-btn {
      left: 30px;
      right: unset;
    }
    ul {
      padding-right: 0;
    }
  }
  .radio {
    label {
      padding-left: 0;
      padding-right: 15px;
      &::before {
        right: 0;
        margin-right: -20px;
        left: unset;
      }
    }
  }
  // sidebar main page
  .page-wrapper {
    .page-body-wrapper {
      .page-title {
        .row {
          h3 {
            text-align: right;
            padding-right: unset !important;
          }
          h6 {
            text-align: right;
          }
        }
      }
    }
    &.compact-wrapper {
      .page-header {
        margin-right: 280px;
        margin-left: unset;
        width: calc(100% - 280px);
        &.close_icon {
          margin-right: 0;
          margin-left: unset;
          width: 100%;
        }
      }
    }
  }
  .page-builder{
    .ge-canvas.ge-editing{
      .row>.ge-tools-drawer{
        text-align: left;
      }
    }
    .dropdown{
      .dropdown-toggle{
        &::after{
          right: 3px;
        }
      }
    }
    .float-start {
      float: left !important;
    }
  }
  // page main header

  .page-header {
    .header-wrapper {
      .nav-right {
        text-align: right;
        .onhover-show-div {
          right: unset;
          left: 0;
        }
        .chat-dropdown {
          li {
            .media {
              .status-circle {
                left: unset;
                right: 0px;
              }
            }
          }
        }
        .cart-dropdown {
          .close-circle {
            right: unset;
            left: 0;
          }
        }
        > ul {
          > li {
            margin-right: unset;
            margin-left: 5px;
            .lang-txt {
              margin-left: 0;
              margin-right: 8px;
            }
            &:before {
              right: 0;
              left: unset;
            }
            .dot {
              left: 17px;
              right: unset;
            }
            &.onhover-dropdown {
              &:hover {
                .onhover-show-div {
                  &:before {
                    right: 10px;
                    left: unset;
                  }
                  &:after {
                    right: 10px;
                    left: unset;
                  }
                }
              }
              &:last-child {
                padding-left: 0;
                padding-right: 20px;
                border-right: none;
              }
            }
            &:last-child {
              padding-right: 20px;
              padding-left: 0;
            }
            text-align: right;
            i {
              &.ms-2 {
                margin-right: 0.5rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
        .profile-dropdown {
          li {
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
      .mobile-sidebar {
        padding-left: 20px;
        padding-right: unset;
      }
    }
    &.open {
      margin-right: 0;
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        right: initial;
        left: -30px;
        padding: 0;
        &:before {
          right: inherit !important;
          left: 35px !important;
        }
        &:after {
          right: inherit !important;
          left: 35px !important;
        }
        li {
          margin-right: 0 !important;
          span {
            &.badge {
              margin-left: unset !important;
            }
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }
          .notification-icon {
            margin-left: 20px;
            margin-right: unset;
          }
        }
      }
    }
  }
  // alert page
  .card-body {
    .alert {
      svg {
        ~ p {
          padding-left: unset;
          padding-right: 20px;
        }
      }
    }
  }
  .alert {
    i {
      margin-left: 5px;
      margin-right: 0;
    }
  }
  .inverse {
    padding: 13px 65px 13px 20px;
    i {
      left: unset;
      right: 0;
    }
  }
  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      &:before {
        border-right: 7px solid nth($alert-color, $i);
        right: 54px;
        left: unset;
        border-left: unset;
      }
    }
  }
  .alert-dismissible {
    .btn-close {
      left: 0;
      right: unset;
      span {
        padding-left: 0;
        padding-right: unset;
      }
    }
  }
  .icofont {
    &.icofont-truck {
      display: inline-block;
    }
  }
  
  // blog page

  .blog-box {
    .blog-details-second{
      .detail-footer{
        ul.sociyal-list{
          li{
            &:nth-child(n+2){
              margin-left: unset;
              margin-right: 15px;
              padding-right: 15px;
              padding-left: unset;
            }
            i{
              margin-left: 5px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .blog-details {
      padding-left: 20px;
      text-align: right;
      .blog-social {
        padding-right: 0;
        li {
          padding-left: 30px;
          padding-right: unset;
          &:first-child {
            border-left: 1px solid $light-gray;
            border-right: unset;
            padding-left: 30px;
            padding-right: unset;
          }
          & + li {
            margin-right: 30px;
            margin-left: unset;
            padding-left: 30px;
          }
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          border-left: 1px dotted;
          border-right: unset;
          &:last-child {
            border-left: none;
          }
        }
      }
    }
    .blog-date {
      span {
        font-size: 36px;
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }
  .comment-box {
    .media {
      img {
        margin-left: 45px;
        margin-right: unset;
      }
      h6 {
        text-align: right;
      }
    }
    ul {
      ul {
        margin-right: 100px;
        margin-left: unset;
        padding-right: 0;
      }
    }
    .comment-social {
      text-align: left;
      li {
        padding-left: 0;
        padding-right: 20px;
        &:first-child {
          border-left: 1px solid $light-semi-gray;
          border-right: unset;
          padding-right: 0;
          padding-left: 20px;
        }
        &:nth-child(n+2){
          border-left: unset;
        }
      }
    }
  }
  // button builder page
  .button-builder-wrap {
    .form-group {
      .btn {
        margin-left: 15px;
        margin-right: 0;
        padding: 9px;
      }
    }
  }
  .button-builder {
    ul {
      &.the-icons {
        padding-right: 0 !important;
      }
    }
  }
  // chat page
  .call-chat-body {
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-history {
            .caller-img {
              &.ps-0 {
                padding-left: 15px !important;
              }
            }
          }
        }
      }
    }
  }
  .chat-box {
    .user-image {
      float: right;
      margin-left: 5px;
      margin-right: 0;
    }
    .people-list {
      .search {
        i {
          left: 10px;
          right: unset;
        }
      }
    }
    .about {
      float: right;
      padding-right: 10px;
      padding-left: unset;
      text-align: right;
    }
    .chat-menu {
      border-right: 1px solid $light-color;
      border-left: unset;
      .nav {
        padding-right: 0;
      }
      &.ps-0 {
        padding-left: 15px !important;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-message {
          .smiley-box {
            margin-left: 0.5rem;
            margin-right: unset;
          }
          .text-box {
            .btn {
              border-radius: 5px 0 0 5px;
            }
          }
        }
      }
      &.pe-0 {
        padding-right: 15px !important;
      }
    }
    .chat {
      .chat-message {
        .text-box {
          .input-group-append {
            margin-right: -2px;
          }
        }
      }
    }
  }
  .chat-msg-box {
    ul {
      padding-right: 0;
    }
  }
  .chat-left-aside {
    .status-circle {
      left: unset;
      right: 40px;
    }
  }
  .chat-right-aside {
    .chat {
      .chat-header {
        .chat-menu-icons {
          padding-left: 0;
          padding-right: unset;
        }
        img {
          float: right;
        }
      }
    }
  }
  // cke editor page
  .cke_toolbar,
  .cke_toolgroup,
  a.cke_button,
  .cke_combo_text {
    float: right;
  }
  .cke_reset_all,
  .cke_reset_all *,
  .cke_reset_all a,
  .cke_reset_all textarea {
    text-align: right;
  }
  .cke_combo_text {
    padding-left: unset;
    padding-right: 10px;
  }
  #editor1 {
    #cke_editor1 {
      #cke_1_contents {
        iframe {
          html {
            direction: rtl !important;
          }
        }
      }
    }
  }
  // coming soon page
  #clockdiv {
    ul {
      padding-right: 0;
    }
  }
  // social app page
  .custom-card {
    .card-footer {
      & > div {
        & + div {
          border-right: 1px solid $light-semi-gray;
          border-left: unset !important;
        }
      }
    }
  }
  .card-social {
    padding-right: 0;
  }
  .card-absolute {
    .card-header {
      right: 15px;
      left: unset;
    }
  }
  .dt-ext {
    .dataTables_wrapper {
      .dataTables_length {
        float: right;
      }
      .dataTables_filter {
        float: left;
      }
    }
  }
  // datatables page
  .dataTables_wrapper {
    .dataTables_filter {
      input[type="search"] {
        margin-right: 10px;
        margin-left: 0;
      }
    }
    .dataTables_filter {
      margin-left: unset;
      margin-right: 15px;
    }
    .dataTables_info {
      float: right;
    }
    .advance-5_filter {
      margin-left: 0;
    }
    .btn-group {
      button {
        margin-left: 5px;
        margin-right: unset;
        &:last-child {
          margin-left: 0;
        }
      }
    }
    table {
      &.dataTable {
        tbody {
          td,
          th {
            &.select-checkbox {
              &:after {
                margin-left: 31px;
              }
            }
          }
        }
      }
    }
  }
  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_paginate {
          margin-right: 15px !important;
          margin-left: 0 !important;
          float: left;
        }
      }
    }
  }
  table {
    &.dataTable {
      thead {
        & > tr {
          & > th {
            padding-left: 30px;
            padding-right: 12px;
            &:before {
              left: 1em !important;
              right: unset;
            }
            &:after {
              left: 0.5em !important;
              right: unset;
            }
          }
        }
      }
    }
  }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    left: 1em !important;
    right: unset;
  }
  .dataTables_scrollHeadInner {
    padding-right: unset !important;
    padding-left: 0 !important;
  }
  div {
    &.table-responsive {
      & > div {
        &.dataTables_wrapper {
          & > div {
            &.row {
              & > div[class^="col-"]:first-child {
                padding-right: 0;
                padding-left: 0;
              }
              & > div[class^="col-"]:last-child {
                padding-right: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .dt-plugin-buttons {
    button {
      &.m-r-10 {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
  // datetime picker page
  .datetime-picker {
    .theme-form {
      .form-group {
        label {
          text-align: left !important;
        }
        .input-group-text {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .input-group {
          .form-control {
            border-radius: 0.25rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
  // dropdown page
  .dropdown-basic {
    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
    .separated-btn {
      .btn {
        border-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .btn-group {
      margin-right: unset;
      margin-left: 18px;
    }
  }
  // error page
  .error-wrapper {
    .col-md-8 {
      &.offset-md-2 {
        margin: 0 auto !important;
      }
    }
  }
  // product page
  .product-price {
    del {
      padding-left: 20px;
      padding-right: 0;
    }
  }
  .product-box {
    .modal {
      .modal-header {
        .btn-close {
          right: unset;
          left: 15px;
        }
      }
    }
    
    .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
    .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
      border-left-color: #007bff;
    }
    .ribbon-bookmark.ribbon-vertical-left:before,
    .ribbon-bookmark.ribbon-vertical-right:before {
      right: 0;
      left: unset;
      border-left: 15px solid #2a3142;
    }
    .ribbon-bookmark.ribbon-info:before {
      border-left-color: transparent;
    }
    .ribbon-warning.ribbon-clip:before {
      border-left-color: #ff850d;
      border-right-color: unset;
    }
    .ribbon-clip {
      right: -14px;
      left: unset;
      &:before {
        right: 0;
        left: unset;
      }
    }
  }
  .product-hover {
    ul {
      padding-right: 0;
    }
  }
  .customer-review {
    .media {
      img {
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }
  .nav {
    padding-right: 0;
  }
  .contacts-tabs {
    .nav-pills {
      border-left: 1px solid #f4f4f4;
    }
  }
  .custom-file-label {
    &::after {
      left: 0;
      right: unset;
    }
  }
  // form wizard 4 page
  .wizard-4 {
    ul {
      &.anchor {
        float: right;
        padding-right: 0;
        padding-left: 30px;
      }
    }
    .step-container {
      clear: unset;
    }
    .form-group {
      text-align: right;
    }
    .action-bar {
      .btn {
        float: left;
      }
    }
  }
  .f1 {
    .f1-buttons {
      text-align: left;
    }
    .f1-steps {
      .f1-step {
        float: right;
      }
    }
  }
  input[type="checkbox"].tui-full-calendar-checkbox-round + span {
    margin-left: 8px !important;
    margin-right: unset;
  }
  input[type="checkbox"].tui-full-calendar-checkbox-square + span {
    margin-left: 5px !important;
    margin-right: unset;
  }
  .calendar-basic {
    .lnb-calendars {
      display: flex;
      align-items: center;
      clear: both;
      float: left;
    }
    #dropdownMenu-calendarType {
      i {
        &:first-child {
          margin-left: 15px;
          margin-right: unset;
        }

        &:last-child {
          margin-right: 15px;
          margin-left: unset;
        }
      }
    }
    #menu-navi {
      .menu-navi-right {
        .move-btn {
          margin-right: 15px;
          margin-left: unset;
        }
      }
    }
  }
  // general widgets page
  .static-top-widget .media-body {
    padding-right: 30px;
    padding-left: unset;
    .icon-bg {
      left: -20px;
      right: unset;
    }
  }
  .widget-joins {
    .media {
      .details {
        border-right: 1px solid $light-semi-gray;
        border-left: unset;
      }
      .media-body {
        text-align: right;
      }
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        text-align: left;
      }
    }
  }
  // tree view page
  .jstree-default {
    .jstree-node {
      margin-left: unset;
      margin-right: 24px;
      background-position: -292px -4px;
      background-repeat: repeat-y;
    }
  }
  // image cropper page
  .img-cropper {
    .btn-group {
      margin-right: 0;
      margin-left: 10px;
      button {
        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:first-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .docs-toggles {
    .btn-group {
      .btn {
        border-radius: 0;
        &:last-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:first-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
  .btn-group {
    > .btn:not(:last-child):not(.dropdown-toggle) {
      > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .jsgrid-button {
    + .jsgrid-button {
      margin-right: 5px;
      margin-left: unset;
    }
  }
  .list-group {
    padding-right: 0;
    i,
    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }
  // authentication page
  .authentication-box {
    text-align: right;
  }
  // mega options page
  .mega-horizontal {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
  }
  // navs page
  .navs-icon {
    svg {
      margin-left: 10px;
      margin-right: unset;
    }
    .fa {
      margin-left: 10px;
      margin-right: unset;
    }
  }
  .nav-list {
    .nav-list-disc {
      text-align: right;
      padding-right: 0;
    }
  }
  .ps-navs-inline {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
    left: 77px;
    right: unset;
  }
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    left: 77px;
    right: unset;
  }
  .navs-dropdown {
    .onhover-show-div {
      .navs-icon {
        li {
          text-align: right;
        }
      }
    }
  }
  .navs-icon.default-according.style-1 {
    li {
      button[aria-expanded="true"] {
        &:before {
          left: 2px;
          right: unset;
        }
      }
      button[aria-expanded="false"]:before {
        left: 2px;
        right: unset;
      }
    }
  }
  // search page css
  .search-form {
    .form-group {
      &:after {
        right: 53px;
        left: unset;
      }
      &:before {
        right: 82px;
        left: unset;
      }
    }
  }
  // order history page css
  .order-history {
    table {
      tbody {
        tr {
          td {
            .product-name {
              .order-process {
                .order-process-circle {
                  &:before {
                    right: -15px;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }
      th,
      td {
        &:first-child {
          text-align: right;
        }
      }
    }
  }
  // product-page
  .product-page-details {
    span {
      padding-right: 15px;
      padding-left: 0;
    }
  }
  .m-checkbox-inline {
    label {
      margin-left: 20px;
      margin-right: 0;
    }
  }
  .form-label-align-right {
    label {
      text-align: left;
    }
  }
  // rating page
  .br-theme-fontawesome-stars-o,
  .br-theme-fontawesome-stars,
  .br-theme-bars-reversed,
  .br-theme-bars-movie,
  .br-theme-bars-1to10,
  .br-theme-bars-square {
    .br-widget {
      .br-current-rating {
        float: right;
      }
      a {
        float: right;
      }
    }
  }
  // ribbons page
  .ribbon-left {
    right: auto;
    left: -2px;
  }
  .ribbon-vertical-left {
    left: auto;
    right: 12px;
  }
  .ribbon-vertical-right {
    right: auto;
    left: 12px;
  }
  .ribbon-vertical-right-wrapper{
    p{
      text-align: left;
    }
  }
  .ribbon-vertical-left-wrapper{
    padding-right: 40px;
    padding-left: unset;
  }
  .ribbon-vertical-right-wrapper{
    padding-left: 40px;
    padding-right: unset;
  }
  // search page
  .star-ratings {
    ul {
      &.search-info {
        padding-right: 0;
        > li {
          & + li {
            border-right: 1px solid #a5afc6;
            border-left: unset;
            padding-right: 8px;
            padding-left: 0;
            margin-right: 8px;
            margin-left: 0;
          }
        }
      }
    }
  }
  // select 2 page
  .select2-container--default {
    .select2-selection--single {
      .select2-selection__arrow {
        left: 10px !important;
        right: unset !important;
      }
    }
    .select2-selection--multiple {
      .select2-selection__choice {
        float: right;
      }
    }
  }
  .selection {
    .select2-selection {
      .select2-search__field {
        text-align: right;
      }
    }
  }
  .select2-results__option {
    text-align: right;
  }
  .editor-statusbar {
    text-align: left;
    span {
      margin-right: 1em;
      margin-left: unset;
    }
  }
  .CodeMirror-scroll {
    margin-left: -30px;
    margin-right: unset;
  }
  .primary-color,
  .yellow-color {
    ul {
      padding-right: 0;
    }
  }
  // steps page
  .u-step-desc {
    text-align: right;
  }
  .u-step-number {
    right: 20px;
    left: unset;
    & ~ .u-step-desc {
      margin-right: 50px;
      margin-left: unset;
    }
  }
  .u-pearl {
    &:after {
      left: 0;
      right: unset;
    }
    &:before {
      right: 0;
      left: unset;
    }
  }
  .u-step-icon {
    float: right;
    margin-left: 0.5em;
    margin-right: unset;
  }
  // summernote page
  .list-icons {
    padding-right: 0;
  }
  .card-header.note-toolbar {
    .note-color {
      .dropdown-toggle {
        padding-right: 5px;
      }
    }
    .form-group {
      &.draggable {
        text-align: right;
      }
    }

    .form-check {
      padding-right: 24px;
      padding-left: unset;
    }
    form {
      .custom-control {
        padding-right: 24px;
        padding-left: unset;
        .custom-control-label {
          &::after {
            right: 0;
            left: unset;
          }
          &::before {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }
  // input group page
  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: unset;
  }
  // tabbed card page
  .tabbed-card {
    ul {
      left: 22px;
      right: unset;
    }
  }
  // checkbox & radios page
  .checkbox {
    label {
      padding-right: 16px;
      padding-left: unset;
      &::before {
        right: 0;
        left: unset;
        margin-right: -16px;
        margin-left: unset;
      }
    }
  }
  .radio {
    label {
      &::after {
        right: 5px;
        left: unset;
        margin-right: -20px;
        margin-left: unset;
      }
    }
  }
  #cd-timeline::before{
    right: -15px;
    left: unset;
  }
  // timeline page
  .cd-timeline-content {
    &::before {
      border-right: 7px solid $light-color;
      border-left-color: transparent;
    }
  }
  .cd-timeline-img {
    i {
      right: 40%;
      left: unset;
      margin-right: -12px;
      margin-left: unset;
    }
  }
  .cd-timeline-block {
    &:nth-child(even) {
      .cd-timeline-content {
        float: left;
        .cd-date {
          right: auto;
          left: 122%;
          text-align: left;
        }
        &::before {
          right: auto;
          left: 100%;
          border-left-color: #eeeeee;
          border-right-color: transparent;
        }
      }
    }
  }
  .cd-timeline-content {
    .cd-date {
      right: 122%;
      left: unset;
    }
  }
  // tour page
  .introjs-tooltip {
    right: 0;
    left: unset;
  }
  .introjs-helperNumberLayer {
    right: -16px;
    left: unset;
  }
  .introjs-tooltipReferenceLayer {
    .introjs-tooltip {
      .introjs-tooltiptext {
        text-align: right;
      }
      .introjs-tooltipbuttons {
        text-align: left;
        .introjs-skipbutton {
          margin-left: 5px;
          margin-right: unset;
        }
        .introjs-nextbutton {
          -webkit-border-radius: 0.2em 0 0 0.2em;
          -moz-border-radius: 0.2em 0 0 0.2em;
          border-radius: 0.2em 0 0 0.2em;
        }
      }
    }
  }
  .list-inline-item {
    &:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: unset;
    }
  }
  .like-comment {
    ul {
      &.list-inline {
        padding-right: 0;
      }
    }
  }
  .typeahead {
    text-align: right;
  }
  .listing {
    ul {
      padding-right: 0;
      .icofont {
        float: right;
      }
    }
  }
  // gallery page
  .gallery {
    .hover-5 {
      img {
        margin-right: 30px;
      }
      &:hover {
        img {
          margin-right: 0;
        }
      }
    }
  }
  .lg-outer {
    text-align: right;
  }
  .lg-toolbar .lg-icon {
    float: left;
  }
  #lg-counter {
    padding-right: 20px;
    padding-left: unset;
    float: right;
  }
  // chart widget page
  .chart-widget-top {
    .text-end {
      text-align: left !important;
    }
    .num {
      .ms-1 {
        margin-right: 0.25rem !important;
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        left: -240px;
      }
    }
    .bottom-content {
      .b-r-light {
        border-left: 1px solid #eeeeee !important;
        border-right: unset !important;
      }
      .num {
        .ms-1 {
          margin-right: 0.25rem !important;
        }
      }
    }
  }
  // dashboard project page
  .chart-vertical-center {
    #myDoughnutGraph,
    #myPolarGraph {
      width: auto !important;
    }
  }
  .crm-activity {
    ul {
      &.dates {
        li {
          + li {
            border-right: 1px solid #ddd;
            padding-right: 10px;
            margin-right: 5px;
            border-left: unset;
            padding-left: unset;
            margin-left: unset;
          }
        }
      }
    }
  }
  div {
    &.dt-buttons {
      float: right;
    }
  }
  .dt-button-collection {
    left: -39px !important;
  }
  // dropzone page
  .dropzone {
    .dz-preview {
      .dz-error-mark,
      .dz-success-mark {
        right: 50%;
        margin-right: -27px;
        margin-left: unset;
        left: unset;
      }
    }
  }
  // footer page
  .page-wrapper {
    .page-body-wrapper {
      .footer {
        margin-right: 280px;
        margin-left: unset;
        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }
  .footer-copyright {
    text-align: right;
  }
  .footer-links {
    text-align: left;
  }
  .page-item {
    &:first-child {
      .page-link {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
    &:last-child {
      .page-link {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // popover page
  .popover.top,
  .popover.bottom {
    > .arrow:after {
      margin-right: -10px;
      margin-left: unset;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    > .input-group-append {
      > .btn,
      > .input-group-text {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    > .form-control,
    > .custom-select {
      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // typahead page
  .typeahead {
    span {
      &.twitter-typeahead {
        .tt-suggestion {
          text-align: right;
        }
      }
    }
    .league-name {
      text-align: right;
      margin-right: 5px;
    }
  }
  // call-chat page
  .follow {
    .text-md-right {
      text-align: left !important;
    }
  }
  // vector map page
  .jvectormap-zoomin,
  .jvectormap-zoomout,
  .jvectormap-goback {
    right: 10px;
    left: unset;
  }

  //customizer rtl css
  .customizer-links {
    right: unset;
    left: 0;
    > .nav {
      padding: 10px;
    }
    .nav-link {
      &:after {
        right: -10px;
        left: unset;
        border-width: 5px 5px 5px 0;
        border-left-color: unset;
        border-right-color: lighten($primary-color, 25%);
      }
      span {
        right: unset;
        left: -60px;
      }
      &:hover {
        span {
          left: 48px;
        }
      }
    }
    &.open {
      right: unset;
      left: 330px;
      border-radius: 0 8px 8px 0;
    }
  }
  .customizer-contain {
    width: 330px;
    right: unset;
    left: -330px;
    &.open {
      right: unset;
      left: 0px;
    }
    .customizer-header {
      .icon-close {
        left: unset;
        right: 30px;
      }
    }
  }
  // boxes layout rtl css
  .box-layout {
    &.page-wrapper.horizontal-wrapper {
      .page-header {
        padding-right: 250px;
        padding-left: unset;
        &.open {
          padding-right: 0;
        }
      }
    }
    &.page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          .page-body {
            margin-left: unset;
            margin-right: 230px;
          }
          .sidebar-wrapper {
            &.close_icon {
              ~ .footer {
                width: 1280px;
                padding-left: 0;
                left: 50%;
              }
            }
          }
        }
        .page-header {
          margin-left: auto;
          margin-right: auto;
          padding-left: unset;
          padding-right: 230px;
          &.close_icon {
            padding-right: 100px;
          }
        }
      }
    }
  }
  .page-header {
    .header-wrapper {
      .nav-right {
        .resp-serch-input {
          .search-form {
            left: 0;
            right: unset;
            margin-left: unset;
          }
        }
      }
    }
  }

  body{
    .dark-only{
      .chart_data_left,
    .chart_data_right {
      .card-body {
        .chart-main {
          .media {
            border-left: 1px solid $dark-card-border;
          }
        }
      }
    }
    .basic-cards{
      .custom-profile{
        .card-footer{
          > div{
            div{
              + div{
                border-left: unset;
                border-right: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
    .data-table{
      table{
        tbody{
          tr{
            td{
              span{
                i + i{
                  border-right: 1px solid $dark-card-border;
                  border-left: unset;
                }
              }
            }
          }
        }
      }
    }
    blockquote {
      border-right: 4px solid $dark-card-border;
      border-left: unset;
      &.text-center {
        border: 0;
      }
      &.text-end {
        border-left: 4px solid $dark-card-border;
        border-right: unset;
      }
    }
    .login-form{
      .form-group{
        .input-group-text{
          border-right: 1px solid $dark-card-border;
          border-left: unset;
        }
      }
    }  
    .widget-joins {
      .media {
        .details {
          border-left: unset !important;
          border-right: 1px solid $dark-card-border;
        }
      }
    }
    .custom-card {
      .card-footer {
        > div {
          + div {
            border-right: 1px solid $dark-card-border;
          }
        }
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          .card:not(.email-body) {
            .b-r-light,
            .border-right {
              border-right: none !important;
              border-left: 1px solid $dark-card-border !important;
            }
          }
        }
      }
    }
      .cd-timeline-content {
        &::before {
          border-right: 7px solid $dark-body-background;
          border-left-color: transparent;
        }
      }
      .cd-timeline-block {
        &:nth-child(even) {
          .cd-timeline-content {
            float: left;
            .cd-date {
              right: auto;
              left: 122%;
              text-align: left;
            }
            &::before {
              right: auto;
              left: 100%;
              border-left-color: $dark-body-background;
              border-right-color: transparent;
            }
          }
        }
      }
      .default-dash{
        .total-transactions{
          .report-sec{
            .report-main{
              border-left: 1px solid $dark-card-border;
            }
          }
        }
      }   
      .general-widget{
        .widget-feedback{
          ul{
            li{
              &:nth-child(n+2){
                border-right: 1px solid $dark-card-border !important;
              }
            }
          }
        }
        .custom-profile{
          .card-footer>div{
            div+div{
              border-right: 1px solid $dark-card-border !important;
            }
          }
        }
        .social-widget-card{
          .card-footer{
            .row{
              .col:nth-child(n+2){
                border-right: 1px solid $dark-card-border;
              }
            }
          }
        }   
      }
      .default-dash{
        .total-transactions{
          .report-sec{
            border-right: 1px solid $dark-card-border;
          }
        }
      }  
      .user-profile{
        .follow{
          ul.follow-list{
            li:nth-child(n+1){
              border-left: 1px solid $dark-card-border !important;
            }
            li:nth-child(n+2){
              border-left: unset !important;
            }
            li:nth-child(2){
              border-left: 1px solid $dark-card-border !important;
            }
          }
        }
      }
      .blog-single{
        .comment-box{
          .comment-social{
            li{
              &:first-child{
                border-left: 1px solid $dark-card-border !important;
                border-right: unset !important;
              }
              &:nth-child(n+2){
                border-left: unset;
              }
            }
          }
        }
      }
  
      .faq-accordion {
        .card {
          .btn-link {
            svg {
              margin-left: 10px;
              margin-right: unset;
              right: 10px;
              left: unset;
            }
          }
        }
      }
      .starter-main{
        blockquote{
          border-right: 4px solid $dark-card-border !important;
          border-left: unset;
        }
      } 
  
      .page-wrapper{
        .page-body-wrapper{
          .page-body{
            .blog-box{
              .blog-details-second{
                .detail-footer{
                  ul.sociyal-list{
                    li{
                      &:nth-child(n+2){
                        border-left: unset;
                        border-right: 1px solid $dark-card-border;
                      }
                    }
                  }
                }
              }
              .blog-details{
                .blog-social{
                  li:nth-child(n+1){
                    border-left: 1px solid $dark-card-border !important;
                  }
                  li:last-child{
                    border-left: unset !important;
                  }
                }
              }
            }
          }
        }
      }
    } 
  } 
  // responsive css
  @media screen and (max-width: 1660px) {
    .chat-box{
      .chat-right-aside{
        .chat{
          .chat-header{
            .chat-menu-icons{
              li{
                margin-right: unset;
                margin-left: 10px;
                &:last-child{
                  margin-left: 0 !important;
                }
              }
            }
          }
        }
      }
    }
    // video chat page
    .caller-img {
      left: unset;
      right: 15px;
    }
  }
  @media screen and (max-width: 1660px) and (min-width: 1366px) {
    .profile-greeting{
      .cartoon-img{
        img{
          margin-left: -130px;
          margin-right: unset;
        }
      }
    }
  }
  @media screen and (max-width: 1540px) {
    .search-page{
      .blog-box{
        .blog-details{
          .blog-social{
            li{
              &:nth-child(n+2){
                padding-left: unset;
                padding-right: 15px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1365px) {
    .email-wrap{
      .actions{
        li{
          &:nth-child(n+2){
            margin-right: 10px;
            margin-left: unset;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1365px) and (min-width: 1200px) {
    .crypto-chart{
      .card-header{
        .media{
          .media-end{
            text-align: left;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1366px) {
    .chat-left-aside{
      .status-circle{
        left: unset;
        right: 36px;
      }
    } 
    .preview-inner-container{
      img{
        + img{
          margin-right: 8px;
          margin-left: unset;
        }
      }
    }
    .file-manager{
      .folder{
        li{
          &:nth-child(odd){
            margin-right: 0;
          }
        }
      }
    }
    .profile-dropdown {
      &.onhover-show-div {
        right: unset !important;
        left: 0 !important;
      }
    }
    .product-wrapper {
      .product-grid {
        .feature-products {
          margin-right: 0;
        }
      }
    }
    .product-grid {
      margin-right: 0;
    }
    .d-none-productlist {
      margin-left: 10px;
      margin-right: unset;
    }

    .photos {
      ul {
        li {
          &:nth-child(3n) {
            margin-left: 10px;
            margin-right: unset;
          }
        }
      }
    }
    .bookmark-tabcontent {
      .list-bookmark {
        .bookmark-card.card {
          .desciption-data {
            width: 55% !important;
          }
        }
      }
    }

    .button-builder-wrap{
      .button-generator-bx{
        position: relative;
        width: 100%;
      }
    } 

    
    // index page css

    .chart_data_left {
      .card-body {
        .chart-main {
          .media {
            .media-body {
              .right-chart-content {
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
    // ecommerce dashboard css
    .best-seller-table {
      table {
        tbody {
          tr {
            td {
              .fa-check-circle {
                right: 2px;
              }
            }
          }
        }
      }
    }
    .offer-box {
      .offer-slider {
        .selling-slide {
          .d-flex {
            .left-content {
              padding-left: unset;
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) and (min-width: 1200px) {
    .basic-cards{
      .tabbed-card{
        ul{
          left: 14px;
          right: unset;
        }
      }
    }
    .page-wrapper{
      .page-body-wrapper{
        .footer{
          margin-left: unset;
          margin-right: 260px;
        }
      }
      &.compact-wrapper{
        .page-header{
          margin-left: unset;
          margin-right: 260px;
          width: calc(100% - 260px);
        }
        .page-body-wrapper{
          .page-body{
            margin-left: unset;
            margin-right: 260px;
          }
          div.sidebar-wrapper{
            width: 260px;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .text-xl-end{
      text-align: left !important;
    }
    // chat page
    .chat-box {
      .pe-xl-0 {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }
      .ps-xl-0 {
        padding-right: 0 !important;
        padding-left: 15px !important;
      }
    }
    // vertical page
    .pixelstrap {
      ul {
        a {
          &:hover,
          &:focus,
          &:active,
          &.highlighted {
            .sub-arrow {
              left: 3px;
              right: unset;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .text-lg-start {
      text-align: right !important;
    }
  }
@media only screen and (min-width: 1170px){
  #cd-timeline::before {
    left: 50% !important;
    right: unset;
    margin-left: -2px;
  }
}

  @media only screen and (max-width: 1169px){
    .cd-timeline-block {
      &:nth-child(even) {
        .cd-timeline-content {
          float: unset;
        }
      }
    }
    .cd-container{
      .cd-timeline-block{
        .cd-timeline-content{
          margin-left: unset;
          margin-right: 6px;
        }
      }
    } 
    .cd-timeline-block{
      .cd-timeline-img{
        right: -2px;
        left: unset;
        margin-right: -30px;
      }
    }
    .cd-timeline-content::before{
      border-right: unset;
      border-left: unset;
    }
    .cd-timeline-content{
      span{
        float: right;
      }
    }
  }
  @media only screen and (max-width: 1199.98px) {
    .invoice{
      .text-md-end{
        text-align: left !important;
      }
    } 
    .md-sidebar{
      .md-sidebar-aside{
        left: unset;
        right: 0;
      }
    } 
    .file-sidebar{
      padding-right: unset;
      padding-left: 12px;
    }
    .jkanban-container {
      .btn {
        margin-left: unset;
        margin-right: 20px;
      }
    }
    .email-wrap {
      .email-sidebar {
        .email-left-aside {
          right: 0;
          left: unset;
        }
      }
    }
    .page-builder{
      .float-start{
        float: right !important;
      }
    } 

    .blog-single{
      .comment-box{
        ul{
          .comment-social{
            margin-right: 0;
          }
        }
      }
    }
    .link-section > div > h6:before {
      right: unset;
      left: 10px;
    }

    .md-sidebar.job-sidebar{
      .md-sidebar-aside.job-left-aside{
        top: 40%;
        right: 10px;
        left: unset;
        .location-checkbox{
          span{
            padding-right: 33px;
            padding-left: unset;
          }
        }
      }
    } 
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              li {
                .submenu {
                  li {
                    &:first-child {
                      padding-right: 15px !important;
                      padding-left: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 45px;
          }
        }
      }
    }
    .email-wrap {
      .checkbox_animated {
        right: -6px;
      }
      .row {
        .col-xl-6 {
          padding-right: 15px;
        }
        .col-xl-3 {
          + .col-xl-3 {
            padding-left: 15px;
          }
        }
      }
      .email-app-sidebar {
        .main-menu {
          & > li {
            text-align: right;
            a {
              i {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }
        ul {
          padding-right: 0;
        }
      }
      .email-content {
        .email-top {
          .user-emailid {
            &:after {
              float: left;
            }
          }
        }
      }
      .email-right-aside {
        .email-body {
          .pe-0 {
            padding-right: unset !important;
          }
        }
      }
    }
    .chat-menu-icons {
      text-align: left;
    }
    .chat-box {
      .chat-history {
        .row {
          .col-sm-7 {
            padding-left: 0 !important;
            padding-right: unset !important;
          }
        }
      }
    }
    .chat-menu {
      left: 0;
      right: unset;
    }
    // index page css

    .chart_data_left {
      .card-body {
        .chart-main {
          .media {
            .media-body {
              .right-chart-content {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }
    // ecommerce dashboard css
    .best-seller-table {
      table {
        tbody {
          tr {
            td {
              .fa-check-circle {
                right: 18px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991.98px) {
    .comment-box{
      .comment-social{
        li{
          &:nth-child(n+2){
            padding-right: 15px;
            padding-left: unset;
            margin-left: unset;
          }
        }
      }
    }
    .modal{
      .modal-header{
        .product-box{
          .product-details{
            .product-size{
              ul{
                li{
                  &:nth-child(n+2){
                    margin-left: unset;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer{
      margin-right: 0;
      margin-left: unset;
    }
    .default-dash{
      .profile-greeting{
        .cartoon-img{
          img{
            margin-left: -200px !important;
            margin-right: unset;
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 20px;
        }
      }
    }
    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            .logo-wrapper {
              text-align: right;
            }
          }
        }
      }
    }
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-header {
            .chat-menu-icons {
              li {
                margin-left: 7px;
                margin-right: unset;
              }
            }
          }
        }
      }
    }
  
    
    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            &.close_icon {
              transform: translate(285px);
              &:hover {
                transform: translate(285px);
              }
            }
            .logo-wrapper {
              .back-btn {
                left: 10%;
                right: unset;
              }
            }
          }
        }
        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              margin-right: 0;
              border-right: none;
              padding-right: 0;
              border-left: 1px solid #ddd;
              margin-left: 15px;
              padding-left: 15px;
            }
          }
        }
      }
    }
    .faq-accordion {
      .faq-header {
        svg {
          left: 20px;
        }
      }
    }
    .categories {
      .learning-header {
        padding-right: 20px;
      }
    }
    .page-wrapper {
      .page-header {
        .header-logo-wrapper {
          .logo-wrapper {
            img {
              margin-left: 15px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .page-body,
      .footer {
        margin-right: 0 !important;
      }
    }
    .main {
      .langChoice {
        left: 20px;
      }
    }
    .cd-timeline-img {
      i {
        right: 50% !important;
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-title {
          .breadcrumb {
            margin-right: 0;
          }
        }
        .page-body {
          min-height: calc(100vh - 60px);
        }
      }
      .page-header {
        margin-right: 0;
      }
      &.compact-wrapper {
        .page-header {
          margin-right: 0 !important;
          margin-left: unset !important;
        }
        .page-body-wrapper {
          &.sidebar-wrapper {
            &.close_icon {
              transform: translate(285px);
            }
          }
        }
      }
    }
    .card-body {
      .value-left {
        margin-left: 20px;
      }
    }
    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              left: -127px;
            }
          }
        }
      }
    }
    .card {
      .card-header {
        .card-header-right {
          left: 15px;
        }
      }
    }
    // index page css

    .chart_data_left {
      .card-body {
        .chart-main {
          .media {
            .media-body {
              .right-chart-content {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
    // ecommerce dashboard css
    .activity-timeline {
      .media {
        .activity-line {
          right: 26px;
        }
      }
    }
    .offer-box {
      .offer-slider {
        .selling-slide {
          .d-flex {
            .left-content {
              padding-right: 0;
            }
          }
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          right: unset;
          left: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .page-wrapper {
      &.compact-wrapper {
        &.material-type {
          .page-body-wrapper {
            div.sidebar-wrapper {
              left: unset;
              right: 0;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .float-md-end {
      float: left !important;
    }
    .text-md-end {
      text-align: left !important;
    }
    .offset-md-2 {
      margin-right: 16.66667%;
      margin-left: unset;
    }
    .offset-md-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 83.33333%;
    }
    .text-md-start {
      text-align: right !important;
    }
    .timeliny{
      .timeliny-vertical-line {
        right: 32%;
      }
    } 
    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;
        ul {
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: 767.98px) {
    .todo{
      .todo-list-wrapper{
        #todo-list{
          li{
            .task-container{
              .assign-name{
                padding-right: 20px;
                padding-left: unset;
              }
            }
          }
        }
      }
    }
    .comment-box{
      .comment-social{
        li{
          &:first-child{
            padding-left: 10px;
          }
        }
      }
    }
    .blog-box{
      .blog-details{
        .blog-social{
          li{
            &:first-child{
              padding-left: 10px;
            }
            + li{
              margin-right: 10px;
              padding-left: 10px;
            }
          }
        }
      }
    }
    .icon-hover-bottom{
      .icon-popup{
        .icon-last{
          .form-inline{
            .form-group{
              .btn{
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .project-list{
      .btn{
        float: right;
      }
    }
    .search-page{
      .blog-box{
        .blog-details{
          .blog-social{
            li{
              &:nth-child(n+2){
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
    .default-dash{
      .profile-greeting{
        .cartoon-img{
          img{
            margin-left: -110px !important;
            margin-right: unset;
          }
        }
      }
    }
    .offset-xl-3 {
      margin-right: unset;
      margin-left: unset;
    }
    .tabbed-card ul {
      position: relative;
      left: 0;
    }
    .sm-left-text {
      text-align: right !important;
    }
    .list-persons {
      .profile-mail {
        .email-general {
          ul {
            padding-left: 0;
          }
        }
      }
    }
    .comment-box{
      ul{
        ul{
          text-align: right !important;
        }
      }
    }
    .form-builder-2-header>div nav{
      float: right;
    }
    .page-wrapper.compact-wrapper .mega-menu {
      right: 80px;
      .nav-link {
        border: none;
      }
    }

    .dt-ext {
      .dataTables_wrapper {
        .dataTables_length {
          float: unset;
        }
        .dataTables_filter {
          float: unset;
        }
      }
    }
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-header {
            .chat-menu-icons {
              li + li {
                margin-right: unset;
                margin-left: 10px !important;
              }
            }
          }
          .chat-message {
            .text-box {
              .btn {
                &:before {
                  transform: scale(-1) !important;
                }
              }
            }
          }
        }
      }
    }
    .bookmark-tabcontent {
      .list-bookmark {
        .bookmark-card.card {
          .desciption-data {
            width: 48% !important;
          }
        }
      }
    }
    .page-wrapper.compact-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            margin-right: auto;
            margin-left: unset;
          }
        }
      }
    }
    .calendar-basic {
      #lnb {
        .lnb-calendars-item {
          float: unset !important;
        }
        .lnb-calendars {
          text-align: left;
          .lnb-calendars-d1 {
            display: block !important;
          }
        }
      }
      #menu-navi {
        .menu-navi-right {
          .move-btn {
            float: left;
          }
        }
        .menu-navi-center {
          left: 20px;
          right: unset;
        }
      }
    }
    .mega-menu .nav-link.active {
      color: $black;
    }
    .product-wrapper {
      &.sidebaron {
        .product-grid {
          .product-wrapper-grid {
            margin-right: 0;
          }
        }
      }
    }
    .feature-products {
      .filter-toggle {
        margin-right: 10px;
        margin-left: unset;
      }
    }
    .page-wrapper {
      .nav-right {
        .nav-menus {
          margin-right: unset;
          margin-left: 57px;
        }
      }
      &.compact-wrapper {
        .nav-right {
          .nav-menus {
            margin-right: unset;
            margin-left: 0;
          }
        }
      }
    }
    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 25px !important;
          }
        }
      }
    }

    .ecommerce-widget {
      .text-md-right {
        text-align: left !important;
      }
    }
    .bar-chart-widget {
      .earning-details {
        i {
          left: -147px !important;
        }
      }
    }
  }

  @media (max-width: 1470px) {
    .crypto-chart {
      .card-header {
        .media {
          .media-body {
            .coin-logo-img {
              margin-right: unset;
              margin-left: 5px;
            }
            h5 {
              margin-left: 4px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .crypto-chart{
      .card-header{
          .media{
              .media-body{
                  h5{
                      margin-right: unset;
                      margin-left: 4px;
                  }
              }
          }
      }
  }
    .custom-profile{
      .card-social{
        li{
          + li{
            margin-right: 11px;
          }
        }
      }
    }
  }
  @media (min-width: 576px) {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    .text-sm-end {
      text-align: left !important;
    }
    .float-sm-end {
      float: left !important;
    }
    .me-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
  }
  @media only screen and (max-width: 575.98px) {
      .comment-box{
        ul{
          ul{
            margin-right: 30px;
            margin-left: unset;
          }
        }
      }
    .blog-details-page{
      .blog-box.blog-shadow{
        .blog-details{
          .blog-social{
            li{
              &:nth-child(2){
                border-left: unset;
              }
            }
          }
        }
      }
    }
    .vertical-tab{
      .nav-tabs{
        margin-right: unset;
        margin-left: 0;
      }
    } 
    .dropdown-basic{
      .btn-group{
        margin-right: 8px;
        margin-left: unset;
      }
    } 
    .login-card{
      .login-form{
        .form-group{
          .btn.btn-block{
            margin-right: unset;
            margin-left: auto;
          }
        }
      }
    }   
    .login-form{
      .form-group{
        .link{
          float: unset;
        }
      }
    }  
    .search-page{
      .search-form{
        .form-group{
          input{
            padding: 8px 60px 8px 8px;
          }
        }
      }
    }
    .activity-log{
      .my-activity{
        p{
          span{
            svg{
              &.m-r-20{
                margin-left: 10px;
                margin-right: unset;
              }
            }
          }
        }
      }
    }
    .feature-products{
      .select-options{
        margin-right: unset;
        margin-left: 10px;
      }
    } 
    .customizer-contain{
      width: 290px;
    }
    .customizer-links{
      > .nav{
        padding: 5px;
      }
      &.open{
        left: 290px;
        right: unset;
      }
    } 
    .page-wrapper{
      .page-body-wrapper{
        .page-title{
          .breadcrumb{
            .breadcrumb-item{
              a{
                padding: 6px 0;
              }
            }
          }
        }
      }
      .page-header{
        .header-wrapper{
          .nav-right{
            .onhover-show-div{
              left: 70px;
              right: unset;
              li{
                .media{
                  .media-body{
                    > span{
                      float: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }    
    .rating{
      .text-end{
        text-align: right !important;
      }
    }
    .border-tab.nav-left{
      .nav-link{
        text-align: center;
      }
    } 
    .alert-dismissible{
      padding-right: 15px;
      padding-left: 30px;
      &.inverse{
        padding: 13px 65px 13px 20px;
      }
    }
    .login-card1{
      .login-form{
        .form-group{
          .link{
            float: unset;
          }
        }
      }
    }   
    .datetime-picker{
      .theme-form{
        label.text-end{
          text-align: right !important;
        }
      }
    }
    .date-picker{
      .text-end{
        text-align: right !important;
      }
    } 
    .search-page{
      .blog-box{
        .blog-details{
          .blog-social{
            li{
              &:nth-child(n+2){
                border-right: none;
              }
            }
          }
        }
      }
    }
    .contacts-tabs{
      .media{
        img.m-r-20{
          margin-left: 15px;
          margin-right: unset;
        }
      }
    }
    .user-profile{
      .photos{
        ul{
          li{
            &:nth-child(3n){
              margin-left: 10px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .product-social{
      li{
        &:nth-child(n+2){
          margin-right: 5px;
          margin-left: unset;
        }
      }
    }
    .invoice .text-xs-center{
      text-align: center !important;
    }
    .ProfileCard-details{
      padding-right: 26px;
      padding-left: unset;
    }
    .page-wrapper{
      .page-header{
        .header-wrapper{
          .cart-dropdown{
            li{
              .media{
                .media-body{
                  margin-right: unset !important;
                  margin-left: 20px !important;
                }
              }
            }
          }
        }
      }
    }
    .d-inline-block {
      float: unset !important;
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .cart-nav {
              .cart-dropdown {
                left: 78px !important;
                right: unset !important;
              }
            }
            .notification-dropdown {
              right: unset;
              left: 38px;
            }
          }
        }
      }
    }
    .default-dash{
      .profile-greeting{
        .cartoon-img{
          img{
            margin-left: -170px !important;
            margin-right: unset;
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 15px;
        }
      }
    }
    .d-inline-block {
      float: unset !important;
    }

    .form-builder-2-header{
      >div{
        .navbar{
          width: 100%;
        }
      }
      ul{
        li{
          text-align: right;
        }
      }
      .form-inline{
        display: block;
        width: 100%;
        button{
          text-align: right;
        }
      }
    }   
    .faq-widgets{
      svg{
        left: 20px;
        right: unset;
      }
    }
    .job-search{
      .media{
        .media-body{
          h6{
            .pull-right{
              margin-right: 15px;
              margin-left: unset;
            }
          }
          ul.rating{
            margin-right: 10px;
            margin-left: unset;
          }
        }
      }
    }
    .faq-accordion{
      .faq-header{
        .pull-right{
          float: left !important;
        }
      }
    }  
    .blog-box{
      .blog-details{
        .blog-social{
          li{
            &:first-child{
              padding-left: 0;
              border-left: unset;
            }
            &:nth-child(n+2){
              margin-right: 10px;
              padding-left: unset;
              margin-left: unset;
            }
          }
        }
      }
    }
    #cd-timeline {
      margin-right: auto !important;
      margin-left: unset;
    }
    .list-persons {
      .nav-pills {
        .nav-link {
          .media {
            .media-body {
              text-align: right;
            }
          }
        }
      }
    }
    .datetime-picker {
      .theme-form {
        .col-form-label.text-end {
          text-align: right !important;
        }
      }
    }

    .mega-horizontal {
      .offset-sm-3 {
        margin-right: unset;
      }
    }
    .user-profile {
      .profile-post {
        .post-body {
          .post-react {
            ul {
              li {
                &:nth-child(n + 2) {
                  margin-right: -12px;
                }
              }
            }
          }
        }
      }
    }
    .bookmark-tabcontent {
      .details-bookmark {
        .bookmark-card.card {
          .desciption-data {
            width: 100% !important;
          }
        }
      }
    }

    .invoice {
      .media-body.text-end {
        text-align: left;
      }
    }
    .feature-products {
      .select-options {
        float: right !important;
      }
    }
    .mega-menu {
      padding-left: unset;
      padding-right: 15px;
    }

    .page-wrapper {
      .nav-right {
        .nav-menus {
          margin-left: 45px;
        }
      }
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              li {
                margin-right: unset;
                margin-left: 1px;
              }
            }
          }
        }
      }
      &.compact-wrapper,
      &.compact-sidebar {
        .page-header {
          .header-wrapper {
            .toggle-sidebar {
              margin-left: 8px;
              padding-left: 8px;
              margin-right: unset;
              padding-right: unset;
              border-right: unset;
              border-left: 1px solid #ddd;
            }
          }
        }
      }
      &.compact-wrapper {
        .nav-right {
          .nav-menus {
            margin-left: 0;
          }
        }
      }
    }
    .translate_wrapper {
      &.active {
        .more_lang {
          &.active {
            left: unset;
            right: 15px;
          }
        }
      }
    }
    .page-header {
      .header-wrapper {
        .nav-right {
          > ul {
            > li {
              &:last-child {
                padding-right: 10px;
              }
            }
          }
          &.right-header {
            ul {
              &.profile-dropdown,
              &.chat-dropdown,
              &.notification-dropdown {
                right: unset !important;
                left: 15px !important;
              }
            }
          }
        }
      }
    }
    .faq-accordion {
      .faq-header {
        svg {
          left: 15px;
        }
      }
    }
    .categories {
      .learning-header {
        padding-right: 15px;
      }
    }
    .user-profile {
      .hovercard {
        .user-image {
          .share-icons {
            left: 15px !important;
            li {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .main {
      .langChoice {
        left: 15px;
      }
    }
    .page-wrapper {
      .search-form {
        .form-group {
          margin-left: 0;
        }
      }
    }
    .card-body {
      .value-left {
        margin-left: 15px;
      }
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              li {
                .profile-media {
                  img {
                    width: 32px;
                    margin-left: unset;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .support-table{
      .d-flex{
        select{
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
    .list-group{
      small{
        text-align: left;
      }
    }
    .email-wrap{
      .email-wrapper{
        .right-download{
          float: none;
        }
        h6{
          float: none;
        }
      }
    }
    .custom-profile {
      .card-social {
        li {
          + li {
            margin-right: 10px;
          }
        }
      }
    }
    .icon-hover-bottom{
      .icon-first{
        margin-left: 10px;
        margin-right: unset;
      }
    }
    .knob-block{
      .chart-clock-main{
        .clock-small {
          right: 38px;
          top: 74px;
        }
      }
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .onhover-show-div {
              right: unset;
              left: 20px;
            }
          }
        }
      }
    }
  }


@media only screen and (max-width: 375px) {
  .ecommerce-dash{
    .get-support{
      .support-sec-img {
        left: -70px;
      }
    }
  }
}
  @media only screen and (max-width: 420px) {
    .chat-box{
      .chat-right-aside{
        .chat{
          .chat-header{
            .chat-menu-icons{
              justify-content: flex-end;
            }
          }
        }
      }
    }    
    .invoice{
      .invo-profile{
        .media{
          .media-body{
            margin-left: unset !important;
            margin-right: 0 !important;
          }
        }
      }
    }
    .file-manager {
      .folder {
        li {
          &:nth-child(n + 2) {
            margin-right: 0;
            margin-left: unset;
          }
        }
      }
    }
    .project-list{
      .border-tab.nav-tabs{
        .nav-item{
          .nav-link{
            padding: 5px 0 5px 15px;
          }
        }
      }
    }   
    .default-dash{
      .profile-greeting{
        text-align: center;
      }
    }
    .timeliny{
      .timeliny-dot::after{
        width: 210px;
      }
    } 
    .page-wrapper {
      &.compact-sidebar {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                li {
                  .sidebar-submenu,
                  .mega-menu-container {
                    right: 112px;
                  }
                }
              }
            }
          }
        }
      }
      &.compact-small {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                li {
                  .sidebar-submenu,
                  .mega-menu-container {
                    right: 80px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 360px) {
    .sticky-header-main {
      .card {
        .card-header {
          h5 {
            a {
              margin-right: 0 !important;
              margin-left: unset !important;
            }
          }
        }
      }
    }
    .list-group{
      img{
        margin-left: 8px;
        margin-right: unset;
      }
    }
    .accordian-page {
      .row {
        div[class*="col-"] {
          &:last-child {
            .card-body {
              .accordion-item {
                .accordion-button {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
    .icon-hover-bottom {
      .icon-popup {
        display: block;
        text-align: right;
      }
    }
    .customizer-contain{
      width: 250px;
    }
    .customizer-links{
      &.open{
        left: 250px;
        right: unset;
      }
    }
    .listings{
      ul.list-group{
        .list-group-item-action{
          img.media-body{
            margin-left: 8px;
            margin-right: unset;
          }
        }
      }
    }  
    .dismiss-text{
      .alert-dismissible{
        .btn-close{
          left: 14px;
          top: 14px !important;
          right: unset;
        }
      }
    }
    .btn-toolbar{
      .btn{
        &:last-child{
          margin-right: 0 !important;
          margin-left: unset !important;
        }
      }
    }
    .user-profile{
      .profile-post{
        .post-body{
          .post-comment{
            li{
              &:last-child{
                margin-right: 0;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
    .checkout{
      .order-place{
        text-align: right !important;
      }
    }
    .kanban-item{
      .list{
        li{
          i{
            margin-right: unset;
            margin-left: 3px;
          }
        }
      }
    }
    .page-wrapper{
      .page-header{
        .header-wrapper{
          .nav-right{
            .cart-nav{
              .cart-dropdown{
                left: 40px !important;
              }
            }
          }
        }
      }
    }
    .prooduct-details-box {
      .close {
        left: 0;
      }
    }
    .social-app-profile {
      .hovercard {
        .user-image {
          .avatar {
            img {
              width: 85px;
              height: 85px;
            }
            margin-top: -47px;
          }
        }
      }
    }
    .social-chat {
      .other-msg {
        margin-right: 15px;
      }
    }
  }
  @media screen and (max-width: 1460px) {
    .sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          > li {
            margin-left: 8px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .toast-section {
    .toast {
      .toast-header {
        .btn-close {
          margin-left: 0;
          margin-right: 0.625rem;
        }
      }
    }
  }

  .custom-datatable-filter {
    text-align: right !important;
    margin-right: 0 !important;
  }

  .bookmark-tabcontent {
    .details-bookmark.list-bookmark {
      .bookmark-card {
        .details-website {
          .hover-block {
            text-align: left;
          }
        }
      }
    }
  }

  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .notification-dropdown {
            li {
              .media {
                .notification-img {
                  right: 0;
                  left: unset;
                }

                .media-body {
                  margin-left: 0;
                  margin-right: 60px;
                }

                .notification-right {
                  span {
                    margin-left: 5px;
                  }

                  a {
                    margin-right: 10px;
                    margin-left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .default-dashboard-section {
    .profile-greeting {
      .cartoon-img {
        left: 30px;
        right: unset;
        img {
          transform: rotateY(180deg);
        }
      }
    }

    .total-transactions-sec {
      .report-sec {
        border-left: 1px solid rgba($dark, 0.1);
        border-right: unset;
        .report-main {
          border-left: 1px solid rgba($dark, 0.1);
          border-right: unset;
        }
      }
    }
  }

  .ecommerce-dashboard-section {
    .support-sec.profile-greeting {
      .support-sec-img {
        right: unset;
        left: 0;
        img {
          transform: rotateY(180deg);
        }
      }
    }
  }

}

/**=====================
    60. RTL CSS Ends
==========================**/
